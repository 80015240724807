<resize-border [dragHolder]="header">
<div #header class="modal-header">
	<h5 class="modal-title f-w-600" id="productModalLabel">Purchase History
	</h5>
	<button type="button" class="close" aria-label="Close" (click)="closeModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="input-group mb-2 SearchBox">
		<input type="text" class="form-control small" id="chat-search" name="searchValue" placeholder="Search By ILC, PO#"
			aria-label="Search" aria-describedby="basic-addon2" [(ngModel)]="searchtext"
			[ngModelOptions]="{standalone: true}">
		<div class="input-group-append">
			<span class="input-group-text btn-primary cursor-pointer">
				<i class="fa fa-search" (click)="getPurchaseOrderList()"></i>
			</span>
		</div>
	</div>
	<div class="category-table custom-datatablemb-3">
		<div class="table-responsive2">
			<table class="custom-table">
				<thead>
					<tr class="custom-tr">
						<th class="custom-th text-center">ILC</th>
						<th class="custom-th text-center">Description</th>
						<th class="custom-th text-center">PO#</th>
						<th class="custom-th text-center">PO Date</th>
						<th class="custom-th text-center">Due Date</th>
						<th class="custom-th text-center">Type</th>
						<th class="custom-th text-center">POTitle</th>
						<th class="custom-th text-center">POCost</th>
						<th class="custom-th text-center">QtyOrdered</th>
						<th class="custom-th text-center">QtyShipped</th>
						<th class="custom-th text-center">QtyReceived</th>
						<th class="custom-th text-center">Status</th>
					</tr>
				</thead>
				<tbody>
					<tr class="custom-tr" *ngFor="let item of itemDataList">
						<td class="custom-td text-center">{{item.ilc}}</td>
						<td class="custom-td text-left">{{item.description}}</td>
						<td class="custom-td text-center">{{item.pon}}</td>
						<td class="custom-td text-center">{{item.podate | date:'yyy-MM-dd'}}</td>
						<td class="custom-td text-center">{{item.duedate | date:'yyy-MM-dd'}}</td>
						<td class="custom-td text-center">{{item.potype}}</td>
						<td class="custom-td text-center">{{item.potitle}}</td>
						<td class="custom-td text-right">{{item.pocost | number:'1.2-2'}}</td>
						<td class="custom-td text-center">{{item.qty}}</td>
						<td class="custom-td text-center">{{item.shippedqty}}</td>
						<td class="custom-td text-center">{{item.receivedqty}}</td>
						<td class="custom-td text-center"><span class="badge" title="{{item.status}}">{{item.status}}</span></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<div class="modal-footer justify-content-center">
	<button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
</div>
</resize-border>
<ngx-ui-loader></ngx-ui-loader>