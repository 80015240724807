import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SalesServices } from '../../Sales/sales.service';
import { COMMA, ENTER, T } from '@angular/cdk/keycodes';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ToastrService } from 'ngx-toastr';
import { SchedulerServices } from '../scheduler.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { serviceManagerServices } from '../../servicemanager/servicemanager.service';
import { MapsAPILoader } from '@agm/core';
import { CustomerslistComponent } from '../../servicemanager/servicecontracts/customerlist/customerlist.component';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  geoCoder: any
  @ViewChild('search') public searchElementRef: ElementRef;
  zoom: any;
  appname = environment.APP_NAME;
  userprofile: any;
  editSetCustomer: any;
  customerSites: any;
  tagName: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl('');
  filteredTags: Observable<string[]>;
  tags: any = [];
  allTags: any = [];
  Resources: any = [];
  public eventResponse: any = {
    "id": null,
    "ts_id": null,
    "siteid": '',
    "subject": '',
    "text": '',
    "status": 'open',
    "start_date": null,
    "due_date": null,
    "end_date": null,
    "fullday": 'No',
    "workcompleted": 'No',
    "multi_day": 'No',
    "customerid": null,
    "customer_name": '',
    "technician": null,
    "companyid": null,
    "attentionto": null,
    "low": null,
    "requestedby": null,
    "poc": '',
    "phone": '',
    "category": '',
    "subcategory": '',
    "notifycustomers": 'No',
    "priority": 'low',
    "directions": '',
    "lat": '',
    "lng": '',
    "customer_notice": '',
    "devices": '',
    "username": '',
    "techniciansellist": [],
    "devicesellist": [],
    "subcat_default_line": false,
    "duration": null,
    "categoryid": '',
    "subcategoryid": '',
    "estimatedhrs": 1,
    "createby": null,
    "createdate": null,
    "updateby": null,
    "upatedate": null,
    "estimatedhrs_perday_pertech": 0,
    "is_update_future_tickets": false,
    "contract_recurrence_id": null
  };

  customerDeviceList: any = [];
  public technicianList = [];
  @ViewChild('tagInput') tagInput: any;
  @ViewChild('Customer') Customer;
  @Input('params') params;
  isDropDownOpen: boolean = false;
  custInfoResponse: any
  @ViewChild(CustomerslistComponent, { static: false }) CustomersComponent: CustomerslistComponent;
  keyword = 'Text';
  site: any;
  isShowEndDate = true;
  categoryList: any = [];
  subCategoryList: any = [];
  priorityList: any = [];
  statusList: any = [];
  totalWorkHrs: any = [];
  totalEstimatedHrs: any;
  addOnFocusOut = true;
  isCloseModal = false;
  action: any;
  customerInfos: any = {};
  constructor(private modalService: NgbModal,
    private toastr: NotificationService,
    private datePipe: DatePipe, private service: SalesServices,
    private loader: NgxUiLoaderService,
    private commonService: CommonServices,
    private cd: ChangeDetectorRef,
    private serviceManager: serviceManagerServices,
    private schedulerService: SchedulerServices,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private activeModal: NgbActiveModal) {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.commonService.getTicketStatuses().subscribe(res => {
			this.statusList = res;
		});
  }
  getCustomerDevices() {
    this.commonService.getCustomerDevices(this.eventResponse.customerid, this.eventResponse.siteid).subscribe(data => {
      this.customerDeviceList = data;
      environment.production ? '' : console.log('customerDevice list', this.customerDeviceList)
      var deviceArray = [];
      if (this.eventResponse.devices) {
        deviceArray = this.eventResponse.devices.split(',');
      }
      if (this.customerDeviceList.length > 0) {
        deviceArray.forEach(device => {
          this.customerDeviceList.forEach(res => {
            if (device == res.Value) {
              res.checked = true;
            } else {
              res.checked = false;
            }
          })
        })
      }
    })
  }
  onDateChangeEvent() {
    return;
    if (this.eventResponse.due_date == undefined || this.eventResponse.due_date == null) {
      return;
    }
    this.calculateHrsInTwoDte(this.eventResponse.start_date, this.eventResponse.due_date)
  }
  calculateHrsInTwoDte(startDte, endDte) {
    //Works for iOS
    // var timeStart = new Date((startDte).replace(/-/g, "/")).getTime().valueOf();
    // var timeEnd = new Date((endDte).replace(/-/g, "/")).getTime().valueOf();
    var timeStart = new Date(startDte).getTime();
    var timeEnd = new Date(endDte).getTime();
    var hourDiff = timeEnd - timeStart; //in ms
    var secDiff = hourDiff / 1000; //in s
    var minDiff = hourDiff / 60 / 1000; //in minutes
    var hDiff = hourDiff / 3600 / 1000; //in hours
    var hours = Math.floor(hDiff);
    var minutes = minDiff - 60 * hours;
    this.totalWorkHrs = [];
    this.totalWorkHrs.push({
      hours: Math.floor(hDiff),
      minutes: minutes
    })
    this.calculateTotalHrs();
  }
  calculateTotalHrs() {
    var totalWrkHrs = 0, totalWrkMnt = 0;
    if (this.totalWorkHrs.length > 0) {
      this.totalWorkHrs.forEach((element) => {
        totalWrkHrs += element.hours;
        totalWrkMnt += element.minutes;
      });
    }
    totalWrkHrs += totalWrkMnt / 60;
    totalWrkMnt = totalWrkMnt % 60;
    var workHrs = ('' + totalWrkHrs).substring(0, 2).replace('.', '');
    var workMin = ('' + totalWrkMnt).substring(0, 2).replace('.', '');
    this.totalEstimatedHrs = workHrs;
    this.totalEstimatedHrs = this.totalEstimatedHrs + '.' + workMin;
    this.eventResponse.estimatedhrs = this.timeStringToFloat(this.totalEstimatedHrs);
  }
  timeStringToFloat(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return this.round(hours + minutes / 60, 1);
  }
  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
  confirmationSubmit() {
    if (!this.eventResponse.contract_recurrence_id) {
      this.Save();
      return;
    }
    Swal.fire({
      title: 'Do you want to update this and all future schedules?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.eventResponse.is_update_future_tickets = true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.eventResponse.is_update_future_tickets = false;
      }
      this.Save();
    })
  }
  Save() {
    if (this.eventResponse.subject == undefined || this.eventResponse.subject == null) {
      this.toastr.Error('Please enter subject');
      return;
    }
    if (this.eventResponse.fullday == 'No' && (this.eventResponse.due_date == undefined || this.eventResponse.due_date == null)) {
      this.toastr.Error('Please enter end date');
      return;
    }
    if (this.eventResponse.text == undefined || this.eventResponse.text == null) {
      this.toastr.Error('Please enter scope of work');
      return;
    }
    if (this.eventResponse.status == undefined || this.eventResponse.status == null) {
      this.toastr.Error('Please select status');
      return;
    }
    var techs;
    var devices;
    var t_ticketdevices = [];
    if (this.customerDeviceList.length > 0) {
      this.customerDeviceList.forEach((d, i) => {
        if (d.checked) {
          if (devices == undefined || devices == null) {
            devices = d.Value;
          } else {
            devices += ',' + d.Value;
          }
          t_ticketdevices.push({
            tid: this.eventResponse.id,
            deviceid: d.Value
          })
        }
      })
    }
    var t_ticketemployees = [];
    if (this.technicianList.length > 0) {
      this.technicianList.forEach((t, i) => {
        if (t.checked) {
          if (techs == undefined || techs == null) {
            techs = t.id;
          } else {
            techs += ',' + t.id;
          }
          t_ticketemployees.push({
            tid: this.eventResponse.id,
            employeeid: t.employee_id,
            active: 'true',
          })
        }
      });
    }
    var tags = null;
    if (this.tags.length > 0) {
      this.tags.forEach((t, i) => {
        if (tags == undefined || tags == null) {
          tags = t;
        } else {
          tags += ',' + t;
        }
      });
    }
    this.eventResponse.tags = tags;
    if (this.eventResponse.id != undefined && this.eventResponse.id != null && this.eventResponse.id > 0) {
      this.eventResponse.updateby = this.userprofile.email;
      this.eventResponse.updatedate = this.datePipe.transform(new Date(), this.userprofile.postdatetimeformat);
    } else {
      this.eventResponse.username = this.userprofile.email;
      this.eventResponse.companyid = this.userprofile.companyid;
      this.eventResponse.createby = this.userprofile.email;
      this.eventResponse.createdate = this.datePipe.transform(new Date(), this.userprofile.postdatetimeformat);
    }
    this.eventResponse.workcompleted = this.eventResponse.workcompleted == 'Yes' ? 1 : 0;
    this.eventResponse.notifycustomers = this.eventResponse.notifycustomers == 'Yes' ? 1 : 0;
    this.eventResponse.fullday = this.eventResponse.fullday == 'Yes' ? 1 : 0;
    this.eventResponse.multi_day = this.eventResponse.multi_day == 'Yes' ? 1 : 0;
    this.eventResponse.exclude_public_holidays = this.eventResponse.exclude_public_holidays == 'Yes' ? 1 : 0;
    this.eventResponse.week_days_only = this.eventResponse.week_days_only == 'Yes' ? 1 : 0;
    this.eventResponse.techniciansellist = techs ? techs.split(',') : [];
    this.eventResponse.devicesellist = devices ? devices.split(',') : [];
    this.eventResponse.t_ticketemployees = t_ticketemployees;
    this.eventResponse.t_ticketdevices = t_ticketdevices;
    this.eventResponse.technician = techs;
    this.eventResponse.devices = devices;
    this.eventResponse.customer_notice = this.eventResponse.customer_notice;

    environment.production ? '' : console.log('request ' + JSON.stringify(this.eventResponse));

    this.loader.start();
    this.schedulerService.postEvent(this.eventResponse).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isCloseModal = true;
        this.closeModal();
      } else {
        this.toastr.Error(res.status)
      }
    }, err => {
      this.loader.stop();
    });
  }

  getSiteInfo() {
    if (!this.eventResponse.siteid) {
      return;
    }
    this.commonService.getSiteInfo(this.eventResponse.siteid).subscribe(res => {
      this.site = res;
      this.eventResponse.poc = this.site.poc ? this.site.poc : this.eventResponse.poc;
      this.eventResponse.phone = this.site.phone ? this.site.phone : this.eventResponse.phone;
      this.eventResponse.low = this.site.low ? this.site.low : this.eventResponse.low;
      // this.eventResponse.customer_notice = this.site.notice ? (this.eventResponse.customer_notice ? this.eventResponse.customer_notice + ', Site Notes: ' + this.site.notice : ('Site Notes: ' + this.site.notice)) : this.eventResponse.customer_notice;
      this.eventResponse.customer_notice = this.site.notice ? this.site.notice : this.eventResponse.customer_notice;
    }, err => {
      environment.production ? '' : console.log('Error : While loading project data', err);
    });
  }
  ngOnInit(): void {
    if (this.params == undefined) {
      return;
    }
    this.eventResponse.id = this.params.id ? this.params.id : null;
    this.eventResponse.technician = this.params.technician ? this.params.technician : null;
    this.eventResponse.start_date = this.params.start_date ? this.params.start_date : null;
    this.eventResponse.end_date = this.params.end_date ? this.params.end_date : null;
    this.action = this.params.action ? this.params.action : null;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.eventResponse.directions = place.formatted_address;
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.eventResponse.lat = place.geometry.location.lat();
          this.eventResponse.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
    this.schedulerService.getSchedulerResources().subscribe((res: any) => {
      this.Resources = res;
      this.technicianList = res;
      // this.calendarOptions.resources = this.Resources;
      this.cd.detectChanges();
      this.setResources();
      environment.production ? '' : console.log('resources', this.Resources)
    });
    var editDataForHeader = '';
    this.commonService.headerEditItemData.next(editDataForHeader); //end '
    var parentid = ''
    this.commonService.getTicketCategories(parentid).subscribe(data => {
      this.categoryList = data;
      environment.production ? '' : console.log('Category list', this.categoryList)
    })
    this.commonService.getComboEnums('ticketpriorities').subscribe(data => {
      this.priorityList = data;
      environment.production ? '' : console.log('Priority list', this.priorityList)
    })
        

    if (this.eventResponse.id) {
      this.schedulerService.getSchedulerEventByID(this.eventResponse.id).subscribe(res => {
        environment.production ? '' : console.log('eventresponse', res);
        this.loader.stop();
        this.eventResponse = res;
        if (this.eventResponse.workcompleted) {
          this.eventResponse.workcompleted = 'Yes';
        } else {
          this.eventResponse.workcompleted = 'No';
        }
        if (this.eventResponse.fullday) {
          this.eventResponse.fullday = 'Yes';
          this.isShowEndDate = false;
        } else {
          this.eventResponse.fullday = 'No';
          this.isShowEndDate = true;
        }
        if (this.eventResponse.notifycustomers) {
          this.eventResponse.notifycustomers = 'Yes';
        } else {
          this.eventResponse.notifycustomers = 'No';
        }
        if (this.eventResponse.multi_day) {
          this.eventResponse.multi_day = 'Yes';
        } else {
          this.eventResponse.multi_day = 'No';
        }
        if (this.eventResponse.exclude_public_holidays) {
          this.eventResponse.exclude_public_holidays = 'Yes';
        } else {
          this.eventResponse.exclude_public_holidays = 'No';
        }
        if (this.eventResponse.week_days_only) {
          this.eventResponse.week_days_only = 'Yes';
        } else {
          this.eventResponse.week_days_only = 'No';
        }
        if (this.eventResponse.siteid != undefined && this.eventResponse.siteid != null) {
          this.eventResponse.siteid = '' + this.eventResponse.siteid;
        } else {
          this.eventResponse.siteid = '';
        }
        if (this.eventResponse.end_date != undefined && this.eventResponse.end_date != null) {
          this.eventResponse.end_date = this.eventResponse.end_date ? this.datePipe.transform(this.eventResponse.end_date, this.userprofile.postdateformat) : null;
        }
        if (this.eventResponse.tags) {
          this.tags = this.eventResponse.tags.split(',');
        }

        environment.production ? '' : console.log('eventresponse', this.eventResponse);
        this.getCustomerDevices();
        this.getSubCategories();
        this.getCustomerSites();
        this.setResources();
      })
    }
    this.setResources();
  }
  setResources() {
    if (this.technicianList.length > 0 && this.eventResponse.technician) {
      var techArray = [];
      if (this.eventResponse.technician) {
        techArray = this.eventResponse.technician.split(',');
      }
      this.technicianList.forEach(res => {
        res.checked = false;
      })
      techArray.forEach(tech => {
        this.technicianList.forEach(res => {
          if (tech == res.id) {
            res.checked = true;
          }
        })
      })
    }
  }
  getSubCategories() {
    this.categoryList.forEach(element => {
      if (element.Value == this.eventResponse.categoryid) {
        this.eventResponse.category = element.Text;
      }
    });
    this.loader.start();
    this.commonService.getTicketCategories(this.eventResponse.categoryid).subscribe(data => {
      this.loader.stop();
      this.subCategoryList = data;
      this.selectSubCategory();
      environment.production ? '' : console.log('SubCategory list', this.subCategoryList)
    })
  }

  selectSubCategory() {
    this.subCategoryList.forEach(element => {
      if (element.Value == this.eventResponse.subcategoryid) {
        this.eventResponse.subcategory = element.Text;
        this.eventResponse.subcategoryid = '' + this.eventResponse.subcategoryid;
      }
    });
  }
  selectCustomer(item) {
    environment.production ? '' : console.log('selected customer', item);
    this.eventResponse.customerid = parseFloat(item.Value);
    if (this.isDropDownOpen) {
      this.customerInfo()
    }
    // this.getCustomerDevices();
    // this.getCustomerSites();
  }

  SelectedCustomer() {
    if (this.CustomersComponent.selectedCustomer) {
      this.customerInfos = this.CustomersComponent.selectedCustomer;
      this.eventResponse.customerid = this.customerInfos.id;
      this.eventResponse.customer_name = this.customerInfos.name;
      this.customerInfo()
    }
  }


  changeDropDownStatus() {
    this.isDropDownOpen = true;

  }
  inputCustomerCleared() {
    this.eventResponse.customerid = null;
    environment.production ? '' : console.log('Customer value cleared', this.eventResponse.customerid);
  }
  onFocusedCustomer($event) {
    if (this.Customer) {
      this.Customer.clear();
    }
  }
  getCustomerSites() {
    if (this.eventResponse.customerid == undefined || this.eventResponse.customerid == null) {
      return;
    }
    this.commonService.getCustomerSiteList(this.eventResponse.customerid).subscribe(data => {
      this.customerSites = data;
      this.getCustomerDevices();
      environment.production ? '' : console.log('site list', this.customerSites)
    })
  }
  customerInfo() {
    if (this.eventResponse.customerid == undefined || this.eventResponse.customerid == null) {
      return;
    }
    this.commonService.getCustomerInfo(this.eventResponse.customerid).subscribe(res => {
      this.custInfoResponse = res;
      // this.contractList = this.custInfoResponse.contractlist;
      this.customerDeviceList = this.custInfoResponse.deviceslist
      // this.projectList = this.custInfoResponse.projlist;
      this.customerSites = this.custInfoResponse.sitelist;
      this.eventResponse.poc = this.custInfoResponse.poc;
      this.eventResponse.phone = this.custInfoResponse.primaryphone;
      this.eventResponse.low = this.custInfoResponse.low;
      this.eventResponse.customer_notice = this.custInfoResponse.notice;
      if (this.customerDeviceList.length > 0) {
        var deviceArray = [];
        if (this.eventResponse.devices) {
          deviceArray = this.eventResponse.devices.split(',');
        }
        if (this.customerDeviceList.length > 0) {
          this.customerDeviceList.forEach(res => {
            res.checked = false;
          })
          deviceArray.forEach(device => {
            this.customerDeviceList.forEach(res => {
              if (device == res.Value) {
                res.checked = true;
              }
            })
          })
        }
      }
      environment.production ? '' : console.log('cust data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading project data', err);
    });



  }
  onCustomerChange(custID) {
    this.getCustomerDevices();
  }
  technicianClick(tech) {
    tech.checked = !tech.checked;
  }
  deviceClick(device) {
    device.checked = !device.checked;
  }
  getTags() {
    this.commonService.getTags('AddTicket').subscribe((res: any) => {
      if (res != null) {
        this.allTags = [];
        res.forEach(element => {
          this.allTags.push(element.Value);
        })
      }
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice())),
      );
      environment.production ? '' : console.log('tags data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading tags data', err);
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our tag
    if (value) {
      var isExists = false;
      if (this.tags.length > 0) {
        this.tags.forEach(element => {
          if (element == value) {
            isExists = true;
          }
        })
      }
      if (!isExists) {
        this.tags.push(value);
      }
    }
    var isExists = false;
    this.allTags.forEach(element => {
      if (element == value) {
        isExists = true;
      }
    })
    if (!isExists) {
      var postTagObj = {
        "description": value,
        "active": true,
        "createby": this.userprofile.email,
        "createdate": this.datePipe.transform(new Date(), this.userprofile.postdatetimeformat),
        "companyid": this.userprofile.companyid,
        "color": "",
        "pg": "AddTicket",
      }
      this.loader.start();
      this.commonService.postTags(postTagObj).subscribe((res: any) => {
        this.loader.stop();
        if (res != null) {
          if (res.status_code == "1") {
            res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          } else {
            res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
            return;
          }
          this.getTags();
        }
      }, err => {
        this.loader.stop();
      });
    }
    // Clear the input value
    event.chipInput!.clear();

    this.tagCtrl.setValue(null);
  }
  ngOnDestroy() {
		this.modalService.dismissAll();
	}
  remove(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
  }
  //#endregion

  deleteTicket() {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.serviceManager.deleteTicket(this.eventResponse.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.isCloseModal = true;
            this.closeModal();
          }
        }, err => {
          this.loader.stop();
          environment.production ? '' : console.log('Error : While loading expType list ', err);
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }
  closeModal() {
    this.activeModal.close(this.isCloseModal);
  }

}
