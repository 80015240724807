<resize-border [dragHolder]="header">
    <div #header class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Product Bin</h5>
        <button type="button" class="close" aria-label="Close" (click)="closemodal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [style.pointer-events]="isView?'none':''">
        <form class="needs-validation" name="from" #f="ngForm">
            <div class="form row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Bin location</label>
                    <ng-select required="" name="bintype" [(ngModel)]="binData.binid" [readonly]="setReadonly" #binid="ngModel"
                        [ngClass]="{ 'is-invalid': binid.invalid && binid.errors , 'is-valid': binid.valid}" required
                        placeholder="Search Bins" [clearable]="binData.binid">
                        <!-- <option value="">Select Bin</option> -->
                        <ng-option *ngFor="let item of binList" [value]="item.Value">
                            {{item.Text}}</ng-option>
                    </ng-select>
                    <div *ngIf="binid.invalid && binid.errors" class="invalid-feedback d-block">
                        <div *ngIf="binid.errors.required">
                            Please select bin
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">On hand</label>
                    <input class="form-control" type="number" name="cnt" readonly [(ngModel)]="binData.cnt">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Hold Count</label>
                    <input class="form-control" type="number" name="onhold" readonly [(ngModel)]="binData.onhold">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Sold Count</label>
                    <input class="form-control" type="number" name="sold" readonly [(ngModel)]="binData.sold"
                        readonly>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Available Count</label>
                    <input class="form-control" type="number" name="availableqty" readonly
                        [(ngModel)]="binData.availableqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Min Qty</label>
                    <input class="form-control" type="number" name="minqty" [(ngModel)]="binData.minqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Max Qty</label>
                    <input class="form-control" type="number" name="maxqty" [(ngModel)]="binData.maxqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Reorder point</label>
                    <input class="form-control" type="number" name="reorderpoint" [(ngModel)]="binData.reorderpoint">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Reorder Qty</label>
                    <input class="form-control" type="number" name="reorderqty" [(ngModel)]="binData.reorderqty">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Notes</label>
                    <textarea class="form-control" rows="1" name="notes" [(ngModel)]="binData.notes">
                  </textarea>
                    <!-- <textarea class="form-control" rows="5"></textarea> -->
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label for="" class="mb-1">Active</label>
                    <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <label class="d-block" for="active">
                            <input class="radio_animated" id="active" type="radio" [value]=true
                                [(ngModel)]="binData.active" name="active">
                            Yes
                        </label>
                        <label class="d-block" for="active1">
                            <input class="radio_animated" id="active1" [value]=false [(ngModel)]="binData.active"
                                name="active" type="radio">
                            No
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
            (click)="closemodal()">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView" (click)="addBin()">Save</button>
    </div>
</resize-border>
<!-- <ngx-ui-loader></ngx-ui-loader> -->