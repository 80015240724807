import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { ItemanalysisComponent } from '../../inventory/products/modal/itemanalysis/itemanalysis.component';
import { PurchasehistoryComponent } from '../../inventory/products/modal/purchasehistory/purchasehistory.component';

@Component({
  selector: 'app-productcatalog',
  templateUrl: './productcatalog.component.html',
  styleUrls: ['./productcatalog.component.scss']
})
export class ProductcatalogComponent implements OnInit {
  fullScreen = false;
  @Output('soItemList') soItemList;
  @Output('params') params;
  @Output('lastPage') lastPage;
  @ViewChild('Depart') Depart;
  @ViewChild('Cat') Cat;
  @ViewChild('SubCat') SubCat;
  @Output('customerDiscount') customerDiscount;

  appname = environment.APP_NAME;
  keyword1 = 'Text';
  keyword2 = 'Text';
  keyword3 = 'Text';
  department: any;
  categories: any;
  subcategories: any;
  userdata: any;
  product: any = '';

  pcatg: any = {
    categoryid: null,
    supplierid: '',
    subcategoryid: null,
    deptid: null,
    productsearchby: 0,
    pricingtype: 'retail',
    ptype: 'inventory',
  }
  assembliesList: any = [];
  // soItemList: any = [];
  searchby: any = {
    ptype: '',
    pricingtype: '',
    productsearchby: '',
  }
  productList: any = [];
  categoryList: any = []
  supplierList: any = []
  isShowtileView: boolean = false;
  isShowGridView: boolean = true;
  subCategoryList: any = [];
  deptList: any = [];
  priceingTypeList: any = [];
  myDropShow = true;
  @HostListener('scroll', ['$event'])
  onWindowScroll(event) {
    this.myDropShow = false;
  }
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private commoanService: CommonServices,
    private toastr: NotificationService,
    private datePipe: DatePipe,
    private loader: NgxUiLoaderService,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    // this.pcatg.pricingtype = this.userdata.default_pricing_type;
    this.commoanService.getDepartmentList(false).subscribe(res => {
      this.deptList = res
    }, err => {
      this.loader.stop();
    });
    this.commoanService.getComboEnums('orderpricingtype').subscribe(res => {
      this.priceingTypeList = res;
    }, err => {
    });
  }

  showDropdown() {
    this.myDropShow = true;
  }
  selectCategory() {
    this.pcatg.subcategoryid = null;
    this.pcatg.supplierid = '';
    this.product = '';
    this.commoanService.getSubCategory(this.pcatg.categoryid).subscribe(res => {
      this.subCategoryList = res
      this.searchProduct();
    }, err => {
      this.loader.stop();
    });
  }
  selectDept() {
    this.pcatg.categoryid = null;
    this.pcatg.subcategoryid = null;
    this.pcatg.supplierid = null;
    this.commoanService.getCategory(this.pcatg.deptid, false).subscribe(res => {
      this.categoryList = res
      this.searchProduct();
    }, err => {
      this.loader.stop();
    });
  }

  searchProduct() {
    this.loader.start();
    this.commoanService.getProductCatalog(this.pcatg.ptype, this.pcatg.pricingtype, this.product, this.pcatg.deptid, this.pcatg.supplierid, this.pcatg.categoryid, this.pcatg.subcategoryid).subscribe(res => {
      this.loader.stop();
      this.productList = res;
      this.productList.forEach(element => {
        if (element.retailprice != null && element.retailprice != 0) {
          element.retailprice = element.retailprice.toFixed(this.userdata.decimals_allowed);
        }
        else {
          element.retailprice = parseFloat("0").toFixed(this.userdata.decimals_allowed);
        }
      });
      this.productList.forEach(item => {
        item.qty = 1;
        if (item.pic) {
          item.pic = environment.DOC_URL + '/' + this.userdata.companyid + '/' + item.pic;
        }
      })
      environment.production ? '' : console.log('product list data ', this.productList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });
  }

  purchasehistory(item) {
    var modalRef = null;
    modalRef = this.modalService.open(PurchasehistoryComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' })
    modalRef.componentInstance.fromParent = { id: item.id };
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  itemalysis(item) {
    var modalRef = null;
    modalRef = this.modalService.open(ItemanalysisComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    modalRef.componentInstance.fromParent = { id: item.id };
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  gridView() {
    this.isShowtileView = false;
    this.isShowGridView = true
  }
  tileView() {
    this.isShowtileView = true;
    this.isShowGridView = false
  }
  addproduct(data) {

  }
  closeModal() {
    this.activeModal.close(this.soItemList);
  }
  ngOnInit(): void {
    this.pcatg.pricingtype = this.params.pricingtype ? this.params.pricingtype : this.userdata.default_pricing_type;
    this.searchProduct();
  }

  selectEvent(item) {
    // do something with selected item
    this.toastr.Success("Item added into list")
    item.qty = item.qty ? (item.qty == 0 ? 1 : item.qty) : 1;
    var discount = this.customerDiscount ? Number(this.customerDiscount).toFixed(this.userdata.decimals_allowed) : (item.discount ? Number(item.discount).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed))
    if (discount !== null || discount !== undefined) {
      item.discount = discount;
    } else {
      item.discount = 0;

    }
    var itmObj = {
      "prodid": item.id,
      "cost": item.cost ? item.cost : 0,
      "pocost": item.pocost ? item.pocost : 0,
      "landedcost": item.cost ? item.cost : 0,
      "price": item.price ? Number(item.price).toFixed(this.userdata.decimals_allowed) : 0,
      "qty": item.qty ? item.qty : 1,
      "hrs": item.qty ? item.qty : 1,
      "description": item.desc,
      "qtyrtd": item.qtyrtd ? item.qtyrtd : 0,
      "active": 1,
      "ilc": item.ilc,
      "qtyonhand": item.qtyonhand,
      "discount": item.discount ? item.discount : Number(0).toFixed(this.userdata.decimals_allowed),
      "tax": item.tax ? item.tax : 0,
      "bin": item.bin,
      "isservice": item.isservice,
      "returnable": item.returnable,
      "qtyreturn": item.qtyreturn ? item.qtyreturn : 0,
      "isserilized": item.isserilized,
      "tpcode": item.tpcode ? item.tpcode : null,
      "serial": item.serial ? item.serial : null,
      "grp": item.grp ? item.grp : null,
      "geid": item.geid ? item.geid : null,
      "deplete_qty": item.deplete_qty ? item.deplete_qty : 0,
      "lotid": item.lotid ? item.lotid : 0,
      "linetype": item.linetype ? item.linetype : null,
      "tech": item.tech ? item.tech : null,
      "datecreated": null,
      "line_total": Number(item.price ? item.price : 0) * (item.qty ? Number(item.qty) : 0),
      "deletedate": null,
      "createby": this.userdata.email,
      "createdate": null,
      "deleteby": null,
      "srs_id": item.srs_id ? item.srs_id : null,
      "taxid": item.taxid ? item.taxid : null,
      "discount_type": "percent",
      "dis_amt": Number(0).toFixed(this.userdata.decimals_allowed),
      "tax_amt": Number(0).toFixed(this.userdata.decimals_allowed),
      "uom": item.uom,
      // "landedcost": item.landedcost ? item.landedcost : Number(0).toFixed(this.userdata.decimals_allowed),
      "rate": item.price
    };
    if (this.soItemList.length > 0) {
      var isItemExists = false;
      if (this.lastPage !== 'qouteinvoice') {
        this.soItemList.forEach(i => {
          if ((i.prodid == item.id) && !isItemExists) {
            i.qty = (i.qty ? Number(i.qty) : 0) + (item.qty ? Number(item.qty) : 0);
            i.hrs = (i.hrs ? Number(i.hrs) : 0) + (item.qty ? Number(item.qty) : 0);
            isItemExists = true;
          }
        })
      }
      if (!isItemExists) {
        for (var i = 0; i <= this.soItemList.length; i++) {
          if (!this.soItemList[i].prodid) {
            this.soItemList[i] = itmObj
            break
          }
          if (i == this.soItemList.length - 1) {
            this.soItemList.push(itmObj);
            break;
          }
        }
      }
    } else {
      this.soItemList = [];
      this.soItemList.push(itmObj);
    }
  }

  selectsubcatEvent(event) {
    this.pcatg.subcategoryid = event.Value;
    this.searchProduct()
  }


  selectcatEvent(event) {
    this.pcatg.categoryid = event.Value;
    this.searchProduct();
    this.selectCategory();
  }

  selectdeptEvent(event) {
    this.pcatg.deptid = event.Value;
    this.searchProduct();
    this.selectDept()
  }

  onDeptFocused(event) {
    this.Depart.clear();
  }

  onCatFocused(event) {
    this.Cat.clear();
  }

  onSubFocused(event) {
    this.SubCat.clear();
  }
}
