<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <form (keydown.enter)="getMarkersList()">
        <div class="custom-row">
          <div class="form-row">
            <div class="col-lg-8"></div>
            <div class="col-lg-4 col-md-6 form-group">
              <label for="">What are you looking for?</label>
              <input type="text" class="form-control" placeholder="Search by description" name="description"
                [(ngModel)]="description">
            </div>
          </div>
          <div class="btn-group CsearchBtn1 CsearchBtn" role="group">
            <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
              (click)="getMarkersList()"><i class="refresh-btn fa fa-search"></i></button>
              <button type="button" container="body" ngbTooltip="Refresh" class="ml-2 btn btn-primary right-radius left-radius" (click)="refresh()"><i class="refresh-btn fa fa-refresh"></i></button>
            <div ngbDropdown class="d-inline-block ActionCell" container="body">
              <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
              <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                <li><span ngbDropdownItem (click)="open()">Add Marker</span></li>
              </ul>
            </div>
          </div>
        </div>
      </form>
        <div class="category-table custom-datatable marker-table">
          <div class="table-responsive">
            <ng2-smart-table [settings]="settings" [source]="markersList"></ng2-smart-table>
          </div>
          <div class="total-count" *ngIf="totalRows">
            <b class="mr-1">Total rows : </b> {{totalRows}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>


<!-- <ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Leave Type</h5>
    <button type="button" class="close" aria-label="Close" name="close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation">
      <div class="form row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="">Select Site</label>
          <select class="form-control" name="siteid" [(ngModel)]="markerData.siteid">
            <option value="">Select Site</option>
            <option *ngFor="let item of locationList" [value]="item.Value">{{item.Text}}</option>
          </select> -->
<!-- <ng-autocomplete #Location [data]="locationList" [searchKeyword]="searchLocation" placeholder="Search Site"
            (selected)='selectLocation($event)' [itemTemplate]="itemTemplate2" (inputCleared)="inputLocationCleared()"
            (inputFocused)='onFocusedLocation($event)' [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
          <ng-template #itemTemplate2 let-item>
            <a [innerHTML]="item.Text"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="">Parent Marker Type</label>
          <select class="form-control" name="marker_type" id="marker_type" [(ngModel)]="markerData.marker_type"
            (change)="getSubTypes()">
            <option value="">Select Marker Type</option>
            <option *ngFor="let item of types" [value]="item.Value">{{item.Text}}</option>
          </select>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="">Marker Type</label>
          <select class="form-control" name="sub_marker_type" id="sub_marker_type"
            [(ngModel)]="markerData.sub_marker_type">
            <option value="">Select Marker Type</option>
            <option *ngFor="let item of subTypes" [value]="item.Value">{{item.Text}}</option>
          </select>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="">Description </label>
          <input class="form-control" name="description" [(ngModel)]="markerData.description" name="description">
        </div>
        <div class="form-group col-md-12 col-lg-6 col-md-6 col-sm-12">
          <label for="">Active </label>
          <select class="form-control" name="active" [(ngModel)]="markerData.active">
            <option [value]="true">Yes</option>
            <option [value]="false">No</option> 
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" name="close" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="saveMarker()" name="save">Save</button>
  </div>
</ng-template>-->