import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppServices } from '../../service/app.services';
import { Events } from '../../service/events.service';
import { NavService, Menu } from '../../service/nav.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
  searchText: any;
  public menuItems: any;
  public url: any;
  public fileurl: any;
  public userData: any;
  public menuList: any;
  public filteredList: any = [];
  appname = environment.APP_NAME;
  companyLogo: any;
  constructor(private router: Router, private events: Events, public navServices: NavService, public service: AppServices) {
    // this.getMenus();
    this.applyMenu();
    this.userData = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.companyLogo = this.userData.logo ? environment.DOC_URL + '/' + this.userData.companyid + '/' + this.userData.logo : 'assets/images/applogo.svg'
    // this.navServices.items.subscribe(menuItems => {
    //   this.menuItems = menuItems
    //   this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       menuItems.filter(items => {
    //         if (items.path === event.url)
    //           this.setNavActive(items)
    //         if (!items.children) return false
    //         items.children.filter(subItems => {
    //           if (subItems.path === event.url)
    //             this.setNavActive(subItems)
    //           if (!subItems.children) return false
    //           subItems.children.filter(subSubItems => {
    //             if (subSubItems.path === event.url)
    //               this.setNavActive(subSubItems)
    //           })
    //         })
    //       })
    //     }
    //   })
    // })

    this.events.subscribe('dashboard:menu', (event: any) => {
      this.applyMenu();
      this.userData = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
      this.companyLogo = this.userData.logo ? environment.DOC_URL + '/' + this.userData.companyid + '/' + this.userData.logo : 'assets/images/applogo.svg'

    });
  }

  applyMenu() {
    if (!localStorage.getItem(this.appname + 'menu')) {
      return;
    }
    this.menuList = JSON.parse(localStorage.getItem(this.appname + 'menu'));
    this.menuItems = JSON.parse(localStorage.getItem(this.appname + 'menu'));
    environment.production ? '' : console.log('menu', this.menuItems);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItems.filter(items => {
          if (items.ang_root === event.url)
            this.setNavActive(items)
          if (!items.children) return false
          items.children.filter(subItems => {
            if (subItems.ang_root === event.url)
              this.setNavActive(subItems)
            if (!subItems.children) return false
            subItems.children.filter(subSubItems => {
              if (subSubItems.ang_root === event.url)
                this.setNavActive(subSubItems)
            })
          })
        })
      }
    })
  }

  filterMenu() {
    if (this.searchText == '') {
      this.filteredList = [];
      return false;
    }
    var searchText = this.searchText.toLowerCase();
    this.filteredList = this.menuList.filter(f => {
      if (f.title.toLowerCase().includes(searchText))
        return f.title.toLowerCase().includes(searchText)
      if (!f.children) return false;
      f.children.some(c => c.title.includes(searchText))
    }


    );
    console.log(this.filteredList)
    // this.menuList.filter(items => {
    //   if(items.title.toLowerCase().includes(this.searchText.toLowerCase())) {
    //     this.filteredList.push(items);
    //   }
    //   if(!items.children) return false;
    //   items.children.filter(subItems => {
    //     if (subItems.title.toLowerCase().includes(this.searchText.toLowerCase())) {
    //       this.filteredList.push(items);   
    //     }
    //     if (!subItems.children) return false;
    //     subItems.children.filter(subSubItems => {
    //       if (subSubItems.title.toLowerCase().includes(this.searchText.toLowerCase()))
    //         this.filteredList.push(items); 
    //     })
    //   }) 
    // })
  }
  getMenus() {
    if (localStorage.getItem(this.appname + 'menu') != null) {
      this.menuList = JSON.parse(localStorage.getItem(this.appname + 'menu'));
      this.menuItems = JSON.parse(localStorage.getItem(this.appname + 'menu'));
      return;
    }
    this.service.getMenuList().subscribe((res: any) => {

      if (res != null) {
        localStorage.setItem(this.appname + 'menu', JSON.stringify(res));
      }
      environment.production ? '' : console.log('Sidebar: ', res);
      this.menuList = res;
      this.menuItems = res;
      environment.production ? '' : console.log('menu', this.menuItems);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.menuItems.filter(items => {
            if (items.ang_root === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.ang_root === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.ang_root === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    }, err => {
      environment.production ? '' : console.log('check err', err);
    });
  }
  //Active Nave state
  setNavActive(item) {
    // this.menuItems.filter(menuItem => {
    //   if (menuItem != item)
    //     menuItem.active = false
    //   if (menuItem.children && menuItem.children.includes(item))
    //     menuItem.active = true
    //   if (menuItem.children) {
    //     menuItem.children.filter(submenuItems => {
    //       if (submenuItems.children && submenuItems.children.includes(item)) {
    //         menuItem.active = true
    //         submenuItems.active = true
    //       }
    //     })
    //   }
    // })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  navigate(item) {
    if (item.type == 'link') {
      if (item.ang_root == '/login') {
        window.localStorage.clear();
        localStorage.clear();
        this.events.publish('event:logout', {
          data: {
            'from': 'header logout'
          }
        });
      }
      this.router.navigate([item.ang_root], { replaceUrl: true });
    }
  }

}
