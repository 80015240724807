<div class="row">
  <div class="col-lg-9 col-md-9 col-12 form-group"></div>
  <div class="col-lg-3 col-md-3 col-12 form-group">
    <input type="search" class="form-control placehder" name="searchText" placeholder="&#xF002; Search"
      [(ngModel)]="searchText">
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center"> <a class="add-table-button cursor-pointer" data-toggle="modal"
                data-original-title="Add" container="body" ngbTooltip="Add Supplier" [class.disabled]="fieldsetDisabled"
                (click)="open(content,null)"><i class="fa fa-plus"></i>
              </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'name')">
              Name <i class="Sorting fa fa-chevron-up"></i>
            </th>

            <th class="custom-th text-center" (click)="onSortClick($event,'primarycontact')">
              Contact <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'islocal')">
              is Local <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'itemcode')">
              Item Code <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'cost')">
              Cost <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'email')">
              Email <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'isprimary')">
              Primary <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createby')">
              CreateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'createdate')">
              CreateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updateby')">
              UpdateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'updatedate')">
              UpdateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of tabsupplierList | supplierfilter: searchText">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="goToView(content,item)">View</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="goToEdit(content,item)">Edit</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="deleteSupplier(item)">Delete</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.name}}</td>
            <td class="custom-td text-left">{{item.primarycontact}}</td>
            <td class="custom-td text-center">{{item.islocal}}</td>
            <td class="custom-td text-center">{{item.itemcode}}</td>
            <td class="custom-td text-right">{{item.cost | number:'1.2-2'}}</td>
            <td class="custom-td text-left">{{item.email}}</td>
            <td class="custom-td text-center">{{item.isprimary}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.createdate |
              date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.updatedate |
              date:userdata.displaydatetimeformat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Supplier</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="form row">
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="" class="mb-1">Supplier</label>
          <select class="form-control" name="to bin location" [(ngModel)]="supplierData.supplierid"
            #supplierid="ngModel"
            [ngClass]="{ 'is-invalid': supplierid.invalid && supplierid.errors , 'is-valid': supplierid.valid}"
            required>
            <option value="">Select Supplier</option>
            <option *ngFor="let item of supplierList" [value]="item.Value">
              {{item.Text}}</option>
          </select>
          <div *ngIf="supplierid.invalid && supplierid.errors" class="invalid-feedback d-block">
            <div *ngIf="supplierid.errors.required">
              Please select supplier
            </div>
          </div>
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="" class="mb-1">Item Code</label>
          <input class="form-control" type="text" name="item ode" [(ngModel)]="supplierData.itemcode"
            #itemcode="ngModel"
            [ngClass]="{ 'is-invalid': itemcode.invalid && itemcode.errors , 'is-valid': itemcode.valid}" required>
          <div *ngIf="itemcode.invalid && itemcode.errors" class="invalid-feedback d-block">
            <div *ngIf="itemcode.errors.required">
              Please select itemcode
            </div>
          </div>
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="" class="mb-1">Cost</label>
          <input class="form-control" type="text" name="cost" [(ngModel)]="supplierData.cost" #cost="ngModel"
            [ngClass]="{ 'is-invalid': cost.invalid && cost.errors , 'is-valid': cost.valid}" required>
          <div *ngIf="cost.invalid && cost.errors" class="invalid-feedback d-block">
            <div *ngIf="cost.errors.required">
              Please enter cost
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="" class="">Active</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="active">
              <input class="radio_animated" [value]="true" [(ngModel)]="supplierData.active" id="active" type="radio"
                name="active">
              Yes
            </label>
            <label class="d-block" for="active1">
              <input class="radio_animated" [value]="false" [(ngModel)]="supplierData.active" id="active1" type="radio"
                name="active">
              No
            </label>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="" class="">Is Primary</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="isprimary">
              <input class=" radio_animated" [value]="true" [(ngModel)]="supplierData.isprimary" id="isprimary"
                type="radio" name="isprimary">
              Yes
            </label>
            <label class="d-block" for="isprimary1">
              <input class="radio_animated" [value]="false" [(ngModel)]="supplierData.isprimary" id="isprimary1"
                type="radio" name="isprimary">
              No
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView" (click)="postSupplier()">Save</button>
  </div>
</resize-border>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>
