<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Bill Payment</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Date of Payment </label>
          <mat-form-field class="bg-white">
            <input matInput [matDatepicker]="picker26" [(ngModel)]="payBillData.trandate" placeholder="Choose a date"
              name="trandate">
            <mat-datepicker-toggle matSuffix [for]="picker26"></mat-datepicker-toggle>
            <mat-datepicker #picker26></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Payment Method </label>
            <div class="input-group mb-3">
              <select class="form-control" required="" name="mop" [(ngModel)]="payBillData.mop"
                (change)="getPaymentAccounts()">
                <option value="">Select Payment Method</option>
                <option *ngFor="let item of paymentMethods" [value]="item.Value">{{item.Text}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Cash/Bank</label>
            <div class="input-group mb-3">
              <select class="form-control" required="" name="bankid" [(ngModel)]="payBillData.bankid"
                (ngModelChange)="selectCheckBooks()">
                <option value="">Select Cash/Bank</option>
                <option *ngFor="let item of companyBanks" [value]="item.Value">{{item.Text}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="isShowChequeFields">
          <div class="form-group">
            <label>Check Book</label>
            <select class="form-control" required="" name="checkbookid" [(ngModel)]="payBillData.checkbookid"
              (change)="getCheckBookNos()">
              <option value="">Select Checkbook</option>
              <option *ngFor="let item of checkbookList" [value]="item.Value">{{item.Text}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group" *ngIf="isShowChequeFields">
          <label for="">Number eg. Cheque</label>
          <input type="text" class="form-control" readonly [(ngModel)]="payBillData.number" placeholder="Cheque#">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <div class="form-group">
            <label>Currency </label>
            <div class="input-group mb-3">
              <select class="form-control" id="currency" required="" (change)="selectROE()" name="currency"
                [(ngModel)]="payBillData.currency">
                <option value="">Select currency</option>
                <option *ngFor="let item of currencyList" [value]="item.Value">
                  {{item.Text}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Exchange Rate</label>
          <input type="text" class="form-control" placeholder="Exchange rate" name="ROE" [(ngModel)]="payBillData.roe"
            readonly>
        </div>

        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Amount</label>
          <input type="number" class="form-control" placeholder="Amount" name="amt" (change)="updatePayAmt()"
            [(ngModel)]="payBillData.amt">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Memo</label>
          <input type="text" class="form-control" placeholder="Memo" name="memo" [(ngModel)]="payBillData.memo">
        </div>
        <div class="col-lg-12 form-group">
          <label for="">Notes </label>
          <textarea class="form-control" placeholder="Notes" name="notes" [(ngModel)]="payBillData.notes" rows="5">
            </textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" (click)="billPayment()">Save</button>
    </div>
  </div>
</resize-border>