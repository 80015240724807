<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Compose email
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Contact name</label>
          <input type="text" class="form-control" [(ngModel)]="compose.to_name" name="title" placeholder="Name">
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
          <label>Default Email Template</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="use_template">
              <input class="radio_animated" [value]=true id="use_template" type="radio" name="use_template"
                [(ngModel)]="compose.use_template">
              Yes
            </label>
            <label class="d-block" for="use_template1">
              <input class="radio_animated" [value]=false id="use_template" type="radio" name="use_template"
                [(ngModel)]="compose.use_template">
              No
            </label>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
          <label>Default Email Sender</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="default_sender">
              <input class="radio_animated" [value]=true id="default_sender" type="radio" name="default_sender"
                [(ngModel)]="compose.default_sender">
              Yes
            </label>
            <label class="d-block" for="default_sender1">
              <input class="radio_animated" [value]=false id="default_sender" type="radio" name="default_sender"
                [(ngModel)]="compose.default_sender">
              No
            </label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Email To (Comma Seperated Email List)</label>
          <textarea class="form-control" [(ngModel)]="compose.to" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">CC (Comma Seperated Email List)</label>
          <textarea class="form-control" [(ngModel)]="compose.cc" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">BCC</label>
          <textarea class="form-control" [(ngModel)]="compose.bcc" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Subject</label>
          <textarea class="form-control" [(ngModel)]="compose.subject" rows="2"></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Message (optional)</label>
          <textarea class="form-control" [(ngModel)]="compose.msg" rows="2"></textarea>
        </div>

      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" type="button" (click)="save()">Send</button>
    </div>
  </div>
</resize-border>