import { DatePipe } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { POSServices } from './pos.service';
import { SalesServices } from '../Sales/sales.service';
import { AppServices } from 'src/app/shared/service/app.services';
import { OpeningbalanceComponent } from './openingbalance/openingbalance.component';
import { GenericprintComponent } from '../modal/genericprint/genericprint.component';
import { ComposeemailComponent } from '../modal/composeemail/composeemail.component';
import { SchedulerServices } from '../scheduler/scheduler.service';
import { ProjjoblistmodalComponent } from '../modal/projjoblistmodal/projjoblistmodal.component';
import { QuickaddproductComponent } from '../modal/quickaddproduct/quickaddproduct.component';
import { CustomerlistComponent } from '../modal/customerlist/customerlist.component';
import { purchaseServices } from '../purchase/purchase.service';
import { AssemblycatalogComponent } from '../modal/assemblycatalog/assemblycatalog.component';
import { SalesrequestComponent } from '../modal/salesrequest/salesrequest.component';
import { PurchaserequestComponent } from '../modal/purchaserequest/purchaserequest.component';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { QuotemodalComponent } from '../modal/quotemodal/quotemodal.component';
import { PickuporderComponent } from '../modal/pickuporder/pickuporder.component';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { CustomerslistComponent } from '../servicemanager/servicecontracts/customerlist/customerlist.component';
import { SerialsmodalComponent } from '../modal/serialsmodal/serialsmodal.component';
import { SinglereceivepaymentComponent } from '../modal/singlereceivepayment/singlereceivepayment.component';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit, OnDestroy {
  @ViewChild('Customer') Customer;
  @ViewChild('Project') Project;
  @ViewChild('Department') Department;
  @ViewChild('Category') Category;
  @ViewChild('SubCatg') SubCatg;
  @ViewChild(CustomerslistComponent, { static: false }) CustomersComponent: CustomerslistComponent;
  @ViewChild(ProjjoblistmodalComponent, { static: false }) ProjectsComponent: ProjjoblistmodalComponent;
  public isShow: boolean = false
  pipe = new DatePipe('en-US');

  // Department
  setDepartmentValue: any;
  departmentKeyword = 'name';
  validatedept: any;

  // Category
  setCategoryValue: any;
  categoryKeyword = 'name';
  validateCateg: any;

  // Sub Category
  setSubCatgValue: any;
  subCatgKeyword = 'name';
  validatesubcatg: any;

  intervalId;
  today = null;
  closeResult: string;
  isCollapsed: boolean = true;
  multiPayment = [1];
  keyword = 'name';
  customer: any = {
    showmulticurrencyonreceipts: false,
    billactualhrs: false,
    authorizationreq: false,
    billindividual: false,
    latefee_enable: false,
    billsbyemail: false,
    billsbyprint: false,
    monthlyarstatements: false,
    reminderoncontractexpiry: false,
    remindme: false,
    rph: 0,
    creditlimit: 0,
    discount: 0,
    latefee: 0,
    status: 'active',
    creditstatus: 'inactive',
    customertype: '',
    currency: '',
    latefee_type: 'percentage',
    parentid: '',
  }
  editSetCustomer: any;
  currencyList: any = [];
  creditStatusList: any;
  customerTypeList: any = [];
  latefeeTypeList: any = [];
  projectStatusList: any = [];
  searchItem: any = {
    pricingtype: 'retail',
    deptid: '',
    categoryid: '',
    subcategoryid: '',
    searchtext: '',
    qtyonhand: true
  }
  proj: any = {};
  project: any = {
    status: '',
  }
  projectTypeList: any = [];
  editSetProjType: any;
  ProjTypeInputValue: any;
  ProjStatusInputValue: any;
  editSetProjStatus: any;
  productList: any;
  categoryList: any = [];
  subCategoryList: any = [];
  departmentList: any = [];
  isReturnOrder: any = false;
  postOrder: any;
  paying: any = {
    cash: 0,
    checks: 0,
    creditcard: 0,
    debitcard: 0,
    change: 0,
    duebalance: 0,
    memo: '',
    isauthorizecredit: false,
    onaccount: 0
  }
  orderEnteries: any = [];
  totalItems: any = 0;
  itemTotal: any = 0;
  uploader: FileUploader;
  response: string;
  userdata: any;
  statusList: any = [];
  orderStatusList: any = [];
  deptList: any = [];
  appName = environment.APP_NAME;
  submitted = false;
  orderTotal: any = 0;
  totalDisc: any = 0;
  totalTax: any = 0;
  totalShipping: any = 0;
  projectList: any = [];
  projectListData: any = []
  projectName: any = 'name';
  customerinfo: any = {
    discount: 0,
    name: null
  };
  technicianList: any = [];
  techkeyword: any = 'title';
  tickeList: any = [];
  itemPictures: any = [];
  priceingTypeList: any = [];
  closingReport: any;
  public customerForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    shortname: new FormControl(''),
    tpaccno: new FormControl(''),
    customertype: new FormControl(''),
    currency: new FormControl(''),
    showmulticurrencyonreceipts: new FormControl(''),
    creditperiod: new FormControl(''),
    creditlimit: new FormControl(''),
    creditstatus: new FormControl(''),
    rph: new FormControl(''),
    billactualhrs: new FormControl(''),
    discount: new FormControl(''),
    latefee_enable: new FormControl(''),
    latefee_type: new FormControl(''),
    latefee: new FormControl(''),
    contractexpirydate: new FormControl(''),
    reminderoncontractexpiry: new FormControl(''),
    parentid: new FormControl(''),
    status: new FormControl(''),
    accountno: new FormControl(''),
    monthlyarstatements: new FormControl(''),
    authorizationreq: new FormControl(''),
    // contact tab
    poc: new FormControl(''),
    primaryphone: new FormControl(''),
    secondaryphone: new FormControl(''),
    fax: new FormControl(''),
    mainemail: new FormControl(''),
    secondaryemail: new FormControl(''),
    //Billing Address Tab
    billaddress1: new FormControl(''),
    billaddress2: new FormControl(''),
    billaddress3: new FormControl(''),
    billaddress4: new FormControl(''),
    remindme: new FormControl(''),
    billindividual: new FormControl(''),
    billsbyemail: new FormControl(''),
    billsbyprint: new FormControl(''),
    //Shipping Address Tab
    shipaddress1: new FormControl(''),
    shipaddress2: new FormControl(''),
    shipaddress3: new FormControl(''),
    shipaddress4: new FormControl(''),
    terms: new FormControl(''),
    notes: new FormControl(''),
  });
  payment: any = {
    mop: '',
    techid: ''
  };
  paymentMethodList: any = [];
  paymentTypeList: any = []
  recentOrders: any = {
    fdate: null,
    edate: null,
    type: '',
    status: '',
    tpsync: '',
    searchtxt: ''
  };
  recentOrderList: any = [];
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    ariaLabelledBy: 'modal-basic-title',
    size: 'xl',
    windowClass: 'index-class', backdropClass: 'index-backdrop'
  };
  productSellUOMList: any = [];
  discountTypeList: any = [];
  taxesList: any = [];
  soOrderTypes: any = [];
  paymentTermsList: any = [];
  currentDateTime: any;
  // @ViewChild('openingbalance') openingbalance;
  constructor(private toastr: NotificationService, private titleService: Title, private purchaseService: purchaseServices, private schedulerService: SchedulerServices, private commoanService: CommonServices, private appService: AppServices, private salesServices: SalesServices, private formBuilder: FormBuilder, private route: ActivatedRoute, private loader: NgxUiLoaderService, private router: Router, private datePipe: DatePipe, private service: POSServices, private commonService: CommonServices, private modalService: NgbModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appName + 'userprofile'));
    this.initialize();
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#region fromdate & Todate
    // this.recentOrders.fdate = this.userdata.fromdate;
    this.recentOrders.edate = moment().add(1, 'days').format('YYYY-MM-DD');
    this.recentOrders.fdate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.commonService.getCurrency().subscribe(res => {
      this.currencyList = res;
      environment.production ? '' : console.log('currency data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading currency data', err);
    });
    this.commonService.getComboEnums('customercreditstatus').subscribe(res => {
      this.creditStatusList = res;
      environment.production ? '' : console.log('customer creditStatusList data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading customer types list data', err);
    });
    this.commonService.getComboEnums('orderpricingtype').subscribe(res => {
      this.priceingTypeList = res;
    }, err => {
      environment.production ? '' : console.log('Error : While loading customer types list data', err);
    });
    this.commonService.getComboEnums('latefeetype').subscribe(res => {
      this.latefeeTypeList = res;
      environment.production ? '' : console.log('fees type data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading fees type data', err);
    });
    this.commonService.getComboEnums('orderstatus').subscribe(res => {
      this.orderStatusList = res;
      environment.production ? '' : console.log('orderstatus  data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading orderstatus  data', err);
    });
    this.commonService.getComboEnums('paymenttype').subscribe(res => {
      this.paymentTypeList = res;
      environment.production ? '' : console.log('payment types data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment types data', err);
    });
    this.commoanService.getComboEnums('discounttype').subscribe(res => {
      this.discountTypeList = res;
      environment.production ? '' : console.log(' ptype list ', this.discountTypeList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading ptype list ', err);
    });
    this.commoanService.getTaxList('sales').subscribe(res => {
      this.taxesList = res;
      environment.production ? '' : console.log('type taxes list data ', this.taxesList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });
    this.schedulerService.getSchedulerResources().subscribe((res: any) => {
      this.technicianList = res;
    });
    this.commoanService.getComboEnums('paymentterms').subscribe(res => {
      this.paymentTermsList = res;
      environment.production ? '' : console.log(' term List ', this.paymentTermsList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading term List ', err);
    });
    // this.initializeFileUploader();
    this.getDepartments();
    this.getCategories();
    this.getProdUom();
    this.getpaymethods();
    this.getSalesOrderTypes();
  }
  gotoDashboard() {
    this.router.navigate(['/dashboard'], { replaceUrl: true });
  }
  getpaymethods() {
    this.commonService.getPOSPaymentMethods().subscribe(res => {
      this.paymentMethodList = res;
      this.paymentMethodList.forEach(item => {
        item.pay = 0;
      })
      environment.production ? '' : console.log('payment method data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment method data', err);
    });
  }
  getSalesOrderTypes() {
    this.soOrderTypes = []
    this.commoanService.getSOTypes().subscribe((res: any) => {
      this.soOrderTypes = res;
    }, err => {
    });
  }
  refreshScreen() {
    this.productList = [];
    this.searchItem = {
      pricingtype: 'retail',
      deptid: '',
      categoryid: '',
      subcategoryid: '',
      searchtext: '',
      qtyonhand: true
    }
    this.Department ? this.Department.clear() : '';
    this.Category ? this.Category.clear() : '';
    this.SubCatg ? this.SubCatg.clear() : '';
    this.Department ? this.Department.close() : '';
    this.Category ? this.Category.close() : '';
    this.SubCatg ? this.SubCatg.close() : '';
    this.refreshOrder();

  }
  initialize() {
    this.postOrder = {
      "id": null,
      "ordertype": "",
      "companyid": "",
      "customerid": "",
      "adjustment": 0,
      "istaxable": false,
      "status": "",
      "orderno": "",
      "seq": "",
      "notes": "",
      "createby": "",
      "createdate": "",
      "updateby": "",
      "updatedate": "",
      "salesrep": this.userdata.email,
      "orderdate": null,
      "currency": this.userdata.currency,
      "title": "",
      "payment_terms": "dueonreceipt",
      "terms": '',
      "tax": 0,
      "discount": 0,
      "shippingcharges": 0,
      "holdfordays": 0,
      "returnfee": 0,
      "authorizedby": "",
      "authorizationnotes": "",
      "qbsync": false,
      "vpsync": false,
      "signfile": "",
      "cno": "",
      "projid": "",
      "taskid": "",
      "cpsync": false,
      "sagesync": false,
      "tallysync": false,
      "pricingtype": this.userdata.default_pricing_type,
      "mforderno": "",
      "printtotalonly": false,
      "deliverynotes": "",
      "deliveredon": "",
      "cidprooftype": "",
      "cidnumber": "",
      "cidexpiry": "",
      "lastaction": "",
      "collectedon": "",
      "signedby": "",
      "deliverytime": "",
      "isposted": false,
      "postdate": "",
      "postedby": "",
      "donotpost": false,
      "paiddate": "",
      "tags": "",
      "roe": "1",
      "rpt_link": "",
      "t_orderentry": [],
      "keepchange": false,
      "ticketid": '',
      "dis_amt": 0,
      "discount_type": '',
      "line_total_amt": 0,
      "origin": 'webpos',
      "priority": "medium",
    }
    this.isReturnOrder = false;
    this.totalItems = 0;
    this.itemTotal = 0;
    this.totalDisc = 0;
    this.totalTax = 0;
    this.totalShipping = 0;
    this.orderTotal = 0;
    this.ProjectsComponent ? this.ProjectsComponent.selectedJob = '' : null;
    this.CustomersComponent ? this.CustomersComponent.selectedCustomer = '' : null;
  }
  openingbalanceModal() {
    var modalRef = null
    modalRef = this.modalService.open(OpeningbalanceComponent, { windowClass: 'index-class', backdropClass: 'index-backdrop' })
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.today = this.pipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a');
    }, 1000);
    this.titleService.setTitle('POS');
    this.customerForm = this.formBuilder.group({
      name: ['', Validators.required],
      shortname: ['', Validators.required],
      tpaccno: ['', Validators.required],
      customertype: ['', Validators.required],
      currency: ['', Validators.required],
      showmulticurrencyonreceipts: [''],
      creditperiod: ['', Validators.required],
      creditlimit: ['', Validators.required],
      creditstatus: ['', Validators.required],
      rph: ['', Validators.required],
      billactualhrs: [''],
      discount: ['', Validators.required],
      latefee_enable: [''],
      latefee_type: ['', Validators.required],
      latefee: ['', Validators.required],
      contractexpirydate: ['', Validators.required],
      reminderoncontractexpiry: ['', Validators.required],
      parentid: [''],
      status: ['', Validators.required],
      accountno: ['', Validators.required],
      monthlyarstatements: [''],
      authorizationreq: [''],
      // contact tab
      poc: ['', Validators.required],
      primaryphone: ['', Validators.required],
      secondaryphone: ['', Validators.required],
      fax: [''],
      mainemail: ['', Validators.required],
      secondaryemail: [''],
      //Billing Address Tab
      billaddress1: ['', Validators.required],
      billaddress2: ['', Validators.required],
      billaddress3: [''],
      billaddress4: [''],
      remindme: [''],
      billindividual: [''],
      billsbyemail: [''],
      billsbyprint: [''],
      //Shipping Address Tab
      shipaddress1: ['', Validators.required],
      shipaddress2: ['', Validators.required],
      shipaddress3: [''],
      shipaddress4: [''],
      terms: [''],
      notes: [''],
    })
  }
  get f(): { [key: string]: AbstractControl } {
    return this.customerForm.controls;
  }
  getPOSOrderById(id, type) {
    this.closeModal();
    this.loader.start();
    this.service.getPOSOrderByID(id).subscribe(res => {
      this.loader.stop();
      this.postOrder = res;
      this.customerinfo = this.postOrder.customerinfo;
      this.orderEnteries = this.postOrder ? this.postOrder.orderLines : [];
      if (type == 'return' || type == 'clone') {
        this.customerinfo = type == 'clone' ? { discount: 0, name: null } : this.customerinfo;
        this.isReturnOrder = type == 'return' ? true : false;
        this.postOrder.id = null;
        this.postOrder.customerid = type == 'return' ? this.postOrder.customerid : null;
        this.postOrder.ordertype = '';
        this.postOrder.cno = type == 'return' ? this.postOrder.orderno : '';
        this.postOrder.title = type == 'return' ? this.postOrder.title : '';
        this.postOrder.t_ordertransactions = (type == 'return' || type == 'clone') ? [] : this.postOrder.t_ordertransactions;
        this.postOrder.vpsync = false;
        this.postOrder.payment_terms = 'net30';
        this.postOrder.orderno = null;
        this.orderEnteries.forEach(item => {
          item.id = null;
          item.createdate = this.datePipe.transform(new Date(), this.userdata.postdatetimeformat);
          item.createby = this.userdata.email;
          item.updateby = null;
          item.updatedate = null;
          item.orderid = null;
          item.deletedate = null;
          item.deleteby = null;
          item.active = true;
          item.qtyrtd = 0;
          item.serial = type == 'clone' ? '' : item.serial
        })
      } else {
        this.isReturnOrder = false;
      }
      this.calculateOrderTotal();
    }, err => {
      this.loader.stop();
    })
  }
  setTerms() {
    if (this.postOrder.title != '' && this.postOrder.title != null) {
      this.postOrder.payment_terms = 'net30';
    } else {
      this.postOrder.payment_terms = 'dueonreceipt';
    }
  }

  selectSerials(index, item) {
    var modalRef = null;
    modalRef = this.modalService.open(SerialsmodalComponent, { size: 'sm' });
    modalRef.componentInstance.serials = item.serial;
    modalRef.componentInstance.selectedSerial = item.selectedSerial ? item.selectedSerial : '';
    modalRef.result.then((result: any) => {
      if (result) {
        var srls = result.split(',');
        item.qty = srls.length;
        item.selectedSerial = result;
        this.calculateLineItemTotal(item);
        this.toastr.Success('Submitted');
      }
    }).catch((error) => {
    });
  }

  getDepartments() {
    this.loader.start();
    this.commoanService.getDepartmentList(false).subscribe((res: any) => {
      this.loader.stop();
      this.departmentList = [];
      if (res == null) {
        return;
      }
      res.forEach(element => {
        this.departmentList.push({
          name: element.Text,
          id: element.Value
        })
      });
      this.getItems();
      environment.production ? '' : console.log(' department list data ', this.departmentList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }

  // ***
  selectDepartment(item) {
    environment.production ? '' : console.log('selected dept', item);
    this.searchItem.deptid = item.id;
    this.searchItem.categoryid = '';
    this.searchItem.subcategoryid = '';
    this.validateCateg = '';
    this.validatesubcatg = '';
    this.getCategories();
  }
  onAnySearchChange(item) {
    environment.production ? '' : console.log('selected dept', item);
  }
  inputClearedDepartment() {
    this.searchItem.deptid = null;
    // this.getCategories();
    environment.production ? '' : console.log('input remvoed input cleared ', this.searchItem.deptid);
  }
  onfocusedDepartment(e) {
    this.Department.clear();
    environment.production ? '' : console.log('input onfocused input cleared ', this.searchItem.deptid);
  }

  getCategories() {
    this.loader.start();
    this.commoanService.getCategory(this.searchItem.deptid, false).subscribe((res: any) => {
      this.loader.stop();
      this.categoryList = [];
      if (res == null) {
        return;
      }
      res.forEach(element => {
        this.categoryList.push({
          name: element.Text,
          id: element.Value
        })
      });
      this.getItems();
      environment.production ? '' : console.log(' category list data ', this.categoryList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  // **
  // ***
  selectCategory(item) {
    environment.production ? '' : console.log('selected dept', item);
    this.searchItem.categoryid = item.id;
    this.searchItem.subcategoryid = '';
    this.validatesubcatg = '';
    this.getSubCategories();
  }

  inputClearedCategory() {
    this.searchItem.categoryid = null;
    environment.production ? '' : console.log('input remvoed input cleared ', this.searchItem.categoryid);
  }
  onfocusedCategory(e) {
    this.Category.clear();
  }

  getSubCategories() {
    this.loader.start();
    this.commonService.getSubCategory(this.searchItem.categoryid).subscribe(res => {
      this.loader.stop();
      this.subCategoryList = res;
      this.getItems();
      environment.production ? '' : console.log(' subcategory list data ', this.subCategoryList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  // ***
  selectSubCatg(item) {
    environment.production ? '' : console.log('selected dept', item);
    this.searchItem.subcategoryid = item.id;
    // this.getSubCategories();
  }

  inputClearedSubCatg() {
    this.searchItem.subcategoryid = null;
    environment.production ? '' : console.log('input remvoed input cleared ', this.searchItem.subcategoryid);
  }
  onfocusedSubCatg(e) {
    this.SubCatg.clear();
  }

  openAssemblyCatalog() {
    var modalRef = null
    modalRef = this.modalService.open(AssemblycatalogComponent, this.ngbModalOptions);
    modalRef.componentInstance.soItemList = this.orderEnteries;
    modalRef.result.then((result) => {
      var itemList = result;
      if (itemList.length > 0) {
        itemList.forEach(item => {
          this.addItem(item);
        })
      }
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  openDetailsModal() {
    var modalRef = null;
    modalRef = this.modalService.open(ProjjoblistmodalComponent, this.ngbModalOptions);
    modalRef.componentInstance.searhtxt = this.postOrder.title ? this.postOrder.title : '';
    modalRef.componentInstance.customerid = '';
    modalRef.result.then((result) => {
      if (result == null || result == '') {
        return;
      }
      this.customerinfo = {
        accountno: result.accountno,
        authorizationreq: result.authorizationreq,
        balance: result.balance ? result.balance : 0,
        creditlimit: result.creditlimit ? result.creditlimit : 0,
        currency: result.currency ? result.currency : null,
        deposit: result.deposit ? result.deposit : 0,
        discount: result.discount ? result.discount : 0,
        giftcard: result.giftcard ? result.giftcard : 0,
        id: result.customerid,
        loyality: result.loyalitycredit ? result.loyalitycredit : 0,
        mainemail: result.mainemail,
        name: result.customername,
        primaryphone: result.primaryphone,
        salestotal: result.salestotal ? result.salestotal : 0,
        shortname: result.shortname ? result.shortname : null,
        status: result.status ? result.status : null,
        tpaccno: result.tpaccno ? result.tpaccno : null
      };
      this.postOrder.customerid = result.customerid;
      this.postOrder.title = result.tpcode ? result.tpcode : result.proj;
      this.setTerms();
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }

  selectedJob() {
    this.ProjectsComponent.searchtxt = ''; //this.postOrder.title ? this.postOrder.title : '';
    this.ProjectsComponent.customerid = this.postOrder.customerid ? this.postOrder.customerid : '';
    if (this.ProjectsComponent.selectedJob) {
      if (this.ProjectsComponent.selectedJob == null || this.ProjectsComponent.selectedJob == '') {
        return;
      }
      this.customerinfo = {
        accountno: this.ProjectsComponent.selectedJob.accountno,
        authorizationreq: this.ProjectsComponent.selectedJob.authorizationreq,
        balance: this.ProjectsComponent.selectedJob.balance ? this.ProjectsComponent.selectedJob.balance : 0,
        creditlimit: this.ProjectsComponent.selectedJob.creditlimit ? this.ProjectsComponent.selectedJob.creditlimit : 0,
        currency: this.ProjectsComponent.selectedJob.currency ? this.ProjectsComponent.selectedJob.currency : null,
        deposit: this.ProjectsComponent.selectedJob.deposit ? this.ProjectsComponent.selectedJob.deposit : 0,
        discount: this.ProjectsComponent.selectedJob.discount ? this.ProjectsComponent.selectedJob.discount : 0,
        giftcard: this.ProjectsComponent.selectedJob.giftcard ? this.ProjectsComponent.selectedJob.giftcard : 0,
        id: this.ProjectsComponent.selectedJob.customerid,
        loyality: this.ProjectsComponent.selectedJob.loyalitycredit ? this.ProjectsComponent.selectedJob.loyalitycredit : 0,
        mainemail: this.ProjectsComponent.selectedJob.mainemail,
        name: this.ProjectsComponent.selectedJob.customername,
        primaryphone: this.ProjectsComponent.selectedJob.primaryphone,
        salestotal: this.ProjectsComponent.selectedJob.salestotal ? this.ProjectsComponent.selectedJob.salestotal : 0,
        shortname: this.ProjectsComponent.selectedJob.shortname ? this.ProjectsComponent.selectedJob.shortname : null,
        status: this.ProjectsComponent.selectedJob.status ? this.ProjectsComponent.selectedJob.status : null,
        tpaccno: this.ProjectsComponent.selectedJob.tpaccno ? this.ProjectsComponent.selectedJob.tpaccno : null
      };
      this.postOrder.customerid = this.ProjectsComponent.selectedJob.customerid;
      this.postOrder.title = this.ProjectsComponent.selectedJob.tpcode ? this.ProjectsComponent.selectedJob.tpcode : this.ProjectsComponent.selectedJob.proj;
      this.setTerms();
    }
  }
  openPrint(id) {
    var modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'index-class', backdropClass: 'index-backdrop' })
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.name = 'printso';
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  openEmailState(id) {
    var modalRef = this.modalService.open(ComposeemailComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'index-class', backdropClass: 'index-backdrop' });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.flag = 'sales_order';
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  doPayment(item) {
    var modalRef = this.modalService.open(SinglereceivepaymentComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' });
    modalRef.componentInstance.params = {
      "companyname": item.customer,
      "customerid": item.customerid,
      "status": item.status,
      "refno": item.orderno,
      "projref": item.orderref,
      "currency": item.currency,
      "bal": item.dueamount,
      "pay": item.dueamount,
      "refid": item.id,
      "ttype": 2,//sales order transaction
      "invdate": item.orderdate,
      "roe": item.roe
    };
    modalRef.result.then((result) => {
      this.getPOSRecentOrders();
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  getItems() {
    this.loader.start();
    this.service.getPOSItems(this.postOrder.pricingtype, this.searchItem.qtyonhand, this.searchItem.deptid, this.searchItem.categoryid, this.searchItem.subcategoryid, this.searchItem.searchtext).subscribe(res => {
      this.loader.stop();
      this.productList = res;
      this.productList.forEach(prod => {
        if (!prod.qtyonhand) {
          prod.qtyonhand = 0;
        }
        if (prod.pic) {
          prod.pic = environment.DOC_URL + '/' + this.userdata.companyid + '/' + prod.pic;
        }
      })
      environment.production ? '' : console.log(' product list data ', this.productList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  initializeFileUploader() {
    this.uploader = new FileUploader({
      url: environment.API_URL,
      headers: [{ name: 'Authorization', value: 'Bearer ' + this.userdata.token }],
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
            companyid: this.userdata.companyid
          });
        });
      }
    });
    this.response = '';
    this.uploader.response.subscribe(res => this.response = res);
  }
  getProdUom() {
    this.purchaseService.getProdUom().subscribe(res => {
      this.productSellUOMList = res;
      environment.production ? '' : console.log(' prod uom list data ', this.productSellUOMList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading prod uom list ', err);
    });
  }
  addItem(item) {
    var isItemExists = false;
    if (this.orderEnteries.length > 0) {
      this.orderEnteries.forEach(element => {
        if (element.prodid == item.id) {
          element.qty++;
          isItemExists = true;
          this.calculateLineItemTotal(element);
        }
      });
    }
    if (!isItemExists) {
      if (item.taxid) {
        this.taxesList.forEach(tax => {
          if (tax.Value == item.taxid) {
            item.tax = tax.Value2;
            item.tax_type = tax.Value1;
          }
        })
      }
      this.orderEnteries.push({
        "id": null,
        "orderid": null,
        "prodid": item.id,
        "cost": item.cost,
        "price": item.price,
        "qty": item.qty ? item.qty : 1,
        "uom": item.uom ? item.uom : 'EA',
        "description": item.desc,
        "qtyrtd": 0,
        "active": true,
        "updateby": null,
        "updatedate": null,
        "ilc": item.ilc,
        "discount": this.customerinfo.discount ? this.customerinfo.discount : (item.discount ? item.discount : 0),
        "discount_type": this.customerinfo.discount ? 'percent' : (item.discount_type ? item.discount_type : 'percent'),
        "dis_amt": 0,
        "tax": item.tax ? item.tax : 0,
        "tax_type": item.tax_type ? item.tax_type : null,
        "tax_amt": 0,
        "taxid": item.taxid ? item.taxid : 0,
        "bin": "",
        "isservice": false,
        "returnable": item.retunable,
        "qtyreturn": 0,
        "isserilized": item.isserilized,
        "tpcode": item.tpcode,
        "serial": "",
        "grp": "",
        "geid": null,
        "deplete_qty": 0,
        "lotid": null,
        "linetype": "",
        "tech": "",
        "datecreated": "",
        "line_total": 0,
      });
      this.calculateLineItemTotal(this.orderEnteries[this.orderEnteries.length - 1]);
    }
  }
  calculateLineItemTotal(item) {
    if (item.taxid) {
      this.taxesList.forEach(tax => {
        if (tax.Value == item.taxid) {
          item.taxid = tax.Value;
          item.tax = tax.Value2;
          item.tax_type = tax.Value1;
        }
      })
    } else {
      item.taxid = null;
      item.tax = 0;
      item.tax_type = 'amount';
    }
    var amount = Number(item.qty ? item.qty : 0) * Number(item.price ? item.price : 0);
    var discount = 0;
    if (item.discount && item.discount_type == 'percent') {
      discount = Number(item.discount) / 100;
    } else if (item.discount && item.discount_type == 'amount') {
      discount = Number(item.discount);
    }
    item.dis_amt = (item.discount_type == 'percent' ? (amount * discount) : discount);
    var tax = 0;
    if (item.tax && item.tax_type == 'percent') {
      tax = Number(item.tax) / 100;
    } else if (item.tax && item.tax_type == 'amount') {
      tax = Number(item.tax);
    }
    amount = amount - (item.discount_type == 'percent' ? (amount * discount) : discount);
    item.tax_amt = (item.tax_type == 'percent' ? (amount * tax) : tax);
    amount = amount + (item.tax_type == 'percent' ? (amount * tax) : tax);
    item.line_total = amount;
    item.cost = Number(item.cost ? item.cost : 0);
    item.price = Number(item.price ? item.price : 0);
    item.qty = Number(item.qty ? item.qty : 0);
    this.calculateOrderTotal();
  }
  calculateOrderTotal() {
    this.totalItems = this.orderEnteries.length;
    this.itemTotal = 0;
    this.totalDisc = 0;
    this.totalTax = 0;
    this.orderEnteries.forEach(item => {
      this.itemTotal += Number(item.line_total);
      this.totalDisc += Number(item.dis_amt);
      this.totalTax += Number(item.tax);
    })
    var finalTotal = this.itemTotal + (this.postOrder.adjustment ? Number(this.postOrder.adjustment) : 0) + (this.postOrder.shippingcharges ? Number(this.postOrder.shippingcharges) : 0);
    this.orderTotal = finalTotal;
  }
  qtyOnFocusOutEvent(item) {
    this.calculateOrderTotal();
  }
  minus(item) {
    if (item.qty == 1) {
      return;
    }
    item.qty--;
    this.calculateLineItemTotal(item);
  }
  plus(item) {
    item.qty++;
    this.calculateLineItemTotal(item);
  }
  deleteItem(item, index) {
    if (item.id > 0) {
      this.loader.start();
      this.service.deletePOSOrderEntry(item.id).subscribe(res => {
        this.loader.stop();
        if (res != null) {
          Swal.fire('', 'Item deleted successfully', 'success');
        }
        environment.production ? '' : console.log('item deleted', res);
      }, err => {
        this.loader.stop();
        environment.production ? '' : console.log('Error : While delete item', err);
      });
    }
    this.orderEnteries.splice(index, 1);
    this.calculateOrderTotal();
  }
  refreshOrder() {
    this.customerinfo = {
      discount: 0
    };
    this.editSetCustomer = {};
    this.initialize();
    this.orderEnteries = [];
    this.calculateOrderTotal();
    this.getItems();
  }
  submitOrder(status) {
    if (this.postOrder.customerid == undefined || this.postOrder.customerid == null || this.postOrder.customerid == '') {
      this.toastr.Error('Please select customer');
      return;
    }
    if (this.postOrder.ordertype == undefined || this.postOrder.ordertype == null || this.postOrder.ordertype == '') {
      this.toastr.Error('Please select order type');
      return;
    }
    var t_ordertransactions = [];
    if (status != 'draft') {
      this.paymentMethodList.forEach(item => {
        if (item.pay > 0) {
          var payAmt = item.pay;
          t_ordertransactions.push({
            "id": null,
            "orderid": null,
            "currency": this.userdata.currency,
            "mop": item.Value,
            "trandate": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            "tranby": this.userdata.email,
            "memo": this.paying.memo,
            "change": 0,
            "techid": this.postOrder.techid,
            "authby": null,
            "authnotes": null,
            "companyid": this.userdata.companyid,
            "notes": this.paying.memo,
            "amt": parseFloat(payAmt).toFixed(2),
            "roe": 1,
            "signby": null,
            "signature": null,
            "isposted": false,
            "postdate": null,
            "postedby": null,
            "donotpost": false,
            "keepchange": false,
            "paytype": null,
            "rpid": null,
            "createby": this.userdata.email,
            "createdate": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            "updateby": null,
            "updatedate": null,
            "tpcode": null,
          })
        }
      })
      this.postOrder.txntype = this.isReturnOrder ? 'salesreturn' : 'salesreceipt';//for cashout
    } else {
      this.postOrder.txntype = 'salesorder';//for draft
    }
    if (this.postOrder.id > 0) {
      this.postOrder.updateby = this.userdata.email;
      this.postOrder.updatedate = this.datePipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.postOrder.createby = this.userdata.email;
      this.postOrder.createdate = this.datePipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.postOrder.companyid = this.userdata.companyid;
    }
    this.postOrder.orderdate = this.postOrder.id ? this.datePipe.transform(new Date(this.postOrder.orderdate), this.userdata.postdateformat) : this.datePipe.transform(new Date(), this.userdata.postdateformat);
    this.postOrder.duedate = this.postOrder.id ? this.datePipe.transform(new Date(this.postOrder.duedate), this.userdata.postdateformat) : this.datePipe.transform(new Date(), this.userdata.postdateformat);
    // this.postOrder.discount = this.totalDisc;
    if (this.isReturnOrder) {
      this.orderEnteries.forEach(item => {
        item.serial = item.selectedSerial ? item.selectedSerial : item.serial;
      })
    }
    this.postOrder.t_orderentry = this.orderEnteries;
    this.postOrder.status = status;
    this.postOrder.t_ordertransactions = t_ordertransactions;
    // this.postOrder.line_total_amt = this.orderTotal; commited by mayur line total showing all amt + adj + delivery fees
    this.postOrder.line_total_amt = this.itemTotal;

    if (status == 'paid' && t_ordertransactions.length > 0 && this.paying.onaccount > 0) {
      this.postOrder.status = 'partiallypaid'
    } else if (status == 'paid' && t_ordertransactions.length == 0 && this.paying.onaccount > 0) {
      this.postOrder.status = 'open'
    } else if (status == 'paid' && t_ordertransactions.length > 0 && this.paying.onaccount == 0) {
      this.postOrder.status = 'paid'
    }
    delete this.postOrder.orderTranscations;
    this.isReturnOrder ? delete this.postOrder.t_ordertransactions : '';
    environment.production ? '' : console.log('pos order req ', JSON.stringify(this.postOrder));
    this.loader.start();
    this.service.postPOSOrder(this.postOrder).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        if (this.postOrder.status != 'draft') {
          this.printAck(res.refid);
        }
        this.orderEnteries = [];
        this.initialize();
        this.modalService.dismissAll();
        this.customerinfo = {};
      }
      environment.production ? '' : console.log('pos order post rest', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While post order ', err);
    });
  }

  inputTechnicianCleared() {
    this.postOrder.techid = null;
  }
  selectTechnician(item) {
    this.postOrder.techid = item.employee_id
  }
  onFocused(item) {

  }
  // viewPicture(content, item) {
  //   this.itemPictures = item.pictures;
  //   this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  viewPicture(content, item) {
    this.loader.start();
    this.commoanService.getProductPictures(item.id).subscribe(res => {
      this.loader.stop();
      this.itemPictures = res;
      if (this.itemPictures.length == 0) {
        this.toastr.Error('No pictures found');
        return;
      } else {
        this.itemPictures.forEach(img => {
          if (img.filename) {
            img.filename = environment.DOC_URL + '/' + this.userdata.companyid + '/' + img.filename;
          }
        });
      }
      this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }, err => {
      this.loader.stop();
    })
  }
  openRecentOrder(content) {
    this.refreshRecentOrders();
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  openTransaction(content) {
    this.paying.isauthorizecredit = false;
    if (this.postOrder.customerid == undefined || this.postOrder.customerid == '' || this.postOrder.customerid == null) {
      Swal.fire('', 'Please select customer', 'warning');
      return;
    }
    if (this.postOrder.ordertype == undefined || this.postOrder.ordertype == '' || this.postOrder.ordertype == null) {
      Swal.fire('', 'Please select order type', 'warning');
      return;
    }
    this.calculateBalance();
    if (this.isReturnOrder) {
      this.submitOrder('refund');
      return;
    }
    this.getpaymethods()
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openQuote() {
    var modalRef = this.modalService.open(QuotemodalComponent, { size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' });
    // modalRef.componentInstance.name = this.postOrder.customername;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  pickupOrder(item) {
    var modalRef = this.modalService.open(PickuporderComponent, { size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' });
    modalRef.componentInstance.id = item.id;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
      this.getPOSRecentOrders();
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  openquickAddProdModal() {
    var modalRef = this.modalService.open(QuickaddproductComponent, { windowClass: 'index-class', backdropClass: 'index-backdrop' });
    modalRef.componentInstance.name = this.postOrder.customername;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  openCustomerList() {
    var modalRef = this.modalService.open(CustomerlistComponent, this.ngbModalOptions);
    modalRef.componentInstance.name = this.customerinfo.name;
    modalRef.result.then((result) => {
      if (result != null) {
        this.customerinfo = result;
        this.postOrder.customerid = this.customerinfo.id;
        this.customerinfo.balance = result.balance ? result.balance : 0;
        this.customerinfo.creditlimit = result.creditlimit ? result.creditlimit : 0;
        this.customerinfo.deposit = result.deposit ? result.deposit : 0;
        this.customerinfo.discount = result.discount ? result.discount : 0;
        this.customerinfo.giftcard = result.giftcard ? result.giftcard : 0;
        this.customerinfo.loyality = result.loyalitycredit ? result.loyalitycredit : 0;
        this.customerinfo.salestotal = result.salestotal ? result.salestotal : 0;
        this.calculateOrderTotal();
      }
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  SelectedCustomer() {
    if (this.CustomersComponent.selectedCustomer) {
      if (this.CustomersComponent.customer.name == undefined || this.CustomersComponent.customer.name == '' || this.CustomersComponent.selectedCustomer.id == null || this.CustomersComponent.selectedCustomer.id == '') {
        return;
      }
      this.customerinfo = this.CustomersComponent.selectedCustomer;
      this.postOrder.customerid = this.customerinfo.id;
      this.customerinfo.balance = this.CustomersComponent.selectedCustomer.balance ? this.CustomersComponent.selectedCustomer.balance : 0;
      this.customerinfo.creditlimit = this.CustomersComponent.selectedCustomer.creditlimit ? this.CustomersComponent.selectedCustomer.creditlimit : 0;
      this.customerinfo.deposit = this.CustomersComponent.selectedCustomer.deposit ? this.CustomersComponent.selectedCustomer.deposit : 0;
      this.customerinfo.discount = this.CustomersComponent.selectedCustomer.discount ? this.CustomersComponent.selectedCustomer.discount : 0;
      this.customerinfo.giftcard = this.CustomersComponent.selectedCustomer.giftcard ? this.CustomersComponent.selectedCustomer.giftcard : 0;
      this.customerinfo.loyality = this.CustomersComponent.selectedCustomer.loyalitycredit ? this.CustomersComponent.selectedCustomer.loyalitycredit : 0;
      this.customerinfo.salestotal = this.CustomersComponent.selectedCustomer.salestotal ? this.CustomersComponent.selectedCustomer.salestotal : 0;
      this.calculateOrderTotal();
      this.initializeProjRef();
      this.ProjectsComponent.selectedJob = null;
      this.ProjectsComponent.jobList = [];
      this.CustomersComponent.customer.name = '';
    }
  }
  initializeProjRef() {
    this.postOrder.projid ? this.postOrder.title = '' : null;
    this.postOrder.ticketid ? this.postOrder.title = '' : null;
    this.postOrder.projid = '';
    this.postOrder.ticketid = '';
  }
  open(content) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.modalService.dismissAll();
  }
  collapseshow() {
    this.isCollapsed = !this.isCollapsed;
  }
  dropdowntoggle() {
    this.isShow = !this.isShow;
  }

  suspendSales() {

  }

  salesReturn() {

  }
  refreshRecentOrders() {
    this.recentOrders.searchtxt = '';
    this.recentOrders.type = '';
    this.recentOrders.status = ''
    this.recentOrders.edate = moment().add(1, 'days').format('YYYY-MM-DD');
    this.recentOrders.fdate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.getPOSRecentOrders();
  }
  // getPOSRecentOrders
  getPOSRecentOrders() {
    this.loader.start();
    this.service.getPOSRecentOrders(this.recentOrders.searchtxt, this.recentOrders.type, this.recentOrders.status, this.datePipe.transform(new Date(this.recentOrders.fdate), this.userdata.postdateformat), this.datePipe.transform(new Date(this.recentOrders.edate), this.userdata.postdateformat)).subscribe(res => {
      this.loader.stop();
      this.recentOrderList = res;
      this.recentOrderList.forEach(item => {
        var status = item.status ? item.status.toLowerCase() : '';
        if (item.to_be_received == 0) {
          item.isshowpickup = false;
        } else {
          item.isshowpickup = true;
        }
        if (status == 'draft') {
          item.isshowedit = true;
        } else {
          item.isshowedit = false;
        }
        if (status == 'collected') {
          item.isshowreturn = true;
        } else {
          item.isshowreturn = false;
        }
        if (status == 'closed') {
          item.isshowpay = false;
        } else {
          item.isshowpay = true;
        }
      })
      environment.production ? '' : console.log(' RecentOrders list data ', this.recentOrderList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading RecentOrders list ', err);
    });
  }

  registerDetails(registerdetails) {
    this.modalService.open(registerdetails, { size: 'xl', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  goBack() {
    this.router.navigate[('/dashboard')];
  }
  //#region DropdownEvents
  customerType: any;
  changeCustType(e: any) {
    let getcustomerTypeData = e.target.value;
    this.customerType = getcustomerTypeData;
  }

  currency: any;
  changecurrency(e: any) {
    let getcurrencyData = e.target.value;
    this.currency = getcurrencyData;
  }

  creditstatus: any;
  changecreditstatus(e: any) {
    let getcreditstatusData = e.target.value;
    this.creditstatus = getcreditstatusData;
  }

  latefee_type: any;
  changelatefee_type(e: any) {
    let getlatefee_typeData = e.target.value;
    this.latefee_type = getlatefee_typeData;
  }

  changeparentid: any;
  changechangeparentid(e: any) {
    let getchangeparentidData = e.target.value;
    this.changeparentid = getchangeparentidData;
  }

  status: any;
  changestatus(e: any) {
    let getstatusData = e.target.value;
    this.status = getstatusData;
  }
  //#endregion DropdownEvents
  addCustomer() {
    if (!this.customer.name) {
      Swal.fire('', 'Please enter name', 'warning');
      return;
    }
    if (!this.customer.primaryphone) {
      Swal.fire('', 'Please enter primaryphone', 'warning');
      return;
    }
    this.submitted = true;
    if (this.customerForm.invalid) {
      // return;
    }
    environment.production ? '' : console.log('customer data', this.customer);
    var obj = {
      id: null,
      customertype: this.customer.customertype,
      phone: this.customer.phone,
      name: this.customer.name,
      mainemail: this.customer.mainemail,
      secondaryemail: this.customer.secondaryemail,
      currency: this.customer.currency,
      companyid: this.userdata.companyid,
      shortname: this.customer.shortname,
      accountno: null,
      poc: this.customer.poc,
      primaryphone: this.customer.primaryphone,
      secondaryphone: this.customer.secondaryphone,
      fax: this.customer.fax,
      showmulticurrencyonreceipts: this.customer.showmulticurrencyonreceipts,
      billaddress1: this.customer.billaddress1,
      billaddress2: this.customer.billaddress2 ? this.customer.billaddress2 : null,
      billaddress3: this.customer.billaddress3 ? this.customer.billaddress3 : null,
      billaddress4: this.customer.billaddress4 ? this.customer.billaddress4 : null,
      shipaddress1: this.customer.shipaddress1 ? this.customer.shipaddress1 : null,
      shipaddress2: this.customer.shipaddress2 ? this.customer.shipaddress2 : null,
      shipaddress3: this.customer.shipaddress3 ? this.customer.shipaddress3 : null,
      shipaddress4: this.customer.shipaddress4 ? this.customer.shipaddress4 : null,
      creditperiod: this.customer.creditperiod ? Number(this.customer.creditperiod) : null,
      creditlimit: this.customer.creditlimit ? Number(this.customer.creditlimit) : this.customer.creditlimit,
      discount: this.customer.discount ? Number(this.customer.discount) : this.customer.discount,
      billactualhrs: this.customer.billactualhrs,
      billindividual: this.customer.billindividual,
      reminderoncontractexpiry: this.customer.reminderoncontractexpiry,
      latefee_type: this.customer.latefee_type,
      latefee_enable: this.customer.latefee_enable,
      latefee: Number(this.customer.latefee),
      rph: Number(this.customer.rph),
      creditstatus: this.customer.creditstatus,
      authorizationreq: this.customer.authorizationreq,
      billsbyemail: this.customer.billsbyemail,
      billsbyprint: this.customer.billsbyprint,
      monthlyarstatements: this.customer.monthlyarstatements,
      contractexpirydate: this.datePipe.transform(this.customer.contractexpirydate, this.userdata.postdateformat),
      status: this.customer.status,
      notice4outsiders: this.customer.notice4outsiders,
      tpaccno: this.customer.tpaccno,
      notes: this.customer.notes,
      updateby: null,
      updatedate: null,
      remindme: this.customer.remindme,
      parentid: this.customer.parentid,
      seq: this.customer.seq,
      createby: this.userdata.email,
      createdate: this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
    }
    this.loader.start();
    this.salesServices.postcustomer(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.modalService.dismissAll();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post rfq ', err);
    });
  }
  // environment.production ? '' : console.log('customer data', JSON.stringify(obj));
  // return false;


  //################Calculate Due Balance###################
  calculateBalance() {
    this.paying.duebalance = 0;
    this.paying.change = 0;
    var totalPay = 0;
    if (this.paying.cash != undefined && this.paying.cash != null && this.paying.cash != '' && this.paying.cash > 0) {
      totalPay += Number(this.paying.cash);
    }
    if (this.paying.checks != undefined && this.paying.checks != null && this.paying.checks != '' && this.paying.checks > 0) {
      totalPay += Number(this.paying.checks);
    }
    if (this.paying.debitcard != undefined && this.paying.debitcard != null && this.paying.debitcard != '' && this.paying.debitcard > 0) {
      totalPay += Number(this.paying.debitcard);
    }
    if (this.paying.creditcard != undefined && this.paying.creditcard != null && this.paying.creditcard != '' && this.paying.creditcard > 0) {
      totalPay += Number(this.paying.creditcard);
    }
    if (Number(this.orderTotal) > totalPay) {
      this.paying.duebalance = Number(this.orderTotal) - totalPay;
    }
    if (Number(this.orderTotal) < totalPay) {
      this.paying.change = totalPay - Number(this.orderTotal);
    }
    if (this.paying.isauthorizecredit && this.paying.duebalance > 0) {
      this.paying.onaccount = this.paying.duebalance;
      this.paying.duebalance = 0;
    }
    if (!this.paying.isauthorizecredit) {
      this.paying.onaccount = 0
    }
  }
  //########################################################
  calculateDueBalance() {
    this.paying.duebalance = 0;
    this.paying.change = 0;
    var totalPay = 0;
    this.paymentMethodList.forEach(item => {
      if (item.pay != undefined && item.pay != null && item.pay != '' && item.pay > 0) {
        totalPay += Number(item.pay);
      }
    })
    if (Number(this.orderTotal) > totalPay) {
      this.paying.duebalance = Number(this.orderTotal) - totalPay;
    }
    if (Number(this.orderTotal) < totalPay) {
      this.paying.change = totalPay - Number(this.orderTotal);
    }
    if (this.paying.isauthorizecredit && this.paying.duebalance > 0) {
      this.paying.onaccount = this.paying.duebalance;
      this.paying.duebalance = 0;
    } else {
      this.paying.onaccount = 0;
    }
    this.paying.duebalance = Number(this.paying.duebalance).toFixed(2);
  }

  // Carousel
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true,
    center: true,
  };

  //Create Purchase Request
  addPurchaseReq(product) {
    var obj = {
      "id": null,
      "status": "newreq",
      "pon": "",
      "requestby": this.userdata.email,
      "description": "",
      "companyid": this.userdata.companyid,
      "ponotes": "",
      "updateby": null,
      "updatedate": null,
      "supplierid": null,
      "createby": this.userdata.email,
      "createdate": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      "origin": "web",
      "rfqno": null,
      "orderref_extra": "",
      "duedate": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      "priority": "medium",
      "type": "",
      "estimated_deliver_time": 0,
      "requestedfor": "",
      "requestdate": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      "seq": null,
      "req_no": null,
      "orderref": this.postOrder.title ? this.postOrder.title : '',
      "t_prs_entry": []
    }
    obj.t_prs_entry = [{
      "id": null,
      "prsid": null,
      "prodid": product.id,
      "qty": 1,
      "ilc": product.ilc,
      "description": product.desc,
      "cost": product.pocost,
      "qtyavailable": product.qtyavailable ? product.qtyavailable : 0,
      "qtyonhand": product.qtyonhand ? product.qtyonhand : 0,
      "reorderqty": product.reorderqty ? product.reorderqty : 0,
      "puom": product.puom ? product.puom : '',
      "newitem": false,
      "uom": product.uom ? product.uom : '',
    }]
    this.loader.start();
    this.purchaseService.postPurchaseRequest(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
    }, err => {
      this.loader.stop();
    })
  }
  //Create Sales Request
  addSalesRequest(item) {
    if (this.postOrder.customerid == undefined || this.postOrder.customerid == null || this.postOrder.customerid == '') {
      this.toastr.Error("Please select customer")
      return false
    }
    var obj = {
      "id": null,
      "customerid": this.postOrder.customerid,
      "description": "",
      "status": "newreq",
      "orderno": "",
      "requestby": this.userdata.email,
      "companyid": this.userdata.companyid,
      "type": "",
      "requestdate": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      "createby": this.userdata.email,
      "createdate": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      "rpt_link": "",
      "error_code": null,
      "error_msg": null,
      "mw_batchno": null,
      "updateby": null,
      "updatedate": null,
      "duedate": moment().add(7, 'days').format('YYYY-MM-DD'),
      "request_type": "",
      "priority": "medium",
      "orderref_extra": "",
      "requested_for": "",
      "origin": "web",
      "seq": null,
      "req_no": null,
      "orderref": this.postOrder.title ? this.postOrder.title : '',
      "t_srs_entry": []
    }
    obj.t_srs_entry = [
      {
        "id": null,
        "srsid": null,
        "prodid": item.id,
        "qty": 1,
        "ilc": item.ilc,
        "description": item.desc,
        "binid": null,
        "createby": this.userdata.email,
        "createdate": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      }
    ]
    this.loader.start();
    this.salesServices.postSalesRequests(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post invoice data ', err);
    });
  }

  // Open Sales Req modal
  openSalesReq() {
    var modalRef = null
    modalRef = this.modalService.open(SalesrequestComponent, { size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  // Open Purchase Req modal
  openPurchaseReq() {
    var modalRef = null
    modalRef = this.modalService.open(PurchaserequestComponent, { size: 'xl', windowClass: 'index-class', backdropClass: 'index-backdrop' })
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  print(item, name) {
    var modalRef = null;
    modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'index-class', backdropClass: 'index-backdrop' })
    modalRef.componentInstance.id = item.id;
    modalRef.componentInstance.name = name;//'printso';
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  printAck(id) {
    Swal.fire({
      title: 'Would you like to print a receipt?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.print({ id: id }, 'printsr')
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  getXReport() {
    this.loader.start();
    this.service.getXReport().subscribe(res => {
      this.loader.stop();
      this.closingReport = res;
      this.closingReport.forEach(item => {
        item.amt = item.amt ? Number(item.amt).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      })
    }, err => {
      this.loader.stop();
    })
  }
  openReport(action) {
    var modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'index-class', backdropClass: 'index-backdrop' })
    modalRef.componentInstance.id = '';
    modalRef.componentInstance.name = (action == 'xprint' ? 'printxreport' : 'printzreport');
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  closeRegister(content) {
    this.currentDateTime = this.datePipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.getXReport();
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
