import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SchedulerServices } from 'src/app/components/scheduler/scheduler.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { serviceManagerServices } from '../../../servicemanager.service';
import { AddticketsComponent } from '../../addtickets/addtickets.component';


@Component({
  selector: 'app-edittimematerialmodal',
  templateUrl: './edittimematerialmodal.component.html',
  styleUrls: ['./edittimematerialmodal.component.scss']
})
export class EdittimematerialmodalComponent implements OnInit {
  fullScreen = false;
  timenmaterial: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  ticket: any = { ticketItems: [] };
  tempList: any = []
  @Input('params') params;
  lineItemType: any
  technicianList: any = []
  isView: any;
  product: any;
  isSaved=false;
  constructor(private datepipe: DatePipe,
    private toastr: NotificationService,
    private cdr: ChangeDetectorRef,
    private activemodel: NgbActiveModal,
    private commonService: CommonServices,
    private ticketService: serviceManagerServices,
    private schedulerService: SchedulerServices,
    private loader: NgxUiLoaderService,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
  }

  ngOnInit(): void {
    this.product = this.params.item;
    this.isView = this.params.action;
    this.ticket = this.params.ticket;
    this.schedulerService.getSchedulerResources().subscribe((res: any) => {
      this.technicianList = res;
      const arr = this.ticket.assignto ? this.ticket.assignto.split(',') : [];
      var filteredTech = [];
      this.technicianList.forEach(data => {
        arr.forEach(res => {
          if (res == data.id) {
            filteredTech.push(data);
          }
        })
      })
      this.technicianList = filteredTech;
      console.log(this.technicianList);
    });
    this.commonService.getWorkTypes().subscribe((res: any) => {
      this.lineItemType = res;
    });
    this.getProdBin();
  }
  getProdBin() {
    this.commonService.getProdBins(this.product.prodid).subscribe(res => {
      this.tempList = res;
      this.timenmaterial = {
        status: this.product.status,
        bookingtime: this.product.bookingtime,
        companyid: this.product.companyid,
        description: this.product.description,
        id: this.product.id,
        ilc: this.product.ilc,
        ischecked: this.product.ischecked,
        isserilized: this.product.isserilized,
        notes: this.product.notes,
        prodid: this.product.prodid,
        qty: this.product.qty,
        rate: this.product.rate,
        tech: this.product.tech,
        ticketItemLocations: this.product.ticketItemLocations
      };
      if (this.timenmaterial.isserilized) {
        const commonKey = "name";
        this.tempList.forEach(element => {
          if (this.timenmaterial.isserilized) {
            element.isserilized = true
            element.serialList = element.serial_numbers ? element.serial_numbers.split(',') : [];
            element.serialList = element.serialList.map(element => ({
              [commonKey]: element
            }));
            element.serialList.forEach(data => {
              data.checked = false;
            });
          }
        });

        this.tempList.forEach(element => {
          if (element.serialList) {
            element.serialList.forEach(item => {
              this.timenmaterial.ticketItemLocations.forEach(data => {
                if (item.name === data.serial) {
                  item.checked = true;
                  item.id = data.id;
                  item.binid = data.binid;
                  item.tid = data.tiid;
                  item.prodid = data.prodid;
                  item.code = data.code
                }
              });
            });
          }

        });
        this.tempList.forEach(element => {
          element.qty = 0;
        });
        console.log('1st time obj', this.tempList);
        this.tempList.forEach(element => {
          if (element.serialList) {
            element.serialList.forEach(item => {
              if (item.checked) {
                element.qty += 1
              } else {
                item.id = null;
                item.binid = element.binid;
                item.tid = this.timenmaterial.id;
                item.prodid = element.prodid;
                item.code = element.code
              }
            });
          }
        });
        this.timenmaterial.ticketItemLocations = this.tempList;
        console.log('obj', this.tempList);

      } else {
        this.tempList.forEach(element => {
          this.timenmaterial.ticketItemLocations.forEach(data => {
            if (element.code === data.code) {
              element.qty = data.qty;
              element.binid = data.binid;
              element.oldid = data.id
              element.tid = data.tiid
              element.prodid = data.prodid
            }
          });
        });
        this.timenmaterial.ticketItemLocations = this.tempList;
      }

      this.cdr.detectChanges();
      this.calculateTotalQty();
    }, err => {
      environment.production ? '' : console.log('Error : While loading binlist data', err);
    });
  }
  saveSingleLineItem() {
    var items = [];
    var updatedLineItem = []
    if (!this.timenmaterial.isserilized) {
      this.timenmaterial.qty = 0;
      this.timenmaterial.ticketItemLocations.forEach(element => {
        if (element.qty > 0 || element.oldid) {
          updatedLineItem.push({
            binid: element.binid,
            code: element.code,
            qty: element.qty,
            serial: element.name,
            id: element.oldid ? element.oldid : null,
            tiid: element.tid ? element.tid : this.timenmaterial.id,
            prodid: element.prodid
          })
          this.timenmaterial.qty += element.qty;
        }
      });
    }
    else {
      this.timenmaterial.qty = 0
      this.timenmaterial.ticketItemLocations.forEach(element => {
        if (element.serialList) {
          element.serialList.forEach(data => {
            if (data.checked) {
              updatedLineItem.push({
                binid: data.binid,
                code: data.code,
                qty: 1,
                serial: data.name,
                id: data.id,
                tiid: data.tid ? data.tid : this.timenmaterial.id,
                prodid: data.prodid,
              })
            }
            // if (!data.checked && data.id !== null) {
            //   updatedLineItem.push({
            //     binid: data.binid,
            //     code: data.code,
            //     qty: 0,
            //     serial: '',
            //     id: data.id,
            //     tiid: data.tid ? data.tid : this.timenmaterial.id,
            //     prodid: data.prodid,
            //   })
            // }
          });
        }
      });
      updatedLineItem.forEach(element => {
        if (element.qty == 1) {
          this.timenmaterial.qty += element.qty
        }
      });


    }
    // this.timeAndMaterialItemList.forEach(element => {
    // 	element.serialList.forEach(data => {
    // 		if (element.isserilized) {
    // 			element.qty = element.serialList.length
    // 		} else {
    // 			element.qty += data.qty
    // 		}
    // 	});
    // });
    this.timenmaterial.ticketItemLocations = updatedLineItem;
    var totalQty = 0;
    updatedLineItem.forEach(item => {
      totalQty += item.qty ? item.qty : 0;
    })
    this.timenmaterial.tid = this.ticket.id ? this.ticket.id : this.timenmaterial.tid;
    this.timenmaterial.qty = totalQty;
    this.timenmaterial.bookingtime = this.datepipe.transform(new Date(this.timenmaterial.bookingtime), this.userdata.postdateformat);
    this.timenmaterial.updateby = this.userdata.email;
    this.timenmaterial.updatedate = this.datepipe.transform(new Date(), this.userdata.postdateformat);
    items.push(this.timenmaterial);
    var postObj = {
      "companyid": this.userdata.companyid,
      "tid": this.ticket.id ? this.ticket.id : this.timenmaterial.tid,
      "ticketitems": items,
      "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "updateby": this.userdata.email
    }
    const postData = JSON.stringify(postObj);
    environment.production ? '' : console.log(JSON.stringify(postObj));
    this.loader.start();
    this.ticketService.postTicketItems(postData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          this.isSaved=true;
          this.closeModal();
          this.timenmaterial = null;
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });
  }
  closeModal() {
    this.activemodel.close(this.isSaved ? 'save' : '');
  }

  trackByIndex(index: number, item: any): number {
    return item.name;
  }
  track(index: number, item: any) {
    return index;
  }
  onCheckboxClick(item, event: any, data) {
    // The event object contains information about the checkbox click
    const isChecked = event.target.checked;
    if (isChecked) {
      item.qty += 1;
    } else {
      item.qty -= 1;

    }
    data.checked = isChecked
    this.calculateTotalQty();
  }
  calculateTotal(item) {
    if (item.qty < 0) {
      item.qty = 0;
    }
    if (item.rate < 0) {
      item.rate = 0;
    }
    var total = Number(item.qty) * Number(item.rate);

    item.total = Number(total).toFixed(this.userdata.decimals_allowed);
  }

  checkEnterQty(item) {
    if (item.qty > item.onhand) {
      this.toastr.Error('Qty should not not be greater than onhand qty');
      item.qty = 0;
    } else {
      this.calculateTotalQty();
    }
  }
  calculateTotalQty() {
    this.timenmaterial.qty = 0;
    this.timenmaterial.ticketItemLocations.forEach(item => {
      this.timenmaterial.qty += item.qty ? Number(item.qty) : 0;
    })
    this.calculateTotal(this.timenmaterial);
  }
}
