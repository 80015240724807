<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Sales Requests
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="custom-row">
        <div class="form-row">
          <div class="col-lg-4 col-md-6 form-group"> </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="">From Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker13" placeholder="Choose a date" [(ngModel)]="salesRequest.fromdate"
                name="fromdate">
              <mat-datepicker-toggle matSuffix [for]="picker13"></mat-datepicker-toggle>
              <mat-datepicker #picker13></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="">To Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker10" placeholder="Choose a date" [(ngModel)]="salesRequest.todate"
                name="enddate">
              <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
              <mat-datepicker #picker10></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
          <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
            (click)="getSalesRequests()">Search</button>
        </div>
      </div>
      <div class="category-table custom-datatable purchase-request-table">
        <div class="table-responsive">
          <table class="custom-table">
            <thead class="sticky-tab-head">
              <tr class="custom-tr">
                <th class="custom-th text-center"> Req# </th>
                <th class="custom-th text-center"> OrderRef </th>
                <th class="custom-th text-center"> Order# </th>
                <th class="custom-th text-center"> Orderref Extra# </th>
                <th class="custom-th text-center"> Request Date </th>
                <th class="custom-th text-center"> Date Expected </th>
                <th class="custom-th text-center"> RequestBy </th>
                <th class="custom-th text-center"> Requested For </th>
                <th class="custom-th text-center"> Custome </th>
                <th class="custom-th text-center"> Origi </th>
                <th class="custom-th text-center"> Priorit </th>
                <th class="custom-th text-center"> Status </th>
                <th class="custom-th text-center"> Create Date </th>
                <th class="custom-th text-center"> Createby </th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr class="custom-tr" *ngFor="let n of salesRequestOrders;let i=index">
                  <td class="custom-td"> {{n.req_no}}</td>
                  <td class="custom-td"> {{n.orderref}}</td>
                  <td class="custom-td"> {{n.orderno}}</td>
                  <td class="custom-td"> {{n.orderref_extra}}</td>
                  <td class="custom-td"> {{n.requestdate | date:'yyy-MM-dd'}}</td>
                  <td class="custom-td"> {{n.duedate | date:'yyy-MM-dd'}}</td>
                  <td class="custom-td text-left">{{n.requestby}}</td>
                  <td class="custom-td text-left">{{n.requested_for}}</td>
                  <td class="custom-td text-left">{{n.customer}}</td>
                  <td class="custom-td text-center">{{n.origin}}</td>
                  <td class="custom-td text-center">{{n.priority}}</td>
                  <td class="custom-td text-center"><span class="badge" title="{{n.status}}">{{n.status}}</span></td>
                  <td class="custom-td">{{n.createdate | date:'yyy-MM-dd hh:mm a'}}</td>
                  <td class="custom-td text-left">{{n.createby}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</resize-border>