<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="Add Review"
                [class.disabled]="fieldsetDisabled" (click)="open(content,'','')"><i class="fa fa-plus"></i> </a></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'description')">Description<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'description')">Rating<i class="Sorting fa fa-chevron-up"></i></th>

            <th class="custom-th text-center"(click)="onSortClick($event,'description')">Active<i class="Sorting fa fa-chevron-up"></i></th>

            <th class="custom-th text-center"(click)="onSortClick($event,'description')">
              CreateBy<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'description')">
              CreateDate<i class="Sorting fa fa-chevron-up"(click)="onSortClick($event,'description')"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'description')">
              UpdateBy<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'description')">
              UpdateDate<i class="Sorting fa fa-chevron-up"></i></th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of reviewList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem data-toggle="modal" data-original-title="View" data-target="#exampleModal"
                      (click)="open(content,item,'view')">View</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" data-toggle="modal"
                      data-original-title="Edit" data-target="#exampleModal"
                      (click)="open(content,item,'edit')">Edit</span>
                  </li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled">Delete</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left" [innerHTML]="item.description"></td>
            <td class="custom-td text-left">{{item.rating}}</td>
            <td class="custom-td text-center"><span class="badge" title="{{item.active}}">{{item.active}}</span></td>

            <td class="custom-td">{{item.createby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.createdate | date : userdata.displaydatetimeformat}}
            </td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.updatedate | date : userdata.displaydatetimeformat}}
            </td>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Review</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="form row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="" class="mb-1">Rating</label>
          <input class="form-control" type="number" name="rating" [(ngModel)]="reviewData.rating" #rating="ngModel"
            [ngClass]="{ 'is-invalid': rating.invalid && rating.errors , 'is-valid': rating.valid}" required>
          <div *ngIf="rating.invalid && rating.errors" class="invalid-feedback d-block">
            <div *ngIf="rating.errors.required">
              Please enter rating
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="" class="mb-1">Description</label>
          <textarea class="form-control" rows="1" name="description" [(ngModel)]="reviewData.description"
            #description="ngModel"
            [ngClass]="{ 'is-invalid': description.invalid && description.errors , 'is-valid': description.valid}"
            required>
          </textarea>
          <div *ngIf="description.invalid && description.errors" class="invalid-feedback d-block">
            <div *ngIf="description.errors.required">
              Please enter description
            </div>
          </div>
          <!-- <textarea class="form-control" type="text" rows="7"></textarea> -->

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid || isview"
      (click)="postProductReview()">Save</button>
  </div>
</resize-border>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>
