import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { ProductsModule } from './components/inventory/products/products.module';
import { AuthModule } from './components/auth/auth.module';
import { AuthInterceptor } from './shared/service/auth.interceptor';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CommonModule, DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ExcelService } from './shared/service/excel.service';
import { FileUploadModule } from 'ng2-file-upload';
import { ToastrModule } from 'ngx-toastr';
import { TicketCopyComponent } from './components/modal/ticketcopy/ticketcopy.component';
import { TechCopyComponent } from './components/modal/techcopy/techcopy.component';
import { HomepageModule } from './components/homepage/homepage.module';
import { PosModule } from './components/pos/pos.module';
import { NgxPrintModule } from 'ngx-print';
import { NgxPrintElementModule } from 'ngx-print-element';
import { ImportComponent } from './components/modal/import/import.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ComposeemailComponent } from './components/modal/composeemail/composeemail.component';
import { SignalRModule } from 'ng2-signalr';
import { SignalRConfiguration } from 'ng2-signalr';
import { environment } from 'src/environments/environment';
import { ChatSignalr } from './components/customerservice/chatsignalr.service';
import { QuickaddproductComponent } from './components/modal/quickaddproduct/quickaddproduct.component';
import { PaymentComponent } from './components/modal/payment/payment.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from 'ng2-ckeditor';
import { CreditComponent } from './components/modal/credit/credit.component';
import { Events } from './shared/service/events.service';
import { NgxCaptureModule } from 'ngx-capture';
import { BnNgIdleService } from 'bn-ng-idle';
import { MilestoneComponent } from './components/modal/milestone/milestone.component';
import { CustomerannouncementsComponent } from './components/modal/customerannouncements/customerannouncements.component';
import { ConversionService } from './shared/service/conversion.service';
import { GenericprintComponent } from './components/modal/genericprint/genericprint.component';
import { SafePipe } from './shared/service/safepipe';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { MasterdataComponent } from './components/modal/masterdata/masterdata.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table'
import { ReceivepoComponent } from './components/modal/receivepo/receivepo.component';
import { ReceiveserilizeditemsComponent } from './components/modal/receiveserilizeditems/receiveserilizeditems.component';
import { ShortshipmentpoComponent } from './components/modal/shortshipmentpo/shortshipmentpo.component';
import { PocostingComponent } from './components/modal/pocosting/pocosting.component';
import { ProductviewComponent } from './components/modal/productview/productview.component';
import { TrackingComponent } from './components/modal/tracking/tracking.component';
import { TransferQtyComponent } from './components/inventory/products/modal/transfer-qty/transfer-qty.component';
import { SerialComponent } from './components/inventory/products/modal/serial/serial.component';
import { PrintlabelComponent } from './components/modal/printlabel/printlabel.component';
import { MakepaymentComponent } from './components/modal/makepayment/makepayment.component';
import { DocumentsComponent } from './components/modal/documents/documents.component';
import { VendorpaymentComponent } from './components/modal/vendorpayment/vendorpayment.component';
import { ItemcodelistComponent } from './components/modal/itemcodelist/itemcodelist.component';
import { ArstatementsComponent } from './components/modal/arstatements/arstatements.component';
import { ApstatementComponent } from './components/modal/apstatement/apstatement.component';
import { ShippingportComponent } from './components/modal/shippingport/shippingport.component';
import { DSCcodeComponent } from './components/modal/dsccode/dsccode.component';
import { QbonlinemodalComponent } from './components/modal/qbonlinemodal/qbonlinemodal.component';
import { GenericintegrationComponent } from './components/modal/genericintegration/genericintegration.component';
import { AssetassignmentComponent } from './components/modal/assetassignment/assetassignment.component';
import { AssetservicelogComponent } from './components/modal/assetservicelog/assetservicelog.component';
import { PurchaseinvoicepaymentComponent } from './components/modal/purchaseinvoicepayment/purchaseinvoicepayment.component';
import { PurchaserequestitemhistoryComponent } from './components/modal/purchaserequestitemhistory/purchaserequestitemhistory.component';
import { PurchaserequestitemanaylsisComponent } from './components/modal/purchaserequestitemanaylsis/purchaserequestitemanaylsis.component';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReportgenericprintComponent } from './components/modal/reportgenericprint/reportgenericprint.component';
import { ReviewTransactionsActionColumn, ReviewtransactionsComponent } from './components/modal/reviewtransactions/reviewtransactions.component';
import { LedgersComponent } from './components/modal/ledgers/ledgers.component';
import { ProductcatalogComponent } from './components/modal/productcatalog/productcatalog.component';
import { AssemblycatalogComponent } from './components/modal/assemblycatalog/assemblycatalog.component';

import { CustomersiteComponent } from './components/modal/customersite/customersite.component';
import { AddmarkerModalComponent } from './components/specmap/markers/addmarker/addmarker.component';
import { CustomerlistComponent } from './components/modal/customerlist/customerlist.component';
import { SalesrequestComponent } from './components/modal/salesrequest/salesrequest.component';
import { PurchaserequestComponent } from './components/modal/purchaserequest/purchaserequest.component';
import { ServiceitemComponent } from './components/servicemanager/servicelineitems/serviceitem/serviceitem.component';
// import { ReviewtransactionsComponent } from './components/modal/reviewtransactions/reviewtransactions.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ModalContactsComponent } from './components/modal/contacts/contacts.component';
import { DeviceComponent } from './components/modal/device/device.component';
import { SiteComponent } from './components/modal/site/site.component';
import { ScheduleComponent } from './components/scheduler/schedule/schedule.component';
import { SitesmodalComponent } from './components/modal/sitesmodal/sitesmodal.component';
import { EditassessmentComponent } from './components/editprofile/editassessment/editassessment.component';
import { BarRatingModule } from "ngx-bar-rating";
import { QuotemodalComponent } from './components/modal/quotemodal/quotemodal.component';
import { PickuporderComponent } from './components/modal/pickuporder/pickuporder.component';
import { Productcatlog4purchaseComponent } from './components/modal/productcatlog4purchase/productcatlog4purchase.component';
import { CustomerContactsModalComponent } from './components/modal/addcontact/addcontact.component';
import { SupplierContactsModalComponent } from './components/modal/addsupplier/addsupplier.component';
import { SuppliercontactComponent } from './components/modal/suppliercontact/suppliercontact.component';
import { AddcardComponent } from './components/modal/addcard/addcard.component';
import { urlSafePipe } from './components/modal/addcard/urlSafe.pipe';
import { JobopeningComponent } from './components/hr/jobopening/jobopening.component';
import { JobapplicationsComponent } from './components/hr/jobapplications/jobapplications.component';
import { SinglebillpaymentComponent } from './components/modal/singlebillpayment/singlebillpayment.component';
import { SinglereceivepaymentComponent } from './components/modal/singlereceivepayment/singlereceivepayment.component';
import { AuditComponent } from './components/modal/audit/audit.component';
import { PaymentsComponent } from './components/payroll/payments/payments.component';
import { PartysComponent } from './components/modal/partysmodal/partysmodal.component';
import { ChequetrackingComponent } from './components/modal/chequetracking/chequetracking.component';
import { ReceivedchequetrackingComponent } from './components/modal/receivedchequetracking/receivedchequetracking.component';
import { SupplierListComponent } from './components/modal/supplier-list/supplier-list.component';
import { ResizeBorderComponent } from './components/modal/resize/resize-border.component';
import { ResizeModule } from './components/modal/resize/resize-border.module';
import { SalesquoteslistmodalComponent } from './components/modal/salesquoteslistmodal/salesquoteslistmodal.component';
import { CustomerListModule } from './components/servicemanager/servicecontracts/customerlist/customerlist.module';
import { ProjjoblistpomodalComponent } from './components/modal/projjoblistpomodal/projjoblistpomodal.component';
import { DepartmentmodalComponent } from './components/modal/departmentmodal/departmentmodal.component';
import { TrackingModule } from './components/tracking/tracking.module';
import { AgmDirectionModule } from 'agm-direction';
import { NgSelectModule } from '@ng-select/ng-select';
import { SerialsmodalComponent } from './components/modal/serialsmodal/serialsmodal.component';


var fingerprint = getBrowserFingerprint({
  hardwareOnly: true,
  enableWebgl: true
});

export function createConfig(): SignalRConfiguration {
  let config = new SignalRConfiguration();
  config.url = environment.API_URL;//environment.API_URL;
  config.hubName = "chathub";
  // config.qs = { type: '' };
  config.qs = { userId: fingerprint };
  config.logging = true;
  config.executeErrorsInZone = true;
  config.executeEventsInZone = true;
  config.executeStatusChangeInZone = true;
  return config;
}

@NgModule({
  declarations: [AppComponent, TicketCopyComponent, TechCopyComponent, ImportComponent, ComposeemailComponent, QuickaddproductComponent, PaymentComponent, CreditComponent, MilestoneComponent, CustomerannouncementsComponent, GenericprintComponent, SafePipe, MasterdataComponent, ReceivepoComponent, ReceiveserilizeditemsComponent, ShortshipmentpoComponent, PocostingComponent, ProductviewComponent, TrackingComponent, SerialComponent, PrintlabelComponent, MakepaymentComponent, DocumentsComponent, VendorpaymentComponent, ItemcodelistComponent, ArstatementsComponent, ApstatementComponent, ShippingportComponent, DSCcodeComponent, QbonlinemodalComponent, GenericintegrationComponent, AssetassignmentComponent, AssetservicelogComponent, PurchaseinvoicepaymentComponent,
    PurchaserequestitemanaylsisComponent, PurchaserequestitemhistoryComponent, ReportgenericprintComponent, ReviewtransactionsComponent, ReviewTransactionsActionColumn, LedgersComponent, ProductcatalogComponent, AssemblycatalogComponent, CustomersiteComponent, AddmarkerModalComponent, CustomerlistComponent, SalesrequestComponent, PurchaserequestComponent, ServiceitemComponent, ModalContactsComponent, DeviceComponent, SiteComponent, ScheduleComponent, SitesmodalComponent, EditassessmentComponent, QuotemodalComponent, PickuporderComponent, Productcatlog4purchaseComponent, CustomerContactsModalComponent, SupplierContactsModalComponent, SuppliercontactComponent, AddcardComponent, urlSafePipe, SinglebillpaymentComponent, SinglereceivepaymentComponent, AuditComponent, ChequetrackingComponent, ReceivedchequetrackingComponent, ProjjoblistpomodalComponent, DepartmentmodalComponent, SerialsmodalComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SignalRModule.forRoot(createConfig), //Uncomment for singalr working
    DashboardModule,
    AuthModule,
    HomepageModule,
    SharedModule,
    ProductsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxUiLoaderModule,
    [SweetAlert2Module.forRoot()],
    ToastrModule.forRoot(),
    FileUploadModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    PosModule,
    TrackingModule,
    NgxPrintModule,
    NgxPrintElementModule,
    NgxFileDropModule,
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    AutocompleteLibModule,
    CKEditorModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgxCaptureModule,
    Ng2SmartTableModule,
    AngularMultiSelectModule,
    BarRatingModule,
    ResizeModule,
    CustomerListModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAuoQSNu8Ct-jXbHFdjGLyhYy5Y_mNm3Y4',
      libraries: ['places']
    }),
    AgmDirectionModule,
    NgSelectModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe,
    ExcelService,
    ChatSignalr,
    Events,
    BnNgIdleService,
    ConversionService,
    AppComponent,
  ],
  exports: [ResizeBorderComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
