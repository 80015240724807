<div class="row">
  <div class="col-sm-12">
    <!-- <div class="row">
			<div class="col-lg-9 col-md-9 col-12 form-group"></div>
			<div class="col-lg-3 col-md-3 col-12 form-group">
				<input type="search" class="form-control placehder" name="" placeholder="&#xF002; Search">
			</div>
		</div> -->
    <div class="clearfix"></div>
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="Add Alias"
                [class.disabled]="fieldsetDisabled" data-target="#exampleModal" (click)="open(content,'','')"><i
                  class="fa fa-plus"></i>
              </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Alias <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)" style="width: 8%;">
              Active <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              CreateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              CreateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              UpdateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              UpdateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of aliasList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="open(content,item,'view')">View</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="open(content,item,'edit')">Edit</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" (click)="delete(item.id)">Delete</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" (click)="print(item)">Print Barcode</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.alias}}</td>
            <td class="custom-td text-center"><span class="badge" [title]="item.active">{{item.active}}</span> </td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.createdate |
							date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.updatedate |
							date:userdata.displaydatetimeformat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Alias</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="from" #f="ngForm">
      <div class="form row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Alias</label>
          <input class="form-control" id="" type="text" name="alais" [(ngModel)]="aliasData.alias" #alias="ngModel"
            [ngClass]="{ 'is-invalid': alias.invalid && alias.errors , 'is-valid': alias.valid}" required>
          <div *ngIf="alias.invalid && alias.errors" class="invalid-feedback d-block">
            <div *ngIf="alias.errors.required">
              Please enter alias
            </div>
          </div>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="" class="mb-1">Active :</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="edo-ani">
              <input class="radio_animated" [value]="true" id="edo-ani" type="radio" name="rdo-ani"
                [(ngModel)]="aliasData.active">
              Yes
            </label>
            <label class="d-block" for="edo-ani1">
              <input class="radio_animated" [value]="false" id="edo-ani1" type="radio" name="rdo-ani"
                [(ngModel)]="aliasData.active">
              No
            </label>
          </div>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView" (click)="postAlias()">Save</button>
  </div>
  </resize-border>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>
