<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">{{tracking.title}}
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label for="">Ref# {{tracking.refno}}</label>
            </div>
            <div class="form-group">
                <label for="">Current Status</label>
                <select class="form-control" id="status" name="status" [(ngModel)]="tracking.status">
                    <option value="">Select Status</option>
                    <option *ngFor="let item of statusList" [value]="item.stringValue">
                        {{item.Text}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Notes</label>
                <textarea name="notes" rows="2" [(ngModel)]="tracking.notes" class="form-control"></textarea>
            </div>
            <div class="form-group">
                <label for="">Action/Tag</label>
                <mat-form-field class="ezybooksChipList" appearance="fill">
                    <mat-chip-list #tagList aria-label="Tag selection">
                        <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                            {{tag}}
                            <button matChipRemove>
                                <mat-icon>
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </mat-icon>
                            </button>
                        </mat-chip>
                        <input placeholder="Select or add new tag" #tagInput [formControl]="tagCtrl"
                            [matAutocomplete]="auto" [matChipInputFor]="tagList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnFocusOut"
                            (matChipInputTokenEnd)="addTag($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                            {{tag}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
            <button class="btn btn-primary" [disabled]="disableSaveBtn" (click)="postTrackingNotes()"
                type="button">Save</button>
        </div>
        <div class="category-table custom-datatable mb-3 col-lg-12 potrackingTable">
            <div class="table-responsive2">
                <table class="custom-table">
                    <thead>
                        <tr class="custom-tr">
                            <!-- <th class="custom-th text-center">Action</th> -->
                            <th class="custom-th text-center" style="min-width: 260px;">Notes</th>
                            <th class="custom-th text-center">Tags</th>
                            <th class="custom-th text-center">Status</th>
                            <th class="custom-th text-center">Create By</th>
                            <th class="custom-th text-center">Create Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="custom-tr" *ngFor="let item of trackingNotes">
                            <!-- <td class="custom-td text-center">
                        <div class="ActionBtn">
                            <a class="hoverBtn">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                    </td> -->
                            <td class="custom-td">{{item.notes}}</td>
                            <td class="custom-td text-center"> <span class="badge"
                                    title="{{item.tags}}">{{item.tags}}</span>
                            </td>
                            <td class="custom-td text-center text-noWrap"><span class="badge"
                                    title="{{item.status}}">{{item.status}}</span></td>
                            <td class="custom-td text-center text-noWrap">{{item.createby}}</td>
                            <td class="custom-td text-center text-noWrap">{{item.createdate | date:format}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</resize-border>