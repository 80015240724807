import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adduom',
  templateUrl: './adduom.component.html',
  styleUrls: ['./adduom.component.scss']
})
export class AdduomComponent implements OnInit {
  @Input('uom') uom;
  itemList: any;
  userdata: any;
  appname = environment.APP_NAME;
  master: any = {
    id: null,
    description: '',
    createby: null,
    createdate: null,
    updateby: null,
    updatedate: null,
    active: true
  }
  constructor(private modalService: NgbModal, private cdRef: ChangeDetectorRef, private toastr: NotificationService, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService, private purchaseservice: purchaseServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getProductUOMIndexValues()
  }

  ngOnInit(): void {
  }
  getProductUOMIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getProductUOMIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  editMasterData(item) {
    this.master = item;
  }
  deleteProductUOM(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteProductUOM(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.getProductUOMIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting document type');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  postProductUOM() {
    if (this.master.id == undefined || this.master.id == null) {
      this.master.companyid = this.userdata.companyid;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.master.createby = this.userdata.email;
    } else {
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.master.updateby = this.userdata.email;
    }
    this.loader.start();
    this.service.postProductUOM(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.master.description = ''
        this.master.qty = ''
        this.getProductUOMIndexValues();
      } else {
        this.toastr.Error('Record not saved or updated');
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post document type');
    })
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
