import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, NgForm, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { AppServices } from 'src/app/shared/service/app.services';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../inventory.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { COMMA, E, ENTER, I } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MasterdataComponent } from 'src/app/components/modal/masterdata/masterdata.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemcodelistComponent } from 'src/app/components/modal/itemcodelist/itemcodelist.component';
import * as moment from 'moment';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { AdduomComponent } from '../modal/adduom/adduom.component';
import { ToastrService } from 'ngx-toastr';
import { TrackingComponent } from 'src/app/components/modal/tracking/tracking.component';
import { FileUploader } from 'ng2-file-upload';


@Component({
	selector: 'app-add-product',
	templateUrl: './add-product.component.html',
	styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
	@ViewChild('clearDateField') clearDateField: ElementRef;
	@ViewChild('clearDateField1') clearDateField1: ElementRef;
	@ViewChild('glaccount') glaccount;
	@ViewChild('supplier') supplier;
	isCollapsed = true;
	isCollapsed2 = true;
	isCollapsed3 = true;
	public counter: number = 1;
	glAccountList: any = [];
	glCostofSalesSettings: any = {};
	glInventoryStockSettings: any = {};
	glIncomeAccountSettings: any = {};
	coa_inventorystock: any;
	coa_costofsales: any;
	coa_income: any;
	addOnFocusOut = true;

	public url = [{
		img: "assets/images/user.png",
	},
	{
		img: "assets/images/user.png",
	},
	{
		img: "assets/images/user.png",
	},
	{
		img: "assets/images/user.png",
	},
	{
		img: "assets/images/user.png",
	}
	]
	public product: any = {
		id: null,
		ptype: 'inventory',
		flatpp: false,
		sale_online: false,
		auth_onsale: false,
		manualstockaudit: false,
		showinmobileapp: false,
		donotsell: false,
		donotorder: false,
		notdiscountable: false,
		lot_tracking: false,
		isserilized: false,
		returnable: false,
		coa_costofsales: '',
		size: '0',
		weight: '0',
		length: '0',
		width: '0',
		height: '0',
		salespricepercentageminimum: '0',
		reorderpoint: '0',
		reorderqty: '0',
		minqty: '0',
		maxqty: '0',
		warranty_period: '0',
		pocost: 0,
		landedcost: '0',
		avgcost: '0',
		salepricepercentage: '0',
		commissionpercentage: '0',
		store: '0',
		storemin: '0',
		service: '0',
		wholesale: '0',
		online: '0',
		offerprice: '0',
		flatprice: '0',
		qtyinpack: '1',
		discount: '0',
		wastage_per: '0',
		days_to_restock: '0',
		dept: '',
		cat: '',
		subcat: '',
		status: 'active',
		parentid: '',
		coa_income: '',
		warranty_in: '',
		coa_inventorystock: '',
		uom: '',
		pcurrency: '',
		puom: '',
		discount_type: '',
		description: '',
		color: 'black',
		tpcode: '',
		tpcodeextra: '',
		abc_type: '',
		pos_description: '',
		landedat: 0,
	}
	appname = environment.APP_NAME;
	userdata: any;
	deptList: any = [];
	categoryList: any = []
	subCategoryList: any = []
	productType: any = [];
	productSellUOMList: any = [];
	supplierList: any = [];
	currencyList: any = [];
	manifractureList: any = [];
	statusList: any = [];
	productData: any = {}
	discountTypeList: any = [];
	editProductData: any = {};
	accountGroupingList: any = []
	warrantyList: any = [];
	paraentMainList: any = [];
	isEdit: boolean = false;
	productid: any;
	lastaction: string;
	accounts: any = [];
	glList: any = [];
	lastPage: string;
	form: NgForm;
	fieldsetDisabled = false;
	tagName: any;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	tagCtrl = new FormControl('');
	filteredTags: Observable<string[]>;
	tags: any = [];
	allTags: any = [];
	totalLeaves: any;
	keyword: any = 'Text';
	header: any = 'Dropdown';
	@ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
	buttonaction: any
	newproductSellUOMList: any = [];
	tracking: any = []
	uploader: FileUploader;
	response: any;
	filename: any;
	DOC_URL: any;
	msg: string;
	url1: any
	constructor(private fb: FormBuilder,
		private toastr: NotificationService,
		private route: ActivatedRoute,
		private toaster: ToastrService, private modalService: NgbModal, private router: Router, private loader: NgxUiLoaderService, private service: InventoryServices, private datepipe: DatePipe, private commonService: CommonServices, private purchaseService: purchaseServices) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		environment.production ? '' : console.log('data.......', this.productid);
		this.DOC_URL = environment.DOC_URL;
		var decodeId = this.route.snapshot.queryParamMap.get('id');
		this.lastaction = this.route.snapshot.queryParamMap.get('action');
		if (this.lastaction == 'view') {
			this.fieldsetDisabled = true;
			this.buttonaction = 'view';
		} else if (this.lastaction == 'edit') {
			this.fieldsetDisabled = false;
		} else {
			this.fieldsetDisabled = false;
		}

		//came from edit project snag tab
		this.lastPage = this.route.snapshot.queryParamMap.get('lastPage');
		if (this.lastPage == 'supplier') {
			this.product.supplierid = atob(this.route.snapshot.queryParamMap.get('supplierid'));
		}
		if (decodeId) {
			this.productid = atob(decodeId);   // decode a string  
			this.isEdit = true;
			this.getItemDetails(this.productid);
			this.getProductTrackingNotes()
		}
		// this.product.ptype = this.route.snapshot.queryParamMap.get('ptype');


		this.commonService.getComboEnums('prodtype').subscribe(res => {
			this.productType = res;
			environment.production ? '' : console.log('product type  ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading product type data', err);
		});

		this.service.getProductSupplier().subscribe(res => {
			this.supplierList = res;
			environment.production ? '' : console.log('supplierList type  ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading supplierList data', err);
		});

		this.commonService.getCurrency().subscribe(res => {
			this.currencyList = res;
			environment.production ? '' : console.log('currencyList  ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading currencyList data', err);
		});

		this.commonService.getComboEnums('prodstatus').subscribe(res => {
			this.statusList = res;
			environment.production ? '' : console.log('statusList  ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading statusList data', err);
		});

		this.commonService.getComboEnums('discounttype').subscribe(res => {
			this.discountTypeList = res;
			environment.production ? '' : console.log('discountTypeList   ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading discountTypeList data', err);
		});

		this.service.getGLAccount().subscribe(res => {
			this.accounts = res;
			// this.dropdownList.forEach(element => {
			// 	element.Value = Number(element.Value)
			// })
			this.glAccountList = [];
			this.accounts.forEach(element => {
				this.glAccountList.push({
					"id": Number(element.Value),
					"itemName": element.Text,
					"category": element.Value1,
				})
			});
			environment.production ? '' : console.log('Drop down list', this.glAccountList);
			this.accountGroupingList = Array.from(new Set(this.accounts.map(({ Value1 }) => Value1)));
			environment.production ? '' : console.log('list', this.accountGroupingList);
			environment.production ? '' : console.log('accounts', res)
			this.setGLAccounts();
		})
		this.glIncomeAccountSettings = {
			disabled: false,
			itemDisabled: false,
			singleSelection: true,
			text: "Select GL Income",
			groupBy: 'category',
			enableSearchFilter: true,
			searchPlaceholderText: 'Search GL Income'
		};

		this.glCostofSalesSettings = {
			disabled: false,
			itemDisabled: false,
			singleSelection: true,
			text: "Select GL Cost Sales",
			groupBy: 'category',
			enableSearchFilter: true,
			searchPlaceholderText: 'Search GL Cost Sales'
		};

		this.glInventoryStockSettings = {
			disabled: false,
			itemDisabled: false,
			singleSelection: true,
			text: "Select GL Inventory Stock",
			groupBy: 'category',
			enableSearchFilter: true,
			searchPlaceholderText: 'Search GL Inventory Stock'
		};

		this.commonService.getComboEnums('warrentyin').subscribe(res => {
			this.warrantyList = res;
			environment.production ? '' : console.log('warrantyList type  ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading warrantyList data', err);
		})
		// this.service.getParaentMain('').subscribe(res => {
		// 	this.paraentMainList = res;
		// 	environment.production ? '' : console.log('parentMainList type  ', res);
		// }, err => {
		// 	environment.production ? '' : console.log('Error : While loading parentMainList data', err);
		// })
		this.getManufacturerData();
		this.getProdUom();
		this.getProdSellUom();
		this.getDeptData();
		this.initializeFileUploader();
	}
	getProdUom() {
		this.product.puom = ''
		this.purchaseService.getProdUom().subscribe(res => {
			this.productSellUOMList = res;
			// if (this.productSellUOMList.length != this.newproductSellUOMList.length) {
			// 	this.newproductSellUOMList = res
			// }

			environment.production ? '' : console.log(' prod uom list data ', this.productSellUOMList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading prod uom list ', err);
		});
		this.product.uom = this.product.uom
	}
	getProdSellUom() {
		this.product.uom = ''
		this.purchaseService.getProdUom().subscribe(res => {
			this.newproductSellUOMList = res;

			environment.production ? '' : console.log(' prod uom list data ', this.productSellUOMList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading prod uom list ', err);
		});
		this.product.uom = this.product.uom
	}
	getManufacturerData() {
		this.service.getManifracturList().subscribe(res => {
			this.manifractureList = res;
		}, err => {
		});
	}

	collapseshow() {
		this.isCollapsed = !this.isCollapsed;
	}
	collapseshow2() {
		this.isCollapsed2 = !this.isCollapsed2;
	}
	collapseshow3() {
		this.isCollapsed3 = !this.isCollapsed3;
	}

	getDeptData() {
		this.commonService.getDepartmentList(false).subscribe(res => {
			this.deptList = res;
			environment.production ? '' : console.log('department data ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading department data', err);
		});
		// this.getCatData('')
	}

	getCatData(deptid) {
		// this.product.cat = ''
		// this.product.subcat = ''

		this.loader.start();
		this.commonService.getCategory(Number(deptid), false).subscribe(res => {
			this.categoryList = res;
			this.loader.stop();
			environment.production ? '' : console.log('Category data ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading category data', err);
		});
	}

	getTags() {
		this.commonService.getTags('product').subscribe((res: any) => {
			if (res != null) {
				this.allTags = [];
				res.forEach(element => {
					this.allTags.push(element.Value);
				})
			}
			this.filteredTags = this.tagCtrl.valueChanges.pipe(
				startWith(null),
				map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice())),
			);
			environment.production ? '' : console.log('tags data ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading tags data', err);
		});
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		// Add our tag
		if (value) {
			var isExists = false;
			if (this.tags.length > 0) {
				this.tags.forEach(element => {
					if (element == value) {
						isExists = true;
					}
				})
			}
			if (!isExists) {
				this.tags.push(value);
			}
		}
		var isExists = false;
		this.allTags.forEach(element => {
			if (element == value) {
				isExists = true;
			}
		})
		if (!isExists) {
			var postTagObj = {
				"description": value,
				"active": true,
				"createby": this.userdata.email,
				"createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
				"companyid": this.userdata.companyid,
				"color": "",
				"pg": "product",
			}
			this.loader.start();
			this.commonService.postTags(postTagObj).subscribe((res: any) => {
				this.loader.stop();
				if (res != null) {
					if (res.status_code == "1") {
						res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
					} else {
						res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
						return;
					}
					this.getTags();
				}
			}, err => {
				this.loader.stop();
			});
		}

		event.chipInput!.clear();
		this.tagCtrl.setValue(null);
	}

	setGLAccounts() {
		if (this.accounts.length == 0) {
			return;
		}
		this.accounts.forEach(element => {
			if (this.product.id) {
				if (element.Value == this.product.coa_income) {
					this.glIncomeAccountSettings = {
						disabled: false,
						itemDisabled: false,
						singleSelection: true,
						text: element.Text,
						groupBy: 'category',
						enableSearchFilter: true,
						searchPlaceholderText: ''
					}
				}
				if (element.Value == this.product.coa_costofsales) {
					this.glCostofSalesSettings = {
						disabled: false,
						itemDisabled: false,
						singleSelection: true,
						text: element.Text,
						groupBy: 'category',
						enableSearchFilter: true,
						searchPlaceholderText: ''
					}
				}
				if (element.Value == this.product.coa_inventorystock) {
					this.glInventoryStockSettings = {
						disabled: false,
						itemDisabled: false,
						singleSelection: true,
						text: element.Text,
						groupBy: 'category',
						enableSearchFilter: true,
						searchPlaceholderText: ''
					}
				}
			}
		});
	}
	remove(tag: string): void {
		const index = this.tags.indexOf(tag);

		if (index >= 0) {
			this.tags.splice(index, 1);
		}
	}

	selectAccount(item) {
		environment.production ? '' : console.log('selected customer', item);
	}

	onFocused($event) {
		this.glaccount.clear();
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.tags.push(event.option.viewValue);
		this.tagInput.nativeElement.value = '';
		this.tagCtrl.setValue(null);
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
	}

	getSubCatdata(cat) {
		// var tarrifData = this.categoryList.find(element => element.Value === cat);
		// this.product.commoditycode = tarrifData ? tarrifData.Value1 : null;

		this.loader.start();
		this.commonService.getSubCategory(cat).subscribe(res => {
			this.loader.stop();
			this.subCategoryList = res;
			environment.production ? '' : console.log('sub category data ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading Sub category data', err);
		});
	}

	increment() {
		this.counter += 1;
	}

	decrement() {
		this.counter -= 1;
	}

	//FileUpload
	readUrl(event: any, i) {
		if (event.target.files.length === 0)
			return;
		//Image upload validation
		var mimeType = event.target.files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}
		// Image upload
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			this.url[i].img = reader.result.toString();
		}
	}

	tabClick(event) {
		if (this.lastaction == 'view') {
			this.buttonaction = 'view';
		}
		else {
			if (event.nextId !== '0') {
				this.buttonaction = 'view';
			}
			if (event.nextId == '0') {
				this.buttonaction = 'edit';
			}
		}
	}

	ngOnInit() {

	}
	selectPUOM() {
		this.productSellUOMList.forEach(item => {
			if (item.Value == this.product.puom) {
				this.product.qtyinpack = item.Value2 ? Number(item.Value2).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

			}
		})
	}
	onItemSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_income = item.id;
		environment.production ? '' : console.log(this.product.coa_income);
	}

	OnItemDeSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_income = null;
		environment.production ? '' : console.log(this.product.coa_income);
	}

	onCosSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_costofsales = item.id;
		environment.production ? '' : console.log(this.product.coa_costofsales);
	}

	OnCosDeSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_costofsales = null;
		environment.production ? '' : console.log(this.product.coa_costofsales);
	}

	onInvSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_inventorystock = item.id;
		environment.production ? '' : console.log(this.product.coa_inventorystock);
	}

	onInvDeSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_inventorystock = null;
		environment.production ? '' : console.log(this.product.coa_inventorystock);
	}

	getItemDetails(id) {
		setTimeout(() => {
			this.service.getInventoryItemsDetials(id).subscribe(res => {
				this.product = res;
				// this.product.manuafacturer = this.product.manuafacturer ? Number(this.product.manuafacturer) : '';
				this.product.supplierid = this.product.supplierid ? this.product.supplierid.toString() : '';
				environment.production ? '' : console.log('items details ', res);
				if (this.lastaction == 'add') {
					this.commonService.headerEditItemData.next(''); //end 
					this.product.ilc = '';
					this.product.ailc = '';
					this.product.milc = '';
					this.product.pocost = 0;
					this.product.landedcost = 0;
					this.product.store = 0;
					this.product.reorderpoint = 0;
					this.product.reorderqty = 0;
					this.product.maxqty = 0;
					this.product.minqty = 0;
					this.product.avgcost = 0;
					this.product.specification = '';
					this.product.qtyonhand = 0;
					this.product.qtyonhold = 0;
					this.product.qtyonorder = 0;
					this.product.qtyavailable = 0;
					this.product.nos = 0;
					this.product.pic = '';
					this.product.uid = '';
					this.isEdit = false
				} else {
					var editDataForHeader = this.product.ilc;
					this.commonService.headerEditItemData.next(editDataForHeader); //end 

					this.product.pocost = this.product.pocost ? Number(this.product.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.landedcost = this.product.landedcost ? Number(this.product.landedcost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.store = this.product.store ? Number(this.product.store).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.avgcost = this.product.avgcost ? Number(this.product.avgcost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.reorderpoint = this.product.reorderpoint ? Number(this.product.reorderpoint).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.reorderqty = this.product.reorderqty ? Number(this.product.reorderqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.maxqty = this.product.maxqty ? Number(this.product.maxqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.minqty = this.product.minqty ? Number(this.product.minqty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.qtyonhand = this.product.qtyonhand ? Number(this.product.qtyonhand).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.qtyonhold = this.product.qtyonhold ? Number(this.product.qtyonhold).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.qtyonorder = this.product.qtyonorder ? Number(this.product.qtyonorder).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.qtyavailable = this.product.qtyavailable ? Number(this.product.qtyavailable).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.nos = this.product.nos ? Number(this.product.nos).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.service = this.product.service ? Number(this.product.service).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.wholesale = this.product.wholesale ? Number(this.product.wholesale).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.online = this.product.online ? Number(this.product.online).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.offerprice = this.product.offerprice ? Number(this.product.offerprice).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.flatprice = this.product.flatprice ? Number(this.product.flatprice).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.landedat = this.product.landedat ? Number(this.product.landedat).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.qtyinpack = this.product.qtyinpack ? Number(this.product.qtyinpack).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
					this.product.salespricepercentageminimum = this.product.salespricepercentageminimum ? Number(this.product.salespricepercentageminimum).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
				}

				// localStorage.setItem(this.appname + 'editproduct', JSON.stringify(this.product));
				this.service.productDetails = this.product;
				if (this.product.pic !== null) {
					this.filename = this.product.pic;
					this.product.pic = environment.DOC_URL + '/' + this.userdata.companyid + '/' + this.product.pic
				}
				if (this.product.dept) {
					this.getCatData(this.product.dept)
				} else {
					this.product.dept = ''
				}
				if (this.product.tags !== null) {
					this.tags = this.product.tags.split(',');
				}

				if (this.product.subcat) {
					this.getSubCatdata(this.product.cat)
				} else {
					this.product.subcat = ''

				}
				if (this.product.parentid == null) { this.product.parentid = '' }
				if (this.product.ptype == null) { this.product.ptype = '' }
				if (this.product.status == null) { this.product.status = '' }
				if (this.product.coa_income == null) { this.product.coa_income = null }
				if (this.product.warranty_in == null) { this.product.warranty_in = '' }
				if (this.product.coa_inventorystock == null) { this.product.coa_inventorystock = '' }
				if (this.product.uom == null) { this.product.uom = '' }
				if (this.product.manifracture == null) { this.product.manifracture = '' }
				if (this.product.supplierid == null) { this.product.supplierid = '' }
				if (this.product.pcurrency == null) { this.product.pcurrency = '' }
				if (this.product.puom == null) { this.product.puom = '' }
				if (this.product.discount_type == null) { this.product.discount_type = '' }

				environment.production ? '' : console.log('items details ', res);
				this.setGLAccounts();
			}, err => {
				environment.production ? '' : console.log('Error : While loading items details data', err);
			})
		}, 500)

	}

	get f(): { [key: string]: AbstractControl } {
		return this.product.controls;
	}
	//open Master Modal code
	openUOMModal() {
		var modalRef = null;
		modalRef = this.modalService.open(AdduomComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
		modalRef.componentInstance.uom = 'uom';
		modalRef.result.then((result) => {
			this.getProdUom();

			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log('umo selected ngmodel data', this.product.uom);
		});
	}
	openSellUOMModal() {
		var modalRef = null;
		modalRef = this.modalService.open(AdduomComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
		modalRef.componentInstance.uom = 'uom';
		modalRef.result.then((result) => {
			this.getProdSellUom();

			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log('umo selected ngmodel data', this.product.uom);
		});
	}
	openMasterModal() {
		var modalRef = null;
		modalRef = this.modalService.open(MasterdataComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
		modalRef.componentInstance.manufacturer = 'manufacturer';
		modalRef.result.then((result) => {
			this.getManufacturerData();
			environment.production ? '' : console.log(result);
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});
	}
	addProduct(action) {
		if (this.product.ptype == 'service' && this.product.qtyinpack == 0 || this.product.qtyinpack < 0 || this.product.qtyinpack == undefined) {
			this.toaster.error('Qty in pack should be greater than zero');
			return false;
		}
		var t_prodbinsList = [];
		t_prodbinsList.push({
			prodid: 0,
			active: true,
			companyid: this.userdata.companyid,
			cnt: 0,
			binid: this.userdata.default_inventory_bin_location,
			onhold: 0,
			sold: 0,
			minqty: 0,
			maxqty: 0,
			availableqty: 0,
			notes: 0,
			createby: this.userdata.email,
			createdate: new Date()
		})
		var tags = null;
		if (this.tags.length > 0) {
			this.tags.forEach((t, i) => {
				if (tags == undefined || tags == null) {
					tags = t;
				} else {
					tags += ',' + t;
				}
			});
		}
		this.product.tags = tags;
		if (this.product.cat) {
			this.product.cat = parseFloat(this.product.cat);
		}
		if (this.product.subcat) {
			this.product.subcat = parseFloat(this.product.subcat);
		}
		if (this.product.dept) {
			this.product.dept = parseFloat(this.product.dept);
		}
		if (this.product.supplierid) {
			this.product.supplierid = parseFloat(this.product.supplierid);
		}
		if (this.product.parentid) {
			this.product.parentid = parseFloat(this.product.parentid);
		}
		if (this.product.coa_income) {
			this.product.coa_income = parseFloat(this.product.coa_income);
		}
		if (this.lastaction != 'add') {
			this.product.updateby = this.userdata.email;
			this.product.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		} else {
			this.product.id = null;
			this.product.companyid = this.userdata.companyid;
			this.product.createby = this.userdata.email;
			this.product.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
			this.product.t_prodpictures = this.filename ? [{ "filename": this.filename ? this.filename : '', "mainpic": true, "companyid": this.userdata.companyid, "active": true, "createby": this.userdata.email, "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat) }] : []
		}
		this.product.salestartdate = this.product.salestartdate ? this.datepipe.transform(new Date(this.product.salestartdate), this.userdata.postdateformat) : null;
		this.product.mfgdate = this.product.mfgdate ? this.datepipe.transform(new Date(this.product.mfgdate), this.userdata.postdateformat) : null;
		this.product.pckgdate = this.product.pckgdate ? this.datepipe.transform(new Date(this.product.pckgdate), this.userdata.postdateformat) : null;
		this.product.offerstartdate = this.product.offerstartdate ? this.datepipe.transform(new Date(this.product.offerstartdate), this.userdata.postdateformat) : null;
		this.product.offerenddate = this.product.offerenddate ? this.datepipe.transform(new Date(this.product.offerenddate), this.userdata.postdateformat) : null;
		this.loader.start();
		this.product.pic = this.filename ? this.filename : this.product.pic;
		this.service.postProduct(this.product).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				if (action == 'saveclose') {
					this.product = {};
					this.router.navigate(['/products']);
				} else {
					this.product = {};
					this.productid = res.refid;
					if (action == 'saveonly') {
						this.lastaction = 'edit';
						this.isEdit = true;
					}
					this.getItemDetails(Number(this.productid))
				}
			}
			environment.production ? '' : console.log('post product response ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading post product ', err);
		});

	}
	clicked(action) {
		if (action == 'itemcodelist') {
			var modalRef = null;
			modalRef = this.modalService.open(ItemcodelistComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
			modalRef.result.then((result) => {
				if (result.code != null) {
					this.product.commoditycode = result.code.code;
					// this.item.rate = result.code.rate;
					// this.item.Description = result.code.description;
				}
				environment.production ? '' : console.log(result);
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
	}
	updateCalcs() {
		var a = moment(this.product.offerstartdate, "YYYY-MM-DD");
		var b = moment(this.product.offerenddate, "YYYY-MM-DD");
		this.totalLeaves = moment.duration(b.diff(a)).asDays();
		if (this.totalLeaves) {
			this.checkDate();
		}
	}
	checkDate() {
		var startDate = this.datepipe.transform(this.product.offerstartdate, ('dd/MM/yyyy'));
		var endDate = this.datepipe.transform(this.product.offerenddate, ('dd/MM/yyyy'));
		var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
		if (parseInt(endDate.replace(regExp, "$3$2$1")) > parseInt(startDate.replace(regExp, "$3$2$1"))) {
			environment.production ? '' : console.log('start date is greater than end date');
		} else {
			this.product.offerenddate = null
			this.clearDateField.nativeElement.value = '';
			this.totalLeaves = 0;
			this.toastr.Error("End date should be greater than start date")
			return false
		}
	}
	updateCalcs1() {
		var a = moment(this.product.mfgdate, "YYYY-MM-DD");
		var b = moment(this.product.pckgdate, "YYYY-MM-DD");
		this.totalLeaves = moment.duration(b.diff(a)).asDays();
		if (this.totalLeaves) {
			this.checkDate1()

		}

	}
	checkDate1() {
		var startDate1 = this.datepipe.transform(this.product.mfgdate, ('dd/MM/yyyy'));
		var endDate1 = this.datepipe.transform(this.product.pckgdate, ('dd/MM/yyyy'));
		var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
		if (parseInt(endDate1.replace(regExp, "$3$2$1")) > parseInt(startDate1.replace(regExp, "$3$2$1"))) {
			environment.production ? '' : console.log('start date is greater than end date');
		} else {
			this.product.pckgdate = null
			this.clearDateField1.nativeElement.value = '';

			this.totalLeaves = 0;
			this.toastr.Error("End date should be greater than start date")
			return false
		}
	}
	validateIlc() {
		this.loader.start();
		this.commonService.validateIlc(this.product.ilc).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					if (this.lastaction == 'add') {
						this.product.ilc = '';
					}
					return;
				}
			}
		}, err => {
			environment.production ? '' : console.log('Error : While loading discountTypeList data', err);
		});

	}
	getProductTrackingNotes() {
		this.loader.start();
		this.commonService.getTrackingNotes(this.productid, 'product').subscribe(res => {
			this.loader.stop();
			this.tracking = res;
			environment.production ? '' : console.log('tracking notes data ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading tracking notes data ', err);
		});
	}
	addTrackingNotes() {
		var modalRef = this.modalService.open(TrackingComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' });
		modalRef.componentInstance.params = { id: this.product.id, type: 'product', refno: this.product.ilc, status: this.product.status };
		modalRef.result.then((result) => {
			environment.production ? '' : console.log(result);
			this.getProductTrackingNotes();
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});
	}
	initializeFileUploader() {
		this.uploader = new FileUploader({
			authTokenHeader: 'authorization',
			authToken: 'Bearer ' + this.userdata.token,
			url: environment.API_URL + 'api/Documents/UploadProductPictures',
			method: 'POST',
			removeAfterUpload: true,
			queueLimit: 100
		})
		this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
			form.append('companyid', this.userdata.companyid);
			form.append('createby', this.userdata.email);
			form.append('refno', this.productData.id ? this.productData.id : null);
			form.append('dtype', 'item-files');
		};
		this.response = '';
		this.uploader.response.subscribe(res => {
			environment.production ? '' : console.log('res ' + res);
			this.response = res
		});
		this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
	}


	selectedFileOnChanged(event: any) {
		environment.production ? '' : console.log(event.target.value);
		// var filename = event.target.value.Replace('C:\'fakepath\','')
		this.filename = event.target.value.split(/(\\|\/)/g).pop()
		this.product.pic = this.filename;
		if (!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
			return;
		}
		var mimeType = event.target.files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.msg = "Only images are supported";
			return;
		}
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			this.msg = "";
			this.product.pic = reader.result;
		}
		this.uploadFile();
	}
	uploadFile() {
		this.uploader.queue[0].onSuccess = function (response, status, headers) {
			if (status == 200) {
				let tempRes = JSON.parse(response);
			} else {
				// this.toaster.Success('File uploaded');
				this.initializeFileUploader();
			}
		};
		this.uploader.queue[0].upload();
	}
}
