<resize-border  [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="assembliesModalLabel">
        Assemblies Catalog
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Search by name, description" aria-label="Search" aria-describedby="basic-addon2"
              [(ngModel)]="product" name="product">
            <div class="input-group-append cursor-pointer">
              <span class="input-group-text btn-primary" id="basic-addon2" (click)="searchAssemblies()">
                <span class="fa fa-search ">
                </span>
              </span>
            </div>
          </div>
          <!-- <div class="custom-control custom-checkbox">
            <label class="custom-control-label" for="customCheckDisabled">Check All</label>
          </div> -->
        </div>
      </form>
      <div>
        <!-- Need to add custom html code here -->
        <table class="table table-responsive customTable">
          <thead>
            <tr>
              <th style="width: 0%;">
                <input type="checkbox" [(ngModel)]="allRowChecked" (click)="assemblyAllItemCheck()" id="customCheckDisabled">
              </th>
              <th style="width: 2%;">No#</th>
              <th style="width: 10%;">Name</th>
              <th style="width: 10%;">Group Name</th>
              <th style="width: 2%;">Status</th>
              <th style="width: 5%;">QTY</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of assembliesList; let i = index;">
              <td>
                <input type="checkbox" [(ngModel)]="data.checked" [ngModelOptions]="{standalone: true}"
                  (click)="assemblyItemCheck(data)">
              </td>
              <td class="text-center"> {{data.assno}}</td>
              <td> {{data.name}}</td>
              <td></td>
              <td class="text-center">{{data.status}}</td>
              <td>
                <input type="text" class="form-control text-center" [(ngModel)]="data.qty" value="0"
                  [ngModelOptions]="{standalone: true}">
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End code here -->
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="assembliesSave()">Add to
        List</button>
    </div>
  </div>
</resize-border>