import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { serviceManagerServices } from '../../servicemanager/servicemanager.service';

@Component({
  selector: 'app-composeemail',
  templateUrl: './composeemail.component.html',
  styleUrls: ['./composeemail.component.scss']
})
export class ComposeemailComponent implements OnInit {
  fullScreen = false;
  @Input('flag') flag;
  @Input('id') id;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  ticketsBatchPrintList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  isShowCopanyLogo = false;
  compose: any = {
    "msg": "",
    "createby": null,
    "from": null,
    "sendername": null,
    "to": null,
    "cc": null,
    "bcc": null,
    "subject": "",
    "id": null,
    "template_code": false,
    "default_sender": false,
    "companyname": null,
    "logo": null,
    "currency": null,
    "contactno": null,
    "bankname": null,
    "bankaccname": null,
    "bankaccno": null,
    "bankbic": null,
    "duedate": null,
    "to_name": null,
    "amt": null,
    "refno": null,
    "companyid": null,
    "use_template": null,
    "attachtickettoinvoice": false,
    "multipleEmails": [
    ]
  }
  constructor(private modalService: NgbModal,
    private toastr: NotificationService,
    private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.companyLogo += this.userdata.logo;
  }

  ngOnInit(): void {
    this.getComposeEmail();
  }
  getComposeEmail() {
    this.loader.start()
    this.service.getComposeEmail(this.id, this.flag).subscribe(res => {
      this.loader.stop();
      if (res != null) {
        this.compose = res;
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading currency data', err);
    })
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  save() {
    if (this.compose.to_name == undefined || this.compose.to_name == null || this.compose.to_name == '') {
      this.toastr.Error('Please enter a recipient name')
      return;
    }
    if (this.compose.to == undefined || this.compose.to == null || this.compose.to == '') {
      this.toastr.Error('Please enter a recipient email')

      return;
    }
    if (this.compose.subject == undefined || this.compose.subject == null || this.compose.subject == '') {
      this.toastr.Error('Please enter subject')
      return;
    }
    this.compose.from_name = this.userdata.name;
    this.compose.createby = this.userdata.email;
    this.compose.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.compose.companyid = this.userdata.companyid;
    this.loader.start();
    this.service.postEmail(this.compose).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While post email', err);
    })
  }

}
