<div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Marker</h5>
    <button type="button" class="close" aria-label="Close" name="close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [style.pointer-events]="flag=='view'?'none':''">
    <form class="needs-validation">
      <div class="form row">
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="">Select Location</label>
          <ng-autocomplete #Location [data]="locationList" [searchKeyword]="searchLocation" [initialValue]="editSetLocation"
            placeholder="Search Location" (selected)='selectLocation($event)' [itemTemplate]="itemTemplate2"
            (inputCleared)="inputLocationCleared()" (inputFocused)='onFocusedLocation($event)'
            [notFoundTemplate]="notFoundTemplate" [disabled]="actionView">
          </ng-autocomplete>
          <ng-template #itemTemplate2 let-item>
            <a [innerHTML]="item.Text"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
        
        <div class="form-group col-md-12 col-lg-6 col-md-6 col-sm-12">
          <label for="">Parent Marker Type</label>
          <select class="form-control" required="" name="marker_type" [(ngModel)]="marker.marker_type"  (change)="getSubTypes()"  [disabled]="actionView">
            <option [value]="item.Value" *ngFor="let item of types">{{item.Text}}</option>
          </select>
        </div>
        <div class="form-group col-md-12 col-lg-6 col-md-6 col-sm-12">
            <label for="">Marker Type</label>
            <select class="form-control" required="" name="sub_marker_type" [(ngModel)]="marker.sub_marker_type"  [disabled]="actionView">
              <option [value]="item.Value" *ngFor="let item of subTypes">{{item.Text}}</option>
            </select>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="">Latitude </label>
            <input class="form-control" name="lat" [(ngModel)]="marker.lat" [disabled]="actionView">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="">Longitude </label>
            <input class="form-control" name="lng" [(ngModel)]="marker.lng"  [disabled]="actionView">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="">Description </label>
            <input class="form-control" name="description" [(ngModel)]="marker.description"  [disabled]="actionView">
          </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" name="close" data-dismiss="modal"
      (click)=" closeModal()">Close</button>
    <button type="button" class="btn btn-primary"  [disabled]="flag=='view'" name="save" (click)="saveMarker()">Save</button>
  </div>