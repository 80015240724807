<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="Add Tax"
                data-target="#exampleModal" (click)="addTax(content,null,null)" [class.disabled]="fieldsetDisabled"><i
                  class="fa fa-plus"></i> </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'uom')">Name<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'effective_from')">From Date<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center" (click)="onSortClick($event,'effective_to')">To Date<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'active')">Active<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'createby')">CreateBy<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'createdate')">CreateDate<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'updateby')">UpdateBy<i class="Sorting fa fa-chevron-up"></i> </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'updatedate')">UpdateDate<i class="Sorting fa fa-chevron-up"></i></th>

          </tr>
          <tr class="custom-tr" *ngFor="let item of taxList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem data-toggle="modal" [class.disabled]="fieldsetDisabled"
                      data-original-title="Edit" data-target="#exampleModal"
                      (click)="addTax(content,item,'view')">View</span></li>
                  <li><span ngbDropdownItem data-toggle="modal" [class.disabled]="fieldsetDisabled"
                      data-original-title="Edit" data-target="#exampleModal"
                      (click)="addTax(content,item,'edit')">Edit</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" (click)="deleteTax(item)">Delete</span>
                  </li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.name}}
            <td class="custom-td text-center text-nowrap">{{item.effective_from | date: format}}
            </td>
            <td class="custom-td text-center text-nowrap">{{item.effective_to | date: format}}</td>
            <td class="custom-td text-center"><span class="badge" title="{{item.active}}">{{item.active}}</span> </td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.createdate |
              date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-center text-nowrap">{{item.updatedate |
              date:userdata.displaydatetimeformat}}</td>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Tax</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" #f="ngForm">
      <div class="form-row">
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="" class="mb-1">From Date :</label>
          <mat-form-field [ngClass]="{ 'is-invalid': fromdate.invalid && fromdate.errors , 'is-valid': fromdate.valid}"
            [class.disabled]="fieldsetDisabled">
            <input matInput [matDatepicker]="picker80" placeholder="Choose a date" [(ngModel)]="taxData.effective_from"
              name="effective_from" (dateChange)="updateCalcs()" required #fromdate="ngModel">
            <mat-datepicker-toggle matSuffix [for]="picker80"></mat-datepicker-toggle>
            <mat-datepicker #picker80></mat-datepicker>
          </mat-form-field>
          <div *ngIf="fromdate.invalid && fromdate.errors" class="invalid-feedback d-block">
            <div *ngIf="fromdate.errors.required">
              From Date is required
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="" class="mb-1">To Date :</label>
          <mat-form-field [ngClass]="{ 'is-invalid': todate.invalid && todate.errors , 'is-valid': todate.valid}"
            [class.disabled]="fieldsetDisabled">
            <input #clearDateField matInput [matDatepicker]="picker81" placeholder="Choose a date"
              [(ngModel)]="taxData.effective_to" name="effective_to" (dateChange)="updateCalcs()" required
              #todate="ngModel">
            <mat-datepicker-toggle matSuffix [for]="picker81"></mat-datepicker-toggle>
            <mat-datepicker #picker81></mat-datepicker>
          </mat-form-field>
          <div *ngIf="todate.invalid && todate.errors" class="invalid-feedback d-block">
            <div *ngIf="todate.errors.required">
              To Date is required
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="">Tax</label>
          <div class="input-group ">
            <select class="form-control" [(ngModel)]="taxData.taxid" name="taxid">
              <option value="">Select tax</option>
              <option *ngFor="let item of taxtypeList" [value]="item.Value">{{item.Text}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
          <label for="" class="mb-1">Active</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="">
              <input class="radio_animated" [value]="true" id="" type="radio" name="rdo-ani"
                [(ngModel)]="taxData.active">
              Yes
            </label>
            <label class="d-block" for="">
              <input class="radio_animated" [value]="false" id="" type="radio" name="rdo-ani"
                [(ngModel)]="taxData.active">
              No
            </label>
          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!f.valid ||action=='view'"
      (click)="saveTax()">Save</button>
  </div>
</resize-border>
</ng-template>
