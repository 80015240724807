import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../inventory/inventory.service';
import { purchaseServices } from '../../purchase/purchase.service';

@Component({
  selector: 'app-receiveserilizeditems',
  templateUrl: './receiveserilizeditems.component.html',
  styleUrls: ['./receiveserilizeditems.component.scss']
})
export class ReceiveserilizeditemsComponent implements OnInit {
  fullScreen = false;
  @Input('item') item;
  @Input('po') po;
  @ViewChild('Location') Location;
  binLocationList: any;
  serialno: any;
  locationKeyword = 'Text';
  serialNoList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  notes: any;
  binid: any = '';
  constructor(
    private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private service: purchaseServices,
    private inventory: InventoryServices,
    private commonService: CommonServices,
    private toastr: NotificationService,
    private datepipe: DatePipe) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

  }

  getBINLocations() {
    this.commonService.getBinLocation().subscribe(res => {
      this.binLocationList = res;
      environment.production ? '' : console.log('binlocation data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading binlocation data', err);
    });
  }
  ngOnInit(): void {
    this.getBINLocations();
  }
  removeSrl(index) {
    this.serialNoList.splice(index, 1);
  }
  addSerial() {
    if (!this.serialno) {
      return;
    }
    if (this.serialNoList.length == (this.item.qty - this.item.receivedqty)) {
      this.toastr.Error('Serials count should not be greater than qty to be receive');
      return;
    }
    if (this.serialNoList.length > 0) {
      var isExists = false;
      this.serialNoList.forEach(srl => {
        if (srl == this.serialno) {
          this.toastr.Error('Duplicate serial# please check');
          isExists = true;
        }
      })
      if (!isExists) {
        this.serialNoList.push(this.serialno);
      }
    } else {
      this.serialNoList.push(this.serialno);
    }
    this.serialno = null;
  }

  postSerialNumber() {
    if (this.serialNoList.length == 0) {
      Swal.fire('', 'Please enter serial#', 'warning');
      return;
    }
    if (this.binid == '') {
      Swal.fire('', 'Please select bin', 'warning');
      return;
    }
    var serials = [];
    this.serialNoList.forEach(serial => {
      serials.push({
        serialno: serial
      })
    })
    var postObj = {
      "id": this.item.id,
      "poid": this.po.poid,
      "binid": this.binid,
      "pon": this.po.pon,
      "shippingfees": this.po.shippingfees,
      "adjustment": this.po.adjustment,
      "msg": this.notes,
      "status": "received",
      "companyid": this.userdata.companyid,
      "prodid": this.item.prodid,
      "ilc": this.item.ilc,
      "description": this.item.description,
      "qty": this.item.qty,
      "cost": this.item.cost,
      "pohdrid": this.item.pohdrid,
      "receivedqty": this.serialNoList.length,
      "serials": serials,
      "updateby": this.userdata.email,
      "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat)
    }
    this.loader.start();
    this.inventory.postReceiveSerilizedItems(postObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
      environment.production ? '' : console.log('serial post item data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading serial post item data ', err);
    });
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  selectlocation(event) {
    this.binid = event.Value;
  }

  onFocusedlocation(event) {
    this.Location.clear();
  }

}
