<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 8%;" class="custom-th text-center" (click)="onSortClick($event,'')"> Actions<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'id')">
              ID<i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'name')" style="width: 8%;">
              Number <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'bin_name')">
              Bin Location <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'cnt')">
              Count<i class="Sorting fa fa-chevron-up" ></i>
            </th>
            <th class="custom-th text-center"(click)="onSortClick($event,'sold')">
              Sold<i class="Sorting fa fa-chevron-up" ></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'expirydate')">
              Expiry<i class="Sorting fa fa-chevron-up" ></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event,'notes')">
              Notes<i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of LotTrackList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="goToEdit(item,content)">Edit</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.id}}</td>
            <td class="custom-td text-left"> {{item.name}}</td>
            <td class="custom-td text-left">{{item.bin_name}}</td>
            <td class="custom-td text-center">{{item.cnt}}</td>
            <td class="custom-td text-center">{{item.sold}}</td>
            <td class="custom-td text-left">{{item.expirydate}}</td>
            <td class="custom-td text-left">{{item.notes}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Lot# {{lotData.id}}</h5>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" #f="ngForm">
        <div class="form-row">
          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="" class="mb-1">Number</label>
            <input class="form-control" type="text" name="rating" [(ngModel)]="lotData.name">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="" class="mb-1">Count</label>
            <input class="form-control" type="number" name="count" [(ngModel)]="lotData.cnt" readonly>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="" class="mb-1">PO#</label>
            <input class="form-control" type="text" name="pon" [(ngModel)]="lotData.pon">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="">Bin</label>
            <div class="input-group ">
              <select class="form-control" [(ngModel)]="lotData.binid" name="binid" readonly>
                <option value="">Select Bin</option>
                <option *ngFor="let item of binList" [value]="item.Value">{{item.Text}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-sm-12">
            <label for="" class="mb-1">Description</label>
            <textarea class="form-control" rows="3" name="description" [(ngModel)]="lotData.notes"
              #description="ngModel"
              [ngClass]="{ 'is-invalid': description.invalid && description.errors , 'is-valid': description.valid}"
              required>
			</textarea>
            <div *ngIf="description.invalid && description.errors" class="invalid-feedback d-block">
              <div *ngIf="description.errors.required">
                Please enter description
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" [disabled]="!f.valid ||action=='view'"
        (click)="postLot()">Save</button>
    </div>
  </resize-border>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>