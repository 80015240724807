import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnyTxtRecord } from 'dns';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import { SpecmapServices } from '../specmap.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { AddmarkerModalComponent } from './addmarker/addmarker.component';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
	selector: 'app-markers',
	templateUrl: './markers.component.html',
	styleUrls: ['./markers.component.scss']
})
export class MarkersComponent implements OnInit {
	totalRows: any;
	markersList: any;
	description: any = '';
	userdata: any;
	format: any;
	companyLogo: any;
	appname: any = environment.APP_NAME;
	locationList: any;
	searchLocation = 'Text';
	markerData: any;
	types: any = [];
	subTypes: any = [];
	constructor(private service: SpecmapServices,private toastr:NotificationService, private modalService: NgbModal, private loader: NgxUiLoaderService, private datepipe: DatePipe) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format
		this.companyLogo += this.userdata.logo;
		this.settings.pager.perPage = Number(this.userdata.grid_page_size ? this.userdata.grid_page_size : 10);
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		this.getMarkersList();
		this.getSpecMapSites();
		this.getTypes();
	}

	ngOnInit(): void {

	}
	getSpecMapSites() {
		this.loader.start();
		this.service.getSpecMapSites().subscribe(res => {
			this.loader.stop();
			this.locationList = res;
		}, err => {
			this.loader.stop();
		})
	}

	getTypes() {
		this.loader.start();
		this.service.getSpecMapCategories().subscribe(res => {
			this.loader.stop();
			this.types = res;
		}, err => {
			this.loader.stop();
		})
	}

	getSubTypes() {
		this.loader.start();
		this.service.getSpecMapSubCategories(this.markerData.marker_type).subscribe((res: any) => {
			this.loader.stop();
			this.subTypes = res.lists;
		}, err => {
			this.loader.stop();
		})
	}

	refresh() {
		this.description = '';
		this.getMarkersList();
	}

	saveMarker() {
		if (!this.markerData.description) {
			Swal.fire('', 'Please enter description', 'warning');
			return;
		}
		this.markerData.companyid = this.userdata.companyid;
		if (this.markerData.id) {
			this.markerData.updateby = this.userdata.email;
			this.markerData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		} else {
			this.markerData.createby = this.markerData.createby;
			this.markerData.createdate = this.datepipe.transform(this.markerData.createdate, this.userdata.postdatetimeformat);
		}
		this.loader.start();
		this.service.postMarker(this.markerData).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
					this.modalService.dismissAll();
					this.getMarkersList();
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
			}
		}, err => {
			this.loader.stop();
		})
	}

	selectLocation(event) {
	}

	onFocusedLocation($event) {

	}


	inputLocationCleared() {

	}

	getMarkersList() {
		this.loader.start();
		this.service.getMarkersList(this.description).subscribe(data => {
			this.loader.stop();
			this.markersList = data;
			environment.production ? '' : console.log('getmarkerList data ', data);
			//#region Get total rows
			this.totalRows = this.markersList != null ? this.markersList.length : null;
			environment.production ? '' : console.log('Total Rows', this.totalRows);
			//#region Get total rows

		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading getMarkerList data ', err);
		});
	}

	open() {
		this.markerData = {
			"id": null,
			"siteid": null,
			"marker_type": null,
			"lat": null,
			"lng": null,
			"description": null,
			"companyid": this.userdata.companyid,
			"createby": this.userdata.email,
			"updateby": null,
			"updatedate": null,
			"createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
			"sub_marker_type": null,
			"active": true,
			"name": null
		}
		this.modalService.open(AddmarkerModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
			this.getMarkersList();
		}, (reason) => {
		});
	}

	ngOnDestroy() {
		this.modalService.dismissAll();
	}

	public settings = {
		pager: {
			display: true,
			perPage: 0
		},
		hideSubHeader: true,
		actions: false,
		columns: {
			customColumn: {
				title: 'Actions',
				type: 'custom',
				filter: false,
				renderComponent: MarkersActionColumn,
				onComponentInitFunction(instance) {
					//do stuff with component
					instance.id = 1;
					instance.click.subscribe(row => {
						console.debug('row', row, this.someMethod());
					});
					// instance.subscribe(data => environment.production ? '' : console.log(data))
				},
				valuePrepareFunction: (cell, row) => {
					return row.columnName;
				},
			},
			description: {
				title: 'Description',
				type: 'html',
				valuePrepareFunction: (description) => {
					return `
					<div class="csstooltip position-relative"><div class="show-less position-relative" container="body" ngbTooltip="${description}">${description ? description : ''}
					</div> <span class="tooltiptext">${description}</span></div>`
				}
			},
			type: {
				title: 'Type',
				type: 'html',
			},
			sub_type: {
				title: 'SubType',
				type: 'html',
			},
			lat: {
				title: 'Latitude',
				type: 'html',
			},
			lng: {
				title: 'Longitude',
				type: 'html',
			},
			active: {
				title: 'Active',
				type: 'html',
				valuePrepareFunction: (active) => {
					return `<span class="badge" title="${active}">${active}</span>`
				}

			},
			createby: {
				title: 'CreateBy'
			},
			createdate: {
				title: 'CreateDate',
				valuePrepareFunction: (createdate: any) => {
					return this.datepipe.transform(createdate, this.userdata.displaydatetimeformat)
				}
			},
			updateby: {
				title: 'UpdateBy'
			},
			updatedate: {
				title: 'UpdateDate',
				valuePrepareFunction: (updatedate: any) => {
					return this.datepipe.transform(updatedate, this.userdata.displaydatetimeformat)
				}
			},
		},
	};

}

@Component({
	selector: 'll-button-comp',
	template: `
	<div ngbDropdown class="d-inline-block ActionCell" container="body">
    <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
    <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
      <li *ngFor="let n of menuActions"><span ngbDropdownItem  (click)="clicked(n.title, content)">{{n.title}}</span></li>
    </ul> 
  </div>
  `
})
export class MarkersActionColumn implements OnInit {
	@Input() rowData: any;
	@Output() click: EventEmitter<any> = new EventEmitter(null);
	userdata: any;
	markerID: any;
	appname = environment.APP_NAME;
	menuData: any;
	menuActions: any;
	action: any;
	constructor(private modalService: NgbModal, private mainComponent: MarkersComponent) {

		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.menuData = JSON.parse(localStorage.getItem('EzyBooks-menu'));
		setTimeout(() => {
			this.menuData.forEach(data => {
				if (data.title.toLowerCase() == 'specmap') {
					var module = data;
					module.children.forEach(data => {
						if (data.title.toLowerCase() == 'markers') {
							this.menuActions = data.menuActions;
						}
					})
				}
			})
		}, 1000);
	}
	ngOnInit(): void {

	}

	clicked(name) {
		var modalRef = null;
		if (this.rowData.id) {
			this.markerID = this.rowData.id;
		}

		if (name.toLowerCase() == 'view') {
			this.action = 'view';
			modalRef = this.modalService.open(AddmarkerModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' });
			modalRef.componentInstance.id = this.rowData.id;
			modalRef.componentInstance.flag = 'view';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
				this.mainComponent.getMarkersList();
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});


		} else if (name.toLowerCase() == 'edit') {
			this.action = 'edit';
			modalRef = this.modalService.open(AddmarkerModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' });
			modalRef.componentInstance.id = this.rowData.id;
			modalRef.componentInstance.flag = 'edit';
			modalRef.result.then((result) => {
				environment.production ? '' : console.log(result);
				this.mainComponent.getMarkersList();
			}).catch((error) => {
				environment.production ? '' : console.log(error);
			});
		}
	}
}