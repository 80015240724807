import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reportgenericprint',
  templateUrl: './reportgenericprint.component.html',
  styleUrls: ['./reportgenericprint.component.scss']
})
export class ReportgenericprintComponent implements OnInit {
  fullScreen = false;
  @Input('params') params;
  @Input('typename') typename;
  userdata: any;
  appname = environment.APP_NAME;
  printURL: any;
  constructor(private modalService: NgbModal, private datepipe: DatePipe, private router: Router, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, public print: NgxPrintElementService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  ngOnInit(): void {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 2000)
    if (this.typename == 'customeragingsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&customerid=' + this.params.customerid + '&currency=' + this.params.currency + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // this.printURL = environment.API_URL + 'public/downloadpdf?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&vw=' + this.typename;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'details') {
      this.printURL = environment.API_URL + 'reports/ledgers?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&coa=' + this.params.coa + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'bslc') {
      this.printURL = environment.API_URL + 'reports/bslc?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&asondate=' + this.params.asondate + '&period=' + this.params.period + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'bsyc') {
      this.printURL = environment.API_URL + 'reports/bsyc?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&asondate=' + this.params.asondate + '&period=' + this.params.period + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'hbalancesheet') {
      this.printURL = environment.API_URL + 'reports/hbalancesheet?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&asondate=' + this.params.fromdate + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'vbalancesheet') {
      this.printURL = environment.API_URL + 'reports/vbalancesheet?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&asondate=' + this.params.fromdate + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'pl') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'trialbalance') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'ledgers') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&coa=' + this.params.coa + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'journals') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'refno=' + this.params.refno + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'audittrails') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&module=' + this.params.module + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'deletedtransactions') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'creditusesummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'customerid=' + this.params.customerid + '&seldate=' + this.params.seldate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'invoiceshistory') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'invno=' + this.params.qteno + '&customer=' + this.params.customer + '&status=' + this.params.status + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'quoteshistory') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'qteno=' + this.params.qteno + '&customer=' + this.params.customer + '&status=' + this.params.status + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'salesbycategory') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'customer=' + this.params.customer + '&status=' + this.params.status + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'vendoragingsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&supplierid=' + this.params.supplierid + '&currency=' + this.params.currency + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'expensehistory') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'expno=' + this.params.expno + '&supplier=' + this.params.supplier + '&expensetype=' + this.params.type + '&submittedby=' + this.params.submittedby + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'expensesummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'expno=' + this.params.expno + '&supplierid=' + this.params.supplierid + '&type=' + this.params.type + '&createby=' + this.params.createby + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'ordersummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'supplierid=' + this.params.supplierid + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'prodreplunishment') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&supplierid=' + this.params.supplierid + '&cat=' + this.params.cat + '&van=' + this.params.van + '&minmax=' + this.params.minmax + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'prodorderhistory') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&proj=' + this.params.proj + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'prodmovementhistory') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&serial=' + this.params.serial + '&proj=' + this.params.proj + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&deptid=' + this.params.deptid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'revenuebyproducts') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'landedcostaudit') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&pon=' + this.params.pon + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'salesvscostsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'stockcostreport') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&category=' + this.params.category + '&type=' + this.params.type + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'stocksummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'stockaudit') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'dept=' + this.params.dept + '&cat=' + this.params.cat + '&printcount=' + this.params.printcount + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'productspriceauditing') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'bolsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'bol=' + this.params.bol + '&po=' + this.params.po + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'serialsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&bin=' + this.params.bin + '&customer=' + this.params.customer + '&serialno=' + this.params.serialno + '&manufactuer=' + this.params.manufactuer + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'catalogsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&description=' + this.params.description + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'inventorybybin') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&category=' + this.params.cat + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'materialcosting') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'womaterialspending') {
      // this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'invbalance') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'asondate=' + this.params.asondate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'invvaluebyinvoice') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'asondate=' + this.params.asondate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'sopickstatus') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'orderno=' + this.params.orderno + '&orderref=' + this.params.orderref + '&ilc=' + this.params.ilc + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'materialdj') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&trantype=' + this.params.trantype + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'salesbymonth') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&category=' + this.params.category + '&supplier=' + this.params.supplier + '&year=' + this.params.year + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'salesbyyear') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&category=' + this.params.category + '&supplier=' + this.params.supplier + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'bolcostingexport') {
      // this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'assemblysalesbymonth') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'assname=' + this.params.assname + '&yr=' + this.params.yr + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'prodcostingreview') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'lotexpiry') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'inventorycountdiscrepancy') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'internaltransfers') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'notcounteditems') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'countid=' + this.params.countid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'Customer') {
      // this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'dailytransactions') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'customer=' + this.params.customer + '&reference=' + this.params.reference + '&mop=' + this.params.mop + '&receivedby=' + this.params.receivedby + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&ordertype=' + this.params.ordertype + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'sotransactions') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'recenttransactions') {
      // this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fdate=' + this.params.fdate + '&tdate=' + this.params.tdate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'transactiontpsync') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&txttype=' + this.params.txttype + '&tpsync=' + this.params.tpsync + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'techticketlogs') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'tech=' + this.params.tech + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'ticketsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'customer=' + this.params.customer + '&proj=' + this.params.projid + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'timesheetpdf') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'unbilledtimelogs') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&customerid=' + this.params.customerid + '&projid=' + this.params.projid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'joborders') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'pon=' + this.params.orderno + '&name=' + this.params.customerid + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'jobinvoices') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'num=' + this.params.inv + '&name=' + this.params.cust + '&status=' + this.params.status + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'ticketsinvcost') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'techhours') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'tech=' + this.params.tech + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&status=' + this.params.status + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'officeclockings') {
      // this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'servicecontractticketsummary') {
      // this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeeshistory') {
      if(this.params.patype == undefined || this.params.patype == null) {
        this.params.patype = ''
      }
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&gender=' + this.params.gender + '&email=' + this.params.email + '&paytype=' + this.params.patype + '&etype=' + this.params.etype + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeespaysummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeespaysummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeebirthdays') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&gender=' + this.params.gender + '&email=' + this.params.email + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeeworkpermits') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.tech + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeeaccesscards') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.ename + '&code=' + this.params.code + '&fromdate=' + this.params.fdate + '&todate=' + this.params.edate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeeconcessionpasses') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeeinsurances') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeepensions') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeeappraisals') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeedisciplinarylog') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeehurricanepocs') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'name=' + this.params.name + '&code=' + this.params.code + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'employeejobapps') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'position=' + this.params.position + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'ticketsinvcost') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'month=' + this.params.month + '&year=' + this.params.year + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'rfqreport') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + this.params.ids;
      // this.printURL = environment.API_URL + 'reports/RfqReportpdf?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + this.params.ids;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'receivebol') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&sid=' + this.params.sid + '&bol=' + this.params.bol + '&pon=' + this.params.pon + '&ilc=' + this.params.ilc;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'pensioninsurcontributions' || this.typename == 'epfcontributions' || this.typename == 'esiccontributions' || this.typename == 'payrollsummary') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'assesments') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&assid=' + this.params.assid + '&employeeid=' + this.params.employeeid;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
    else if (this.typename == 'countdetails') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id + '&searchtxt=' + this.params.searchtxt + '&countedby=' + this.params.countedby + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'customerpayments') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + this.params.searchtext + '&customerid=' + this.params.customerid + '&currency=' + this.params.currency + '&fdate=' + this.params.fdate + '&tdate=' + this.params.tdate;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'chequesissued') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'chequesreceived') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'customerwallethistory') {
      if(this.params.searchtext = undefined || this.params.searchtext == null) {
        this.params.searchtext = ''
      }
      if(this.params.tranntype = undefined || this.params.tranntype == null) {
        this.params.tranntype = ''
      }
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + this.params.searchtext + '&tranntype=' + this.params.tranntype + '&fromdate=' + this.params.fdate + '&todate=' + this.params.edate;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'customerwalletbalance') {
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + this.params.searchtext;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else {
      if(this.params.id == undefined || this.params.id == null) {
        this.params.id = ''
      }
      if(this.params.ilc == undefined || this.params.ilc == null) {
        this.params.ilc = ''
      }
      if(this.params.desc == undefined || this.params.desc == null) {
        this.params.desc = ''
      }
      this.printURL = environment.API_URL + 'reports/' + this.typename + '?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + this.params.id + '&ilc=' + this.params.ilc + '&desc=' + this.params.desc + '&countedby=' + this.params.countedby + '&fromdate=' + this.params.fromdate + '&todate=' + this.params.todate;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
