import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class DashboardServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    isLoading: boolean;
    objLogin: modelLogin;
    objComp: modelComp;
    objCompId: any;
    userprofile: any;
    public hero: ''
    public expenseData: ''
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    getMyReminders(email, searchtxt, fromdate, todate, type) {
        return this.http.get(this.baseUrl + 'api/Reminders/Gett_Reminders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&assignto=' + email + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate + '&type=' + type);
    }
    getReminderById(id) {
        return this.http.get(this.baseUrl + 'api/Reminders/Gett_ReminderByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getDepartmentList() {
        return this.http.get(this.baseUrl + 'api/InventoryItems/Get_DepartmentIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTransactionType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTransactionTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteReminder(id) {
        return this.http.delete(this.baseUrl + 'api/Reminders/Delete_Reminder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Time Log
    getShiftDetails() {
        return this.http.get(this.baseUrl + 'api/OfficeClockings/Get_ShiftDetails?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employee_id=' + this.userprofile.empid)
    }

    toggleShift(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/OfficeClockings/Post_ShiftLog', data);
    }

    shiftBreak(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/OfficeClockings/Post_ShiftLogBreak', data);
    }

    toggleEmployeeShift(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/OfficeClockings/Post_ClockInOut', data);
    }

    shiftEmployeeBreak(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/OfficeClockings/Post_ShiftBreak', data);
    }

    //Employee Leave
    getEmployeeLeave() {
        return this.http.get(this.baseUrl + 'api/Leaves/Gett_LeavesByEmployeeID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employee_id=' + this.userprofile.empid)
    }
    getLeavesById(id) {
        return this.http.get(this.baseUrl + 'api/Leaves/Gett_LeaveByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    postEmployeeLeave(val) {
        const data = JSON.stringify(val);
        // return this.http.post(this.baseUrl + 'api/OfficeClockings/Postt_leaves', data)
        return this.http.post(this.baseUrl + 'api/Leaves/Postt_Leaves', data);

    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

    postReminder(data) {
        return this.http.post(this.baseUrl + 'api/Reminders/Postt_Reminder', data);
    }

    getReminderType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetReminderTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getRecentTransactionType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetRecentTranTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    getUsers() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetUsers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getReasons(type) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetShiftBreakReasons?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type)
    }

    //all modules seperate apis.

    getHomeMenu() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeMenu?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeMenuDummy() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeMenuDummy?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeTilelist() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeTiles?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeReminders() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeReminders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getRecentTransaction() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeRecentTransactions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }

    getHomeAnnouncements() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeAnnouncements?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeSalesSummary() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeSalesSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomePurchaseSummary() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomePurchaseSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeTimeLogs() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeTimeLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid=' + this.userprofile.empid);
    }
    getHomeClock() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeClock?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid=' + this.userprofile.empid);
    }
    getHomeEmpLeaves() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeEmpLeaves?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid=' + this.userprofile.empid);
    }
    getHomeTicketSummary() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeTicketSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeEzbCosting(month, year) {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeEzbCosting?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeTaskSummary() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeTaskSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    getHomeSnagSummary() {
        return this.http.get(this.baseUrl + 'api/Home/Get_HomeSnagSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + this.userprofile.id);
    }
    // Customers/Delete_CustomerCard
    deleteCard(id) {
        return this.http.delete(this.baseUrl + 'api/Customers/Delete_CustomerCard?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    setCustomerCard(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Set_DefaultCustomerCard?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    getCostingDetailsByDate(yr, month) {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_EzbCostingByDate?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&yr=' + yr + '&month=' + month)

    }
    getCostingInv(fromdate, todate, eb_customerid) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_EzbSubscriptionInvoices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&eb_customerid=' + eb_customerid)

    }







}
