<resize-border class="modal-resize" [dragHolder]="header" style="position: relative">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Receive PO
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form">
        <div class="form row">
          <div class="form-group col-lg-6 col-12">
            <label for="" class="mb-1">Status</label>
            <select class="form-control" required="" [(ngModel)]="poDetails.status">
              <option value="">Select Status</option>
              <option *ngFor="let status of poStatusList" [value]="status.stringValue">{{status.Text}}</option>
            </select>
          </div>
          <div class="form-group col-lg-6 col-12">
            <label for="" class="mb-1">Notes</label>
            <input class="form-control" id="" name="notes" type="text" [(ngModel)]="poDetails.msg" placeholder="Notes">
          </div>
        </div>
        <div class="category-table receivepoModal">
          <div class="table-responsive">
            <table class="custom-table">
              <tr class="custom-tr">
                <th class="custom-th text-center w-150" (click)="onSortClick($event)">
                  ILC <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Description <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Serilized <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Lot Tracking? <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  OrderQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center w-90" (click)="onSortClick($event)">
                  Cost <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Qty2beReceived <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  OverageQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  DamageQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Serial <i class="Sorting fa fa-chevron-up"></i>
                </th>
              </tr>
              <ng-container *ngFor="let item of poDetails.lstItems;let j=index">
                <tr class="custom-tr" *ngIf="item.ilc !==null">
                  <td class="custom-td text-left w-150">
                    <i class="fa fa-chevron-circle-down float-left" aria-hidden="true"
                      *ngIf="!item.buttonclicked && (item.qty - item.receivedqty)>0 && !item.isserilized"
                      (click)="item.buttonclicked = !item.buttonclicked"></i>
                    <i class="fa fa-chevron-circle-up float-left" aria-hidden="true" *ngIf="item.buttonclicked"
                      (click)="item.buttonclicked = !item.buttonclicked"></i>
                    {{item.ilc}}
                  </td>
                  <td class="custom-td text-left">
                    {{item.description}}
                  </td>
                  <td class="custom-td text-center">
                    <span *ngIf="!item.isserilized">No</span>
                    <a class="link-text" *ngIf="item.isserilized" (click)="serilizepo(item)">Yes <i
                        class="eb-receive-po"></i></a>
                  </td>
                  <td class="custom-td text-center">
                    {{item.lot_tracking}}
                  </td>
                  <td class="custom-td text-center">
                    {{item.qty}}
                  </td>
                  <td class="custom-td text-center">

                    <input type="number" name="cost-{{i}}" class="form-control text-center" [(ngModel)]="item.cost" />
                  </td>
                  <td class="custom-td text-center">
                    {{item.qty - item.receivedqty}}
                  </td>
                  <td class="custom-td text-center">

                    <input type="number" name="cost-{{i}}" class="form-control text-center"
                      [(ngModel)]="item.overageqty" />
                  </td>
                  <td class="custom-td text-center">

                    <input type="number" name="cost-{{i}}" class="form-control text-center"
                      [(ngModel)]="item.damageqty" />
                  </td>
                  <td class="custom-td text-center">
                    {{item.serial}}
                  </td>
                </tr>
                <tr class="custom-tr" *ngIf="item.buttonclicked && item.lot_tracking">
                  <td class="custom-td text-left" colspan="10">
                    <table class="custom-table">
                      <tr class="custom-tr">
                        <th class="custom-th text-center">
                          Lot Number
                        </th>
                        <th class="custom-th text-center">
                          Lot Notes
                        </th>
                        <th class="custom-th text-center">
                          Lot Expiry
                        </th>
                      </tr>
                      <tr class="custom-tr">
                        <th class="custom-td text-center">
                          <input type="text" name="lot_name-{{i}}" class="form-control text-center"
                            [(ngModel)]="item.lot_name" />
                        </th>
                        <th class="custom-td text-center">
                          <input type="text" name="lot_notes-{{i}}" class="form-control text-center"
                            [(ngModel)]="item.lot_notes" />
                        </th>
                        <th class="custom-td text-center">
                          <input type="date" name="lot_expiry-{{i}}" class="form-control text-center"
                            [(ngModel)]="item.lot_expiry" />
                        </th>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr class="custom-tr" *ngIf="item.buttonclicked">
                  <td class="custom-td text-left" colspan="10">
                    <table class="custom-table">
                      <tr class="custom-tr">
                        <th class="custom-th text-center">
                          Bin
                        </th>
                        <th class="custom-th text-center">
                          Description
                        </th>
                        <th class="custom-th text-center">
                          QtyReceived
                        </th>
                      </tr>
                      <tr class="custom-tr" *ngFor="let loc of item.itemBins; index as i">
                        <td class="custom-td text-left">
                          {{loc.code}}
                        </td>
                        <td class="custom-td text-left">
                          {{loc.name}}
                        </td>
                        <td class="custom-td text-center">
                          <input type="number" name="receivedcnt-{{i}}" class="form-control text-center"
                            [(ngModel)]="loc.receivedcnt" (input)="checkReceivedTotal(item,loc)" />
                        </td>
                      </tr>
                      <tr class="custom-tr">
                        <td class="custom-td text-left">
                          <!-- <select class="form-control" name="newbin-{{j}}" required="" [(ngModel)]="item.newbin"
                        (change)="selectBin(item)">
                        <option value="">Select Bin</option>
                        <option *ngFor="let loc of binLocationList" [value]="loc.Value">{{loc.Value}} - {{loc.Text}}
                        </option>
                      </select> -->
                          <!-- <input class="form-control" placeholder="Search bin" list="newbin" name="newbin"  [(ngModel)]="item.newbin">
                        <datalist id="newbin">
                            <option *ngFor="let loc of binLocationList"  [value]="loc.Value" (click)="selectBin(item)">{{loc.Value}} - {{loc.Text}} </option>
                        </datalist> -->
                          <ng-select placeholder="Select Bin" [clearable]="false" appendTo=".modal-resize"
                            name="newbin-{{j}}" required="" [(ngModel)]="item.newbin" (change)="selectBin(item)">
                            <ng-option *ngFor="let loc of binLocationList" [value]="loc.Value">{{loc.Value}} -
                              {{loc.Text}}</ng-option>
                          </ng-select>

                          <!-- <ng-autocomplete #binLocation="ngModel" [data]="binLocationList" [searchKeyword]="keyword2"
                        placeholder="Search bin location" (selected)='selectEvent($event, item)'
                        (inputFocused)='onFocused($event)' [itemTemplate]="itemsTemplate"
                        [notFoundTemplate]="notFoundTemplate" [(ngModel)]="binlocation"
                        [ngModelOptions]="{standalone: true}"
                        [ngClass]="{ 'is-invalid': binLocation.invalid && binLocation.errors , 'is-valid': binLocation.valid}"
                        required>
                      </ng-autocomplete>
                      <ng-template #itemsTemplate let-item>
                        <a [innerHTML]="item.Value"></a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template> -->
                        </td>
                        <td class="custom-td text-left">
                          {{item.newbincode}}
                        </td>
                        <td class="custom-td text-center">
                          <input type="number" name="newbincnt-{{j}}" class="form-control text-center"
                            [disabled]="!item.newbin" (input)="checkReceivedTotal(item)" [(ngModel)]="item.newbincnt" />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" type="button" (click)="postReceivePO()">Save</button>
    </div>
  </div>
</resize-border>