import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NavService } from 'src/app/shared/service/nav.service';
import { projectCategoryDB } from 'src/app/shared/tables/project-category-db';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { accountServices } from '../../accounts/account.service';
import * as moment from 'moment';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
	selector: 'app-reviewtransactions',
	templateUrl: './reviewtransactions.component.html',
	styleUrls: ['./reviewtransactions.component.scss']
})
export class ReviewtransactionsComponent implements OnInit {
	fullScreen = false;
	@Input('batchid') batchid;
	@Input('fromdate') fromdate;
	@Input('todate') todate;
	@Input('txntype') txntype;
	@ViewChild('reviewTransTable') reviewTransTable: any;
	public isCollapsed = true;
	//#region userdata block
	appname = environment.APP_NAME;
	userdata: any;
	companyLogo: any = 'http://ezybooks.net/docs/logos/';
	format: any;
	totalRows: any;
	//#endregion userdata block

	TransactionType: any;

	review: any = {
		fromdate: '',
		todate: '',
		narr: '',
		txtype: ''
	}
	public isShow: boolean = false

	dropdowntoggle() {
		this.isShow = !this.isShow;
	}
	public arrowicon: boolean = true;
	clickEvent() {
		this.arrowicon = !this.arrowicon;
	}
	public projectData = [];

	reviewTransData: any;

	//#region Ng multiselect dropdown
	txtTypeSetting: IDropdownSettings;
	txtypeList: any = [];
	selectedtxType = [];

	statusSetting: IDropdownSettings;
	statusList: any = [];
	selectedstatus = [];
	selectedtype = [];
	stringData = '';
	//#endregion Ng multiselect dropdown

	selectedRows: any;
	constructor(
		private service: accountServices,
		private commonService: CommonServices,
		private loader: NgxUiLoaderService,
		private datepipe: DatePipe,
		private toastr: NotificationService,
		private activeModal: NgbActiveModal,

	) {
		//#region userdata block
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format
		this.companyLogo += this.userdata.logo;
		this.settings.pager.perPage = Number(this.userdata.grid_page_size ? this.userdata.grid_page_size : 15);
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
		//#endregion userdata block

		this.review.fromdate = this.userdata.fromdate;
		this.review.todate = this.datepipe.transform(new Date(), 'yyyy-MM-dd')
		environment.production ? '' : console.log('from date', this.review.fromdate);
		environment.production ? '' : console.log('end date', this.review.todate);

		this.projectData = projectCategoryDB.projectDB;

		this.commonService.getComboEnums('paymenttrantype').subscribe(res => {
			this.txtypeList = res;
			environment.production ? '' : console.log('Trans Type list ', this.txtypeList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading Trans Type List ', err);
		});

		this.commonService.getComboEnums('journaltype').subscribe(res => {
			this.TransactionType = res;
			// this.selectedstatus = this.statusList;
			environment.production ? '' : console.log('Status list ', this.statusList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading Status List ', err);
		});
	}

	public settings = {
		selectMode: 'multi',
		pager: {
			display: true,
			perPage: 0
		},
		hideSubHeader: false,
		perPage: 25,
		actions: false,
		columns: {
			// customColumn: {
			// 	title: 'Actions',
			// 	type: 'custom',
			// 	filter: false,
			// 	renderComponent: ReviewTransactionsActionColumn,
			// 	onComponentInitFunction(instance) {
			// 		//do stuff with component
			// 		instance.id = 1;
			// 		instance.click.subscribe(row => {
			// 			console.debug('row', row, this.someMethod());
			// 		});
			// 		// instance.subscribe(data => environment.production ? '' : console.log(data))
			// 	},
			// 	valuePrepareFunction: (cell, row) => {
			// 		return row.columnName;
			// 	},
			// },
			transactiondate: {
				title: 'Txn Date',
				type: 'html',
				width: '100px',
			},
			customer: {
				title: 'Party'
			},
			num: {
				title: 'RefNo ',
				type: 'html',
			},
			currency: {
				title: 'Cur.',
			},
			amt: {
				title: 'Amount',
			},
			txntype: {
				title: 'Txn Type',
			},
			Memo: {
				title: 'Memo',
			},
			Narration: {
				title: 'Narration',
			},
			status: {
				title: 'Status',
				type: 'html',
				valuePrepareFunction: (status) => {
					return `<span class="badge" title="${status}">${status}</span>`
				}
			}
		},
	};
	onUserRowSelect(event) {
		this.selectedRows = event.selected;
		if (this.selectedRows.length == this.reviewTransData.length) {
			this.reviewTransTable.isAllSelected = true;
		} else {
			this.reviewTransTable.isAllSelected = false;
		}
	}
	ngOnInit(): void {
		this.review.fromdate = this.fromdate;
		this.review.todate = this.todate;
		//#region Grid Settings
		if (this.userdata.grid_inline_filter === false) {
			this.settings.hideSubHeader = true;
			environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
		} else {
			this.settings.hideSubHeader = false;
			environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
		}
		this.settings.perPage = this.userdata.grid_page_size;
		environment.production ? '' : console.log('settings.perPage :', this.settings.perPage);
		var editDataForHeader = '';
		this.commonService.headerEditItemData.next(editDataForHeader); //end '
		//#region Grid Settings

		//#region NgAutocomplete
		this.txtTypeSetting = {
			singleSelection: false,
			idField: 'stringValue',
			textField: 'Text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 2,
			allowSearchFilter: true
		}

		this.statusSetting = {
			singleSelection: false,
			idField: 'stringValue',
			textField: 'Text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 2,
			allowSearchFilter: true
		}
		//#endregion

		this.getReviewTransactions();
		// this.getTransactiontypes();
	}


	getTransactiontypes() {
		this.service.getTrasanctionTypes().subscribe(data => {
			this.TransactionType = data;
			environment.production ? '' : console.log('Transaction Type', this.TransactionType);
		})
	}

	//#region NgAutocomplete TxType
	onItemSelectTxType(item: any) {
		let arrayData = [];
		this.selectedtxType.forEach(data => {
			arrayData.push('' + data.stringValue);
			environment.production ? '' : console.log(arrayData);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.review.txtype = this.stringData;
		this.getReviewTransactions();
	}

	onSelectAllTxType(items: any) {
		let arrayData = [];
		items.forEach(data => {
			arrayData.push('' + data.stringValue);
		});
		this.stringData = arrayData.toString();
		this.review.txtype = this.stringData;
		environment.production ? '' : console.log('Selected All: ', this.review.txtype);
		this.getReviewTransactions();
	}

	onItemDeSelectTxType($event) {
		let arrayData = [];
		this.selectedtxType.forEach(data => {
			arrayData.push('' + data.stringValue);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.review.txtype = this.stringData;
		environment.production ? '' : console.log('Item DeSelect :', this.review.txtype);
		this.getReviewTransactions();
	}

	onDeSelectAllTxType(items: any) {
		this.review.txtype = '';
		environment.production ? '' : console.log('Deselected All: ', this.review.txtype);
		this.getReviewTransactions();
	}
	//#endregion

	//#region NgAutocomplete Status
	onItemSelectStatus(item: any) {
		let arrayData = [];
		this.selectedstatus.forEach(data => {
			arrayData.push('' + data.stringValue);
			environment.production ? '' : console.log(arrayData);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.review.status = this.stringData;
		environment.production ? '' : console.log('Item Selected: ', this.review.status);
		this.getReviewTransactions();
	}
	onItemSelectType(item: any) {
		let arrayData = [];
		this.selectedtype.forEach(data => {
			arrayData.push('' + data.stringValue);
			environment.production ? '' : console.log(arrayData);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.review.txtype = this.stringData;
		environment.production ? '' : console.log('Item Selected: ', this.review.txtype);
		this.getReviewTransactions();
	}


	onSelectAllStatus(items: any) {
		let arrayData = [];
		items.forEach(data => {
			arrayData.push('' + data.stringValue);
		});
		this.stringData = arrayData.toString();
		this.review.status = this.stringData;
		environment.production ? '' : console.log('Selected All: ', this.review.status);
		this.getReviewTransactions();
	}

	onItemDeSelectStatus($event) {
		let arrayData = [];
		this.selectedstatus.forEach(data => {
			arrayData.push('' + data.stringValue);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.review.status = this.stringData;
		environment.production ? '' : console.log('Item DeSelect :', this.review.status);
		this.getReviewTransactions();
	}

	onDeSelectAllStatus(items: any) {
		this.review.status = '';
		environment.production ? '' : console.log('Deselected All: ', this.review.status);
		this.getReviewTransactions();
	}
	//#endregion
	convertToDate(dateString: string): Date {
		const timestamp = Date.parse(dateString);
		if (!isNaN(timestamp)) {
			return new Date(timestamp);
		} else {
			throw new Error('Invalid date format');
		}
	}
	getReviewTransactions() {
		this.loader.start();
		this.service.getReviewTrans(this.datepipe.transform(new Date(this.review.fromdate), this.userdata.postdateformat), this.datepipe.transform(new Date(this.review.todate), this.userdata.postdateformat), this.review.txtype, this.review.narr).subscribe(res => {
			this.loader.stop();
			this.reviewTransData = res;
			this.reviewTransData.forEach(element => {
				const dateObject = this.convertToDate(element.txndate);
				element.transactiondate = this.datepipe.transform(new Date(dateObject), this.format);
				if (element.amt != null && element.amt != 0) {
					element.amt = element.amt.toFixed(this.userdata.decimals_allowed);
				}
				else {
					element.amt = parseFloat("0").toFixed(this.userdata.decimals_allowed);
				}
			});

			//#region Get total rows
			this.totalRows = this.reviewTransData != null ? this.reviewTransData.length : null;
			environment.production ? '' : console.log('Total Rows', this.totalRows);
			//#region Get total rows

			environment.production ? '' : console.log('Review Transaction Data ', this.reviewTransData);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading Review Transaction Data ', err);
		});
	}

	closeModal() {
		this.activeModal.close('Modal Closed');
	}
	postTransaction() {
		var tranids = [];
		if (this.selectedRows.length > 0) {
			this.selectedRows.forEach(item => {
				tranids.push({
					"id": null,
					"batchid": this.batchid,
					"txndate": this.datepipe.transform(new Date(item.txndate), this.userdata.postdatetimeformat),
					"refid": item.id,
					"refno": item.num,
					"party": item.customer,
					"currency": item.currency,
					"roe": item.roe,
					"amt": item.amt,
					"trantype": item.txntype,
					"narration": item.memo,
					"status": item.status
				})
			})
		}
		if (tranids.length == 0) {
			Swal.fire('', 'Please select transaction', 'error');
			return;
		}
		var postObj = {
			"id": this.batchid,
			"companyid": this.userdata.companyid,
			"updateby": this.userdata.email,
			"updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
			"batchEntries": tranids
		}
		this.loader.start();
		this.service.postBatchTransactions(postObj).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.getReviewTransactions();
			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading complete signup data ', err);
		});
	}

}


@Component({
	selector: 'll-button-comp',
	template: ` 
	<div class="ActionCheckbox">
	<input class="" type="checkbox" value="" id="flexCheckDefault">
		<i class="fa fa-share icon" container="body" ngbTooltip="Post Transaction"></i>
	</div>
	`
})

export class ReviewTransactionsActionColumn implements OnInit {
	@Input() rowData: any;
	@Output() click: EventEmitter<any> = new EventEmitter(null);
	renderValue: string;
	menuData: any;
	menuActions: any;

	constructor(private navservice: NavService, private toastr: ToastrService, private commonService: CommonServices, private loader: NgxUiLoaderService, private maincomponent: ReviewtransactionsComponent, private datepipe: DatePipe) {
		this.menuData = JSON.parse(localStorage.getItem('EzyBooks-menu'));

		setTimeout(() => {
			this.menuData.forEach(data => {
				if (data.title.toLowerCase() == 'accounts') {
					var module = data;

					module.children.forEach(data => {
						if (data.title.toLowerCase() == 'review transactions') {
							this.menuActions = data.menuActions;
						}
					})
				}
			})
			environment.production ? '' : console.log(this.menuActions)
		}, 1000);
	}
	ngOnInit() {
		this.renderValue = this.rowData.id;
	}

	clicked(actionname) {
		if (actionname.toLowerCase() == 'post transaction') {
			// this.postTransaction(this.rowData.id);
		}
	}

}