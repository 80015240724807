<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <div class="card bg-primary">
            <a href="javascript:void(0)" routerLink="/" class="svg-icon">
              <img src="assets/images/applogo.svg">
            </a>
            <div class="single-item">
              <owl-carousel-o [options]="owlcarouselOptions">
                <ng-container *ngFor="let data of owlcarousel">
                  <ng-template carouselSlide class="item">
                    <div>
                      <h3>{{data.title}}</h3>
                      <p>{{data.desc}}</p>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body">
              <h4><i class="icon-user mr-2"></i>Login</h4>

              <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                <form name="LoginForm" #f="ngForm" class="form-horizontal auth-form" novalidate="">
                  <div class="mb-2">
                    <input name="username" type="email" class="form-control" placeholder="Email" id="exampleInputEmail1"
                      [(ngModel)]="tempForm.username" #username="ngModel" [disabled]="isLoading"
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      [ngClass]="{ 'is-invalid': username.invalid && username.errors , 'is-valid': username.valid}"
                      required="" [readonly]="isLogin" />
                    <div *ngIf="username.invalid && username.errors" class="invalid-feedback d-block">
                      <div class="col-lg-12">
                        <div *ngIf="username.errors.required">
                          Email is required
                        </div>
                        <div *ngIf="username.errors?.pattern">
                          Valid Email is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 company-scroll">
                    <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                      *ngFor='let item of companyidList;let i = index'>
                      <label class="d-block" for="showMultiple{{item.companyid}}">
                        <input class="radio_animated" id="showMultiple{{item.companyid}}" [value]="item.companyid"
                          type="radio" name="companyid" #companyid="ngModel" [checked]="item.checked"
                          [(ngModel)]="tempForm.companyid" (change)="changeCompany()">
                        {{item.displayname}}
                      </label>
                    </div>
                  </div>
                  <div class="form-group passwordToggle" *ngIf="isLogin && !isSocialLogin">
                    <input name="password" type="password" class="form-control" placeholder="Password"
                      [(ngModel)]="tempForm.password" #password="ngModel" [type]="passActive ? 'password' : 'text'"
                      [ngClass]="{ 'is-invalid': password.invalid && password.errors , 'is-valid': password.valid}"
                      required="" />
                    <i [ngClass]="passActive ? 'fa fa-eye' : 'fa fa-eye-slash'" id="PasswordToggle"
                      (click)="passToggle()"></i>
                    <div *ngIf="password.invalid && password.errors" class="invalid-feedback d-block">
                      <div class="col-lg-12">
                        <div *ngIf="password.errors.required">
                          Password is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-terms">
                    <div class="">
                      <div class="custom-control custom-checkbox mr-sm-2" *ngIf="isLogin">
                        <input type="checkbox" class="custom-control-input" id="customControlAutosizing">
                        <label class="custom-control-label" for="customControlAutosizing">
                          Remember me
                        </label>
                      </div>
                    </div>

                    <a href="javascript:void(0)" routerLink="/forgotPassword" class="btn btn-default forgot-pass">forgot
                      password</a>
                  </div>
                  <div class="form-button">
                    <button class="btn btn-primary" type="Save" *ngIf="!isLogin && !isSocialLogin" [disabled]="!f.valid"
                      (click)="findUser()">
                      <i class="loading fa fa-spinner fa-spin" *ngIf="isLoading"></i>
                      Next
                    </button>
                    <button class="btn btn-primary" *ngIf="isLogin && !isSocialLogin" type="Save" [disabled]="!f.valid"
                      (click)="doLogin()">
                      <i class="loading fa fa-spinner fa-spin" *ngIf="isLoading"></i>
                      Login
                    </button>
                    <button *ngIf="isSocialLogin" class="btn btn-primary" type="Save" (click)="doSocialLogin()">
                      <i class="loading fa fa-spinner fa-spin" *ngIf="isLoading"></i>
                      Login
                    </button>
                  </div>
                  <div class="form-footer">
                    <span class="OtherOptions">
                      <span *ngIf="!tempForm.companyid">
                        Don't have an account?
                        <a href="javascript:void(0)" (click)="gotoPricing()" class=" forgot-pass"
                          style="margin-left: 5px; text-transform: inherit;">
                          Register here
                        </a>
                      </span>
                      <!-- <span *ngIf="companyid">&nbsp;OR</span> -->

                      <a href="javascript:void(0)" *ngIf="tempForm.companyid" (click)="differentAccount()"
                        class=" forgot-pass" style="margin-left: 5px; text-transform: inherit;">
                        Sign in to a different account
                      </a>
                    </span>
                    <div class="flex">
                      <!-- <button class="social-button" (click)="signInWithGoogle()"><i class="fa fa-google"></i></button> -->
                      <!-- <button class="social-button" (click)="signInWithFB()"><i class="fa fa-facebook"></i></button> -->
                      <!-- <button class="social-button" (click)="signInWithMicrosoft()"><i
                          class="fa fa-windows"></i></button> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>