<resize-border [dragHolder]="header">
<div #header class="modal-header">
  <h4 class="modal-title">Product UOM</h4>
  <div>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <button type="button" class="close"></button>
  </div>
</div>
<div class="modal-body">
  <div class="input-group mb-3">
    <input type="text" class="form-control" placeholder="Add Description" aria-label="Search"
      [(ngModel)]="master.description" aria-describedby="basic-addon2" name="description">
    &nbsp;
    <input type="number" class="form-control" placeholder="Qty" aria-label="Search" [(ngModel)]="master.qty"
      aria-describedby="basic-addon2" name="qty">
    <div class="input-group-append">
      <span class="input-group-text btn-primary cursor-pointer" (click)="postProductUOM()" id="basic-addon2">Save</span>
    </div>
  </div>
  <div class="category-table custom-datatable mb-3">
    <div class="table-responsive2">
      <table class="custom-table">
        <thead>
          <tr class="custom-tr">
            <th class="custom-th text-center">ID#</th>
            <th class="custom-th text-center">Description</th>
            <th class="custom-th text-center">Qty</th>
            <th class="custom-th text-center">Active</th>
            <th class="custom-th text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="custom-tr" *ngFor="let item of itemList">
            <td class="custom-td text-center">{{item.id}}</td>
            <td class="custom-td">{{item.description}}</td>
            <td class="custom-td text-center">{{item.qty}}</td>
            <td class="custom-td text-center">{{item.active}}</td>
            <td class="custom-td text-center">
              <div class="modalActionBtn">
                <i class="eb-edit mr-1" (click)="editMasterData(item)"></i>
                <i class="eb-delete" (click)="deleteProductUOM(item)"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Close</button>
</div>
</resize-border>