import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { serviceManagerServices } from '../../servicemanager/servicemanager.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SchedulerServices } from '../../scheduler/scheduler.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { EdittimematerialmodalComponent } from '../../servicemanager/servicetickets/timematerials/edittimematerialmodal/edittimematerialmodal.component';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  fullScreen = false;
  @Input() tid: any;
  @Input() ticket: any;
  @ViewChild('Technician') Technician: any;
  unauditList: any = [];
  isTimeCard = true;
  status: any = 'submitted';
  technician: any;
  technicianList: any = [];
  userdata: any;
  appname: any = environment.APP_NAME;
  inventoryItems: any = [];
  serviceItems: any = [];
  keywordTechSearch: any = 'Text';
  materialMainCheckbox: any;
  timeCardMainCheckbox: any;
  isView: boolean;
  TicketItemLocations: any[];
  constructor(private modalService: NgbModal, private toastr: NotificationService, private activeModal: NgbActiveModal, private schedulerService: SchedulerServices, private service: serviceManagerServices, private datepipe: DatePipe, private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getTechList();
  }

  ngOnInit(): void {
    this.getAuditCards();
  }

  getTechList() {
    this.schedulerService.getSchedulerResources().subscribe((res: any) => {
      this.technicianList = res;
    });
  }
  getAuditCards() {
    this.loader.start();
    this.service.getUnauditedCards(this.tid).subscribe(res => {
      this.loader.stop();
      this.unauditList = res;
      if (this.unauditList != null && this.unauditList.unAuditedTicketCards != undefined) {
        this.unauditList.unAuditedTicketCards.forEach(item => {
          item.ischecked = false;
          item.approvedhrs = item.hrs;
          item.dw = item.dw ? this.datepipe.transform(new Date(item.dw), this.userdata.date_format) : '';
        });
      }
      this.filterItems();
    })
  }
  filterItems() {
    this.inventoryItems = [];
    this.serviceItems = [];
    if (!this.isTimeCard) {
      this.unauditList.unAuditedTicketItems.forEach(item => {
        if (item.status == this.status) {
          this.inventoryItems.push(item);
        }
      })
    } else {
      this.unauditList.unAuditedTicketCards.forEach(item => {
        if (item.status == this.status) {
          this.serviceItems.push(item);
        }
      })
    }
  }
  getAuditItem() {
    this.loader.start();
    this.service.getUnauditedItems(this.tid).subscribe(res => {
      this.loader.stop();
      this.unauditList = res;
      if (this.unauditList != null && this.unauditList.unAuditedTicketItems != undefined) {
        this.unauditList.unAuditedTicketItems.forEach(item => {
          item.ischecked = false;
          item.bookingtime = item.bookingtime ? this.datepipe.transform(new Date(item.bookingtime), 'yyy-MM-dd') : '';
          item.total = (item.hrs ? Number(item.hrs) : 0) * (item.rate ? Number(item.rate) : 0);
          item.rate = Number(item.rate).toFixed(this.userdata.decimals_allowed);
          item.total = Number(item.total).toFixed(this.userdata.decimals_allowed);
        });
      }
      this.filterItems();
    })
  }
  validateHrs(item) {
    if (item.approvedhrs && item.approvedhrs > item.hrs) {
      this.toastr.Error('Approved hrs cannot exceed actual hrs');
      item.approvedhrs = item.hrs;
    } else if (!item.approvedhrs) {
      item.approvedhrs = item.hrs;
    }
  }
  calculateLineTotal(item) {
    item.total = (item.qty ? Number(item.qty) : 0) * (item.rate ? Number(item.rate) : 0);
    item.rate = Number(item.rate).toFixed(this.userdata.decimals_allowed);
    item.total = Number(item.total).toFixed(this.userdata.decimals_allowed);
    // this.goToEdit(item, 'edit');
  }
  checkTimeCardValue() {
    if (this.timeCardMainCheckbox) {
      this.serviceItems.forEach(item => {
        item.ischecked = true;
      })
    } else {
      this.serviceItems.forEach(item => {
        item.ischecked = false;
      })
    }
  }
  checkMatrialValue() {
    if (this.materialMainCheckbox) {
      this.inventoryItems.forEach(item => {
        item.ischecked = true;
      })
    } else {
      this.inventoryItems.forEach(item => {
        item.ischecked = false;
      })
    }
  }
  selectTechnician(event) {

  }

  onFocusedTech(event) {

  }

  closeModal() {
    this.activeModal.close();
  }
  postAudit(action) {
    if (this.isTimeCard) {
      this.postAuditCards(action);
    } else {
      this.postAuditItems(action);
    }
  }
  postAuditItems(action) {
    var unAuditedTicketItems = [];
    this.inventoryItems.forEach(item => {
      if (item.ischecked) {
        unAuditedTicketItems.push(item);
      }
    })
    if (unAuditedTicketItems.length == 0) {
      // Swal.fire('', 'Select item from list', 'error');
      this.toastr.Error('Select item from list');
      return;
    }
    var postObj = {
      "tid": this.tid,
      "companyid": this.userdata.companyid,
      "status": action,
      "updateby": this.userdata.email,
      "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "unAuditedTicketItems": unAuditedTicketItems
    }
    this.loader.start();
    this.service.postAuditedItem(postObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.getAuditItem();
    })
  }

  postAuditCards(action) {
    var unAuditedTicketItems = [];
    this.serviceItems.forEach(item => {
      if (item.ischecked) {
        unAuditedTicketItems.push(item);
      }
    })
    if (unAuditedTicketItems.length == 0) {
      // Swal.fire('', 'Select item from list', 'error');
      this.toastr.Error('Select item from list');
      return;
    }
    var postObj = {
      "tid": this.tid,
      "companyid": this.userdata.companyid,
      "status": action,
      "updateby": this.userdata.email,
      "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "unAuditedTicketCards": unAuditedTicketItems
    }
    this.loader.start();
    this.service.postAuditedCard(postObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.getAuditCards();
    })
  }

  calculateHrs(item) {
    let newTime1: any;
    let newTime2: any;

    const date1 = new Date(item.startime),
      date2 = new Date(item.endtime);
    if (JSON.stringify(date1) === 'null') {
      newTime1 = this.toTime(item.startime);
    } else {
      newTime1 = this.toTime(date1.toTimeString());
    }

    if (JSON.stringify(date2) === 'null') {
      newTime2 = this.toTime(item.endtime);
    } else {
      newTime2 = this.toTime(date2.toTimeString());
    }

    var duration = newTime2 - newTime1;
    var hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    item.hrs = this.timeStringToFloat(hours + '.' + minutes);
    environment.production ? '' : console.log('timeDifference : ', item.hrs);
    this.calculateTotal(item);
    var totalWrkHrs = 0, totalWrkMnt = 0;
    totalWrkHrs = hours;
    totalWrkMnt = minutes;
    totalWrkHrs += totalWrkMnt / 60;
    item.hrs = totalWrkHrs.toFixed(2)
  }

  toTime(timeString) {
    var timeTokens = timeString.split(' ');
    timeTokens = timeTokens[0].split(':');
    return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]);
  }

  timeStringToFloat(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return this.round(hours + minutes / 60, 1);
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  calculateTotal(item) {
    if (item.hrs < 0) {
      item.hrs = 0;
    }
    if (item.rate < 0) {
      item.rate = 0;
    }
    var total = Number(item.hrs) * Number(item.rate);

    item.total = Number(total).toFixed(this.userdata.decimals_allowed);
  }
  goToEdit(item, action) {
    if (action == 'view') {
      this.isView = true;
    } else {
      this.isView = false;
    }
    var modalRef = null;
    modalRef = this.modalService.open(EdittimematerialmodalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'xl' })
    modalRef.componentInstance.params = { 'ticket': this.ticket, 'item': item, 'action': this.isView, 'prodid': item.prodid };
    modalRef.result.then((result) => {
      if (result == 'save') {
        this.getAuditItem();
      }
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });

  }
}
