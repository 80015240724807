import { I } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { flash } from 'ng-animate';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';
import { SerialComponent } from '../serial/serial.component';

@Component({
  selector: 'app-holdqty',
  templateUrl: './holdqty.component.html',
  styleUrls: ['./holdqty.component.scss']
})
export class HoldqtyComponent implements OnInit {
  @Input() fromParent;
  @ViewChild('Customer') Customer;
  customerid_validation: any;
  keyword = 'Text';
  itemData: any = {}
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  customerList: any = [];
  ownItemProdBinList: any = []
  serialList: any = []
  closeResult: string;
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private datepipe: DatePipe,
    private commonService: CommonServices,
    private modalService: NgbModal,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

  }

  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    this.service.getHoldQty(this.fromParent.id).subscribe(res => {
      this.loader.stop();
      this.itemData = res;
      if (this.itemData.isserilized) {
        this.itemData.holdqty = 1;
      }
      this.getOwnBinLocation();
    }, err => {
      this.loader.stop();
    });

    this.commonService.getCustomerList().subscribe(res => {
      this.customerList = res;
    }, err => {
    });
  }
  closemodal() {
    this.activeModal.close('Modal Closed');
  }
  setFromBinLocation() {
    this.itemData.frombinlocation = this.fromParent.binid ? '' + this.fromParent.binid : null;
    this.itemData.frombinlocation ? this.selectOwnBinLocation(this.itemData.frombinlocation) : '';
  }
  selectOwnBinLocation(id) {
    environment.production ? '' : console.log(id);
    let obj = this.ownItemProdBinList.find(x => x.Value == id);
    this.itemData.qty = obj.Value2;
    environment.production ? '' : console.log(obj);
    this.getSerial();
  }
  getSerial() {
    if (!this.itemData.isserilized) {
      return;
    }
    this.service.getSerialListUsingBin(this.itemData.frombinlocation, this.fromParent.id,).subscribe(res => {
      this.serialList = res;
      environment.production ? '' : console.log('serial list data ', this.serialList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  selectCustomer(event) {
    this.itemData.customerid = event.Value;
  }
  getOwnBinLocation() {
    this.service.getProductBin(this.fromParent.id).subscribe(res => {
      this.ownItemProdBinList = res;
      this.setFromBinLocation();
    }, err => {
    });
  }
  onFocusedCustomer(event) {
    this.Customer.clear();
  }
  postHoldQty() {
    if (!this.itemData.customerid) {
      this.toastr.Error('Please select customer')
      return false;
    }
    if (this.itemData.isserilized) {
      if (!this.itemData.serial || this.itemData.serial == undefined) {
        this.toastr.Error('Please select serial no')
        return false
      }
    }
    if (this.itemData.qty <= 0) {
      this.toastr.Error('Qty should be greater than one')
      return false
    }
    var data = {
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "companyid": this.userdata.companyid,
      "prodid": this.fromParent.id,
      "tiid": this.itemData.tiid,
      "module": this.itemData.module,
      "username": this.userdata.username,
      "createby": this.userdata.email,
      "tobinlocation": this.itemData.frombinlocation,
      "frombinlocation": this.itemData.frombinlocation,
      "qty": this.itemData.qty,
      "deplete_qty": this.itemData.deplete_qty,
      "qtycount": this.itemData.holdqty ? this.itemData.holdqty : 1,
      "damageqty": this.itemData.damageqty,
      "overageqty": this.itemData.overageqty,
      "action": this.itemData.action,
      "msg": this.itemData.msg,
      "ttype": 6,//it means hold
      "refid": this.itemData.refid,
      "serial": this.itemData.serial,
      "ilc": this.itemData.ilc,
      "description": this.itemData.description,
      "isserilized": this.itemData.isserilized,
      "customerid": this.itemData.customerid,
      "adjusttype": this.itemData.adjusttype,
      "qty2pick": this.itemData.qty2pick,
    }

    this.loader.start();
    this.service.postTransferHoldAdjust(data).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      if (!this.itemData.isserilized) {
        this.getOwnBinLocation()
        this.closemodal()

      } else {
        this.getOwnBinLocation()
        this.itemData.frombinlocation = '';
        this.itemData.serial = '';
        this.itemData.qty = 0;
        this.Customer.clear()
        this.Customer.close()
        this.itemData.msg = ''

      }
      environment.production ? '' : console.log('post product response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }
  checkValidation(itemData) {
    if (itemData.holdqty > itemData.qty) {
      this.toastr.Error('Hold qty should be less than onhand qty');
      itemData.holdqty = 1;
    }
    if (itemData.holdqty <= 0) {
      this.toastr.Error('Please enter correct qty');
      itemData.holdqty = 1;
    }
  }
  open() {
    var modalRef = null;
    this.fromParent.binid = this.itemData.frombinlocation;
    modalRef = this.modalService.open(SerialComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.fromParent = this.fromParent;
    modalRef.componentInstance.actoin = 'add';
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.getSerial();
      environment.production ? '' : console.log('closed');
    }, (reason) => {
      this.getSerial();
    });
  }
}
