<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Receive Serial Item
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form">
        <div class="d-flex justify-content-space-between mb-2">
          <div class="text-left">
            <div>
              ILC# {{item.ilc}}
            </div>
            <div>
              Description {{item.description}}
            </div>
            <div>
              Qty2bReceived# {{item.qty-item.receivedqty}}
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-primary" (click)="postSerialNumber()">Submit</button>
          </div>
        </div>
        <div class="form row">
          <div class="form-group col-lg-12 col-12">
            <input class="form-control" type="text" [(ngModel)]="serialno"
              placeholder="Enter/Scan serial number here and please press enter key to add into the serial# list"
              (keyup.enter)="addSerial()">
          </div>
        </div>
        <div class="category-table ">
          <div class="table-responsive2">
            <table class="custom-table">
              <tr class="custom-tr">
                <th class="custom-th text-center">
                  Serial No. <span *ngIf="serialNoList.length>0" style="color: red;">&nbsp;&nbsp;Total
                    Serials({{serialNoList.length}})</span>
                </th>
                <th class="custom-th text-center">
                  Actions
                </th>
              </tr>
              <tr class="custom-tr" *ngFor="let srl of serialNoList; let i=index">
                <td class="custom-td text-left w-150">
                  {{srl}}
                </td>
                <td class="custom-td text-center">
                  <div class="ActionBtn">
                    <a class="hoverBtn" (click)="removeSrl(i)">
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-12 col-12">
            <label for="">Location</label>
            <!-- <select class="form-control" name="location" [(ngModel)]="binid">
          <option value="">Select location</option>
          <option *ngFor="let loc of binLocationList" [value]="loc.Value">{{loc.Text}}</option>
        </select> -->
            <ng-autocomplete #Location [data]="binLocationList" [searchKeyword]="locationKeyword"
              placeholder="Select Location" (inputFocused)="onFocusedlocation($event)"
              (selected)='selectlocation($event)' [itemTemplate]="itemTemplate1" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #itemTemplate1 let-item>
              <a [innerHTML]="item.Text"></a>
            </ng-template>
            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
          <div class="form-group col-lg-12 col-12">
            <label for="">Notes</label>
            <textarea rows="7" class="form-control" [(ngModel)]="notes" placeholder="Notes"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</resize-border>